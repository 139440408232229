<template>
  <!--CONTENEDOR GENERAL-->

  <div class="tiers-container">
    <div class="tier-parent">
      <div class="tier-img">
        <img
          src="https://cdn.discordapp.com/attachments/987378128106168403/1106659786390519990/Sin-titulo-1.png"
          style="border-radius: 0.5rem">
      </div>
      <div :class="referrals < 1 ? 'tier-badge' : 'tier-badge-completed'">
        <p v-show="referrals < 1">
          1
        </p>
        <span
          v-show="referrals >= 1"
          class="material-symbols-sharp">
          check_circle
        </span>
      </div>
      <div class="division-line" />
      <p>Monthly artist to watch issue</p>
    </div>
    <div class="tier-parent">
      <div class="tier-img">
        <img
          src="https://cdn.discordapp.com/attachments/987378128106168403/1106662180335669268/Sin-titulo-1.png"
          style="border-radius: 0.5rem">
        <div :class="referrals < 5 ? 'tier-badge' : 'tier-badge-completed'">
          <p v-show="referrals < 5">
            5
          </p>
          <span
            v-show="referrals >= 5"
            class="material-symbols-sharp">
            check_circle
          </span>
        </div>
      </div>
      <div class="division-line" />
      <p>Discord access</p>
    </div>
    <div class="tier-parent">
      <div class="tier-img">
        <img
          src="https://cdn.discordapp.com/attachments/987378128106168403/1106666766735773777/Sin-titulo-1.png"
          style="border-radius: 0.5rem">
        <div :class="referrals < 10 ? 'tier-badge' : 'tier-badge-completed'">
          <p v-show="referrals < 10">
            10
          </p>
          <span
            v-show="referrals >= 10"
            class="material-symbols-sharp">
            check_circle
          </span>
        </div>
      </div>
      <div class="division-line" />
      <p>50% discount (monthly /yearly subscriptions)</p>
    </div>
    <div class="tier-parent">
      <div class="tier-img">
        <img
          src="https://cdn.discordapp.com/attachments/987378128106168403/1106672403674845224/Sin-titulo-1.png"
          style="border-radius: 0.5rem">
        <div :class="referrals < 25 ? 'tier-badge' : 'tier-badge-completed'">
          <p v-show="referrals < 25">
            25
          </p>
          <span
            v-show="referrals >= 25"
            class="material-symbols-sharp">
            check_circle
          </span>
        </div>
      </div>
      <div class="division-line" />
      <p>Kaloh's Mug</p>
    </div>
    <div class="tier-parent">
      <div class="tier-img">
        <img
          src="https://cdn.discordapp.com/attachments/987378128106168403/1106668412656164965/Sin-titulo-1.png"
          style="border-radius: 0.5rem">
        <div :class="referrals < 50 ? 'tier-badge' : 'tier-badge-completed'">
          <p v-show="referrals < 50">
            50
          </p>
          <span
            v-show="referrals >= 50"
            class="material-symbols-sharp">
            check_circle
          </span>
        </div>
      </div>
      <div class="division-line" />
      <p>Lifetime Premium subscription</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PxProgressBar",

  props: {
    referrals: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style>
.tiers-container {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
  position: relative;
  width: 70%;
}

.tier-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  background-color: #212449;
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
}

.tier-parent p {
  font-size: small;
  margin: 0;
}

.division-line {
  margin-top: 1rem;
  width: 20px;
  height: 0px;

  border: 2.21097px solid #ffffff;
  transform: rotate(180deg);

  flex-grow: 0;
}

.tier-img img {
  width: 100%;
}

.tier-badge {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff184d;
  color: #ffffff;
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.tier-badge-completed {
  background-color: #28a745;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  color: #ffffff;
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .division-line {
    display: none;
  }

  .tiers-container {
    flex-direction: column;
    width: 70%;
  }
  .tier-parent {
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
  }
  .progress-width {
    position: relative;
    width: 100%;
  }
  .tier-img img {
    width: 70px;
  }
}
@media (max-width: 425px) {
  .tiers-container {
    width: 90%;
  }
  .emoji {
    display: none;
  }
  .progress-container {
    top: -50%;
  }
  .progress-circle {
    width: 40px;
    height: 40px;
    background-color: #ffff;
    border-radius: 50%;

    color: #090b24;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #ff184d;
  }
}
</style>
