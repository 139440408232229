import numeral from 'numeral'

export function conditionalControl (conditionals) {
  let whereConditional = ''
  let aliasConditional = ''
  let tzDomainConditional = ''

  if (conditionals.alias && conditionals.alias !== '') {
    aliasConditional = ' ,artist_profile: {alias: {_eq: $alias}} '
  } else if (conditionals.domain && conditionals.domain !== '') {
    tzDomainConditional = ' ,artist_profile: {domain_name: {_eq: $domain}} '
  }
  whereConditional = aliasConditional + tzDomainConditional
  return whereConditional
}

export function groupBy (input, key) {
  return input.reduce((acc, currentValue) => {
    const groupKey = currentValue[key]
    if (!acc[groupKey]) {
      acc[groupKey] = []
    }
    acc[groupKey].push(currentValue)
    return acc
  }, {})
}

export function percentFilter (value) {
  if (!isFinite(value)) return 'New!'
  if (!value) return '0.00%'
  return `${numeral(value).format('0,0.00')}%`
}

export function tezosFilter (value) {
  if (!isFinite(value)) return 'New!'
  if (!value) return 0
  return numeral(value).format('0')
}

export function etherFilter (value) {
  if (!isFinite(value)) return 'New!'
  if (!value) return 0
  return numeral(value).format('00.00000')
}

export function quantityFilter (value) {
  if (!isFinite(value)) return 'New!'
  if (!value) return 0
  // return `${numeral(value).format("0,0")} ꜩ`;
  return numeral(value).format('0')
}

/**
 * @name ParseDataToView
 * @description class to parse data to show tables and plots
 * @example
 * const parser = new ParseDataToView();
 */
export class ParseDataToView {
  constructor() {
    this.sales = [];
    this.actions = [];
    this.owners = [];
  }

  parseBartPlot(data = undefined) {
    const sales = data || this.sales;
    const dataBart = [];
    for (let index = 0; index < sales.length; index++) {
      const element = sales[index];
      const dateFormat = new Date(String(element.from));
      sales[index].dateIndex = String(
        dateFormat.toLocaleString("en", { month: "short" }) +
          " " +
          dateFormat.getFullYear()
      );
    }
    const aggregated = groupBy(sales, "dateIndex");
    // reduce array to get the sum of sales
    for (const [key, value] of Object.entries(aggregated)) {
      let sum = 0;
      value.forEach(function (item) {
        sum += item.secVolumeTz / 1000000 || 0;
      });
      dataBart.push({ dateIndex: key, sales: sum });
    }
    return dataBart;
  }

  actionsTableParser(data = undefined) {
    const actions = data || this.actions;
    const table = actions.map(function (item) {
      let buyer = "", seller = "";
      let buyerPk = "", sellerPk = "";
      if(item.type === "OFFER_ACCEPTED" || item.type === "COLLECTION_OFFER_ACCEPTED"){
        buyer = item.target.name || item.target.id;
        buyerPk = item.target.id;
        seller = item.issuer.name || item.issuer.id;
        sellerPk = item.issuer.id;
      } else {
        buyer = item.issuer.name || item.issuer.id;
        buyerPk = item.issuer.id;
        seller = item.target.name || item.target.id;
        sellerPk = item.target.id;
      }
      return {
        date: new Date(item.createdAt),
        buyer: buyer,
        seller: seller,
        price: item.numericValue / 1000000,
        buyerPk: buyerPk,
        sellerPk: sellerPk,
        objktId: item.objkt.id,
        objktName: item.objkt.name,
        displayUri: String(item.objkt.displayUri).replace(
          "ipfs://",
          "https://media.fxhash.xyz/w_256/"
        ),
        // edition: item.objkt.iteration,
      };
  });
    return table;
  }

  ownerAndUniques(data = undefined) {
    const collections = data || this.sales;
    // get unique holders
    const unique = {};
    let totalMints = 0;
    collections.entireCollection.forEach(function(item){
      // get a array of objects with unique id and total count
      const id = item.owner.name || item.owner.id;
      const idPk = item.owner.id;
      if(unique[id] === undefined) {
        unique[id] = {value: 1, pk: idPk};
      } else {
        unique[id].value += 1;
      }
      totalMints += 1;
    });
    const holders = Object.entries(unique).map(([name, value]) => ({name, value: value.value, pk: value.pk}));
    const statsUnique = {
      totalMints: totalMints,
      uniqueMinters: Object.keys(unique).length
    }
    collections.statsUnique = statsUnique;
    return {collections, holders};
  }

  artistParser(data = undefined) {
    const authors = data || this.owners;
    const artist = authors.name
    ? [
        {
          artistName: authors.name,
          pkh: authors.id,
          artistPreview: String(authors.avatarUri).replace(
            "ipfs://",
            "https://media.fxhash.xyz/w_256/"
          ),
        },
      ]
    : authors.collaborators.map((item) => {
        return {
          artistName: item.name,
          pkh: item.id,
          artistPreview: String(item.avatarUri).replace(
            "ipfs://",
            "https://media.fxhash.xyz/w_256/"
          ),
        };
      });
    return artist;
  }
}
