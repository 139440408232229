<template>
  <b-table
    class="general-table"
    striped
    hover
    responsive
    no-sort-reset
    :busy="busy"
    sticky-header="600px"
    :items="highestSales"
    :fields="[
      'index',
      { key: 'urlImg', label: 'urlImg', sortable: true },
      { key: 'title', label: 'Item', sortable: true },
      { key: 'price', label: 'Price', sortable: true },
      { key: 'buyer', label: 'Buyer', sortable: true, class: 'hidden-overflow' },
    
    ]"
    :sort-by="sortConfig.by"
    :sort-desc="sortConfig.order"
    label-sort-asc=""
    label-sort-desc="">
    <template #table-busy>
      <div class="text-center spinner-loading">
        <b-spinner />
      </div>
    </template>
    <template #head(index)>
      <th>{{ "" }}</th>
    </template>
    <template #cell(index)="data">
      <p class="small text-center m-0">
        {{ data.index + startIndex }}
      </p>  
    </template>
    <template #head(urlImg)>
      <th>{{ "" }}</th>
    </template>
   
    <template #cell(urlImg)="row">
      <div
        style="width: 30px; height: 30px;"
        class="highSale">
        <b-avatar
          :id="`popover-1-${row.item.urlImg}`"
          width="100%"
          height="100%"
          size="1.75rem"
          :icon="activeButton === 'artist' ? 'person-fill':'image-fill'"
          :src="row.item.urlImg"
          style="cursor: pointer" />
        <b-popover
          style="max-width: 50px;"
          :target="`popover-1-${row.item.urlImg}`"
          triggers="hover"
          placement="righttop">
          <img
            :src="row.item.urlImg">
        </b-popover>
      </div>
    </template>
    <template #cell(title)="row">
      <router-link
        v-b-tooltip.hover
        class="collection-name"
        :to="collectionRedirect(row)"
        :replace="false"
        style="text-decoration: none">
        <a
          :title="row.item.title"
          class="collection-name"
          target="_blank">
          {{ row.item.title }}
        </a>
      </router-link>

      
      <div
        v-if="row.item.chain ==='tezos'"
        class="d-flex  align-items-center"
        style="color:#fff; border-radius: 50px; gap:0.25rem">
        <b-avatar
          src="https://pbs.twimg.com/profile_images/1468745106786242566/hgMjtzcV_400x400.png"
          size="0.8rem" />
        <p class="small m-0">
          fxhash
        </p>
      </div>

      <div
        v-if="row.item.chain === 'ethereum'"
        class="d-flex  align-items-center"
        style="color:#fff; border-radius: 50px; gap:0.25rem">
        <b-avatar
          src="https://pbs.twimg.com/profile_images/1582886125336608768/ZeBztIcV_400x400.png"
          size="0.8rem" />
        <p class="small m-0">
          Art Blocks
        </p>
      </div>
    </template>
    <template #cell(price)="row">
      <div
        class="price"
        :title="row.item.price">
        {{ chainFilterCoin(row.item.chain,isUSD ? parseFloat(row.item.priceUSD) : parseFloat(row.item.price)) }}
      </div>
    </template>
    <template #cell(buyer)="row">
      <router-link
        v-b-tooltip.hover
        :title="row.item.chain === 'ethereum'? 'Detailed Art Blocks information coming soon':''"
        class="collection-name"
        :to="row.item.chain !== 'ethereum'?{ name: 'collector', params: { collector: row.item.buyerpk }}:''"
        :replace="false"
        style="text-decoration: none">
        <a
          :style="row.item.chain !== 'ethereum' ? '':'color:#a0a0a0'"
          :title="row.item.buyer"
          class="collection-name"
          target="_blank">
          {{ row.item.buyer }}
        </a>
      </router-link>
    </template>
  </b-table>
</template>
<script>
export default {
  name: "HighestSalesTable",

  props: {
    highestSales: {
      type: Array,
      required: true,
    },
    busy: {
      type: Boolean,
      required: true,
    },
    chainFilterCoin: {
      type: Function,
      required: true,
    },
    isUSD: {
      type: Boolean,
      required: true,
    },
    startIndex:{
      type: Number,
      required:true,
    } 
  },
  data() {
    return {
      sortConfig: { by: "priceUSD", order: true },
    }
  },
  methods: {
    collectionRedirect(rowParam){
      return { 
        name: 'collection', 
        query: { 
          id: rowParam.item.collectionId,
          chain: rowParam.item.chain,
        }
      }
    },
  },
};
</script>
<style>
.highSale > img {
  border-radius: 100% ;
}
</style>