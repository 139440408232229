import { gql, request } from 'graphql-request';
import { OBJKT_API } from '@/constants';

const objktListings = async (galleryId) => {
  const objktQuery = gql`
  query Query_root($where: gallery_bool_exp, $limit: Int) {
    gallery(where: $where) {
      items
      owners
      name
      gallery_id
      active_listing
      tokens(limit: $limit) {
        id
        token {
          name
          thumbnail_uri
          listings_active {
            amount
            marketplace {
              contract
              name
            }
            marketplace_contract
            price
            price_xtz
            bigmap_key
          }
        }
      }
    }
  }
`;

  const response = await request(OBJKT_API, objktQuery, {
    "where": {
      "gallery_id": {
        "_eq": galleryId
      }
    },
    "limit": 10000
  });

  const activeListings = response.gallery[0].tokens.filter((token) => token.token.listings_active.length > 0)

  // console.log(response.gallery.activeListings)

  const listings = activeListings.map((listing) => ({
    cid: `https://gateway.fxhash.xyz/ipfs/${listing.token.thumbnail_uri}`.replace(`ipfs://`, ''),
    collection: listing.token.name.split(` #${listing.token.name.split('#')[1]}`)[0],
    iteration: listing.token.name.match(/#(\d+)/) ? listing.token.name.match(/#(\d+)/)[1] : 1,
    price: listing.token.listings_active[0].price / 1000000,
    marketplace: {
      address: listing.token.listings_active[0].marketplace.contract,
      name: listing.token.listings_active[0].marketplace.name.includes('fxhash') ? 'fxhash' : 'objkt',
      id: listing.token.listings_active[0].bigmap_key,
      price: listing.token.listings_active[0].price / 1000000,
    },
  }));

  return listings;

}

const getOffersByCollection = async (collection,chain='tezos') => {
  // const listings = await objktListings(collection.toString());
  // return listings;
  if(chain === 'tezos'){
    const listings = await objktListings(collection.toString());
    return listings;
  } else {
    return [];
  }
}

export default getOffersByCollection;
