<template>
  <div>
    <b-table
      v-if="items != 0"
      class="general-table"
      sticky-header
      :busy="busy"
      responsive
      striped
      hover
      label-sort-asc=""
      label-sort-desc=""
      no-sort-reset
      :items="items"
      :fields="[
        { key: 'Time_of_sale', label: 'Time of Sale', sortable: false },
        {
          key: 'Buyer',
          label: 'Buyer',
          sortable: false,
          class: 'hidden-overflow',
        },
        {
          key: 'Seller',
          label: 'Seller',
          sortable: false,
          class: 'hidden-overflow',
        },
        { key: 'Price_in_Tezos', label: `Price in ${coinLabel}`, sortable: true },
        { key: 'objktName', label: 'Item', sortable: true },
      ]">
      <template #table-busy>
        <div class="text-center spinner-loading">
          <b-spinner />
        </div>
      </template>
      <template #cell(Price_in_Tezos)="row">
        <td>
          {{ filterCoin(row.item.Price_in_Tezos,'0,000.00') }}
        </td>
      </template>
      <template #cell(Buyer)="row">
        <router-link
          :to="{ name: 'collector', params: { collector: row.item.buyerPk } }"
          :replace="false"
          style="text-decoration: none">
          <a
            :title="row.item.Buyer"
            class="collection-name"
            target="_blank">
            {{ row.item.Buyer }}
          </a>
        </router-link>
      </template>
      <template #cell(Seller)="row">
        <router-link
          :to="{ name: 'collector', params: { collector: row.item.sellerPk } }"
          :replace="false"
          style="text-decoration: none">
          <a
            :title="row.item.Seller"
            class="collection-name"
            target="_blank">
            {{ row.item.Seller }}
          </a>
        </router-link>
      </template>
      <template #cell(objktName)="row">
        <div
          :id="`popover-1-${row.item.displayUri}`"
          :title="row.item.objktName"
          style="cursor: pointer">
          <div>
            {{ row.item.objktName }}
          </div>
        </div>
        <b-popover
          :target="`popover-1-${row.item.displayUri}`"
          triggers="hover"
          placement="left"
          class="popover">
          <img :src="row.item.displayUri">
        </b-popover>
      </template>
    </b-table>
    <NoDataIndividual v-if="!busy && items < 1" />
  </div>
</template>

<script>
import NoDataIndividual from "./NoDataIndividual.vue";
export default {
  name: "SalesTable",
  components: { NoDataIndividual },
  props: {
    items: {
      type: Array,
      required: true,
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterCoin: {
      type: Function,
      required: false,
      default: () => {},
    }
  },
  data() {
    return {
      coinLabel: this.$route.query.chain === "ethereum" ? "ETH" : "Tezos",
    };
  },
};
</script>
<style>
.popover {
  --bs-popover-max-width: 100%;
  --bs-popover-bg: transparent;
  --bs-popover-padding-y: 1 rem;
}
</style>
