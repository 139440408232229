import { BLIND_GALLERY_API } from '@/constants'

const isUserAllowed = async (wallet) => {
  const url = `https://${BLIND_GALLERY_API}/v1/user?wallet=${wallet}`

  const data = await fetch(url)
  const userInfo = await data.json()
  return userInfo.is_allowed
}

export default isUserAllowed
