<template>
  <div>
    <div>
      <h2>Stats From Last Week</h2>
      <!-- Top 5 fxhash sales (Weekly) -->
      <h3>
        Top 5 fxhash Sales
      </h3>
      <px-top-sales :items="top5SalesOfTheWeek" />

      <!-- Top 5 Collections by Volume -->
      <h3>Top 5 Collections by Volume</h3>
      <PxTopSalesCollection :items="top5CollectionsByVolume.week" />

      <!-- Top movers by floor -->
      <h3>Top Movers by Floor</h3>
      <PxTopSalesCollection :items="top5CollectionsByFloorChange.week" />

      <!-- Top absolute sales (weekly) -->
      <h3>Top 5 Collections by Absolute Sales</h3>
      <PxTopSalesCollection :items="top5CollectionsByAbsoluteSales.week" />

      <!-- Top 5 by volume -->
      <h3>Top 5 Artist by Volume</h3>
      <PxTopSalesArtist :items="top5artistsByVolume.week" />

      <!-- Top 5 absolute sales -->
      <h3>Top 5 Artist by Absolute Sales</h3>
      <PxTopSalesArtist :items="top5artistsByAbsoluteSales.week" />

      <!-- Top 5 floor change (positive) -->
      <h3>Top 5 Movers by Floor (Artist)</h3>
      <PxTopSalesArtist :items="top5artistsByFloorChange.week" />
    </div>
    <div>
      <h2>Stats From Last Month</h2>

      <!-- Top 10 fxhash sales (month) -->
      <h3>Top 10 fxhash Sales</h3>
      <px-top-sales :items="top10SalesOfTheMonth" />

      <h3>Top 5 Collections by Volume</h3>
      <PxTopSalesCollection :items="top5CollectionsByVolume.month" />

      <!-- Top movers by floor (weekly) -->
      <h3>Top Movers by Floor</h3>
      <PxTopSalesCollection :items="top5CollectionsByFloorChange.month" />

      <!-- Top absolute sales (weekly) -->
      <h3>Top 5 Collections by Absolute Sales</h3>
      <PxTopSalesCollection :items="top5CollectionsByAbsoluteSales.month" />

      <h3>Top 5 Artist by Volume</h3>
      <PxTopSalesArtist :items="top5artistsByVolume.month" />

      <!-- Top 5 absolute sales -->
      <h3>Top 5 Artist by Absolute Sales</h3>
      <PxTopSalesArtist :items="top5artistsByAbsoluteSales.month" />

      <!-- Top 5 floor change (positive) -->
      <h3>Top Movers by Floor</h3>
      <PxTopSalesArtist :items="top5artistsByFloorChange.month" />
    </div>

    <!-- Articles -->
    <h1>Articles</h1>
    <h2>Interesting Digital Art Content</h2>
    <px-articles :items="articles" />

    <!-- Events -->
    <h1>Events</h1>
    <h2>Upcoming Digital Art Events</h2>
    <px-events :items="events" />
  </div>
</template>
<script>
import getHighestSales from "@/API/getHighestSales.js";
import {hotCollection} from "@/API/kalohBoardAPI.js";
import { FXHASH } from "@/API/fxhash.js";
import getArticles from "@/API/articles.js";
import getEvents from "@/API/events.js";
import { Reports } from "@/services/reports"

import PxTopSales from './PxTopSales.vue';
import PxEvents from './PxEvents.vue';
import PxArticles from './PxArticles.vue';
import PxTopSalesCollection from './PxTopSalesCollection.vue'
import PxTopSalesArtist from './PxTopSalesArtist.vue'

export default {
  name: "ViewReports",
  components: {
    PxTopSales,
    PxEvents,
    PxArticles,
    PxTopSalesCollection,
    PxTopSalesArtist
  },

  data() {
    return {
      reports: undefined,
      events: [],
      articles: [],
      top5SalesOfTheWeek: [],
      highlightTopSales: {},
      top10SalesOfTheMonth: [],
      highlightTopSalesMonth: {},
      top5CollectionsByVolume: {
        week: [],
        month: []
      },
      top5CollectionsByFloorChange: {
        week: [],
        month: []
      },
      top5CollectionsByAbsoluteSales: {
        week: [],
        month: []
      },
      top5artistsByVolume: {
        week: [],
        month: []
      },
      top5artistsByAbsoluteSales: {
        week: [],
        month: []
      },
      top5artistsByFloorChange: {
        week: [],
        month: []
      },
    }
  },
  mounted() {
    this.reports = new Reports();
    this.reports.generateReports();
  },
  created() {
    this.getData();
    getArticles(10, 10).then((x) => {
      this.articles = x;
    });
    getEvents(10, 0).then((x) => {
      this.events = x;
    });
  },
  methods: {

    async getData() {
      await this.getTop5SalesOfTheWeek();
      await this.getTop10SalesOfTheMonth();
      await this.getCollectionsDataWeekly();
      await this.getCollectionsDataMonthly();
      await this.getArtistDataWeekly();
      await this.getArtistDataMonthly();
    },
    async getTop5SalesOfTheWeek() {
      this.top5SalesOfTheWeek = [];

      const sales = await getHighestSales(5, 7, "price", 0);
      sales.forEach(async (sale) => {
        this.top5SalesOfTheWeek.push(
          {
            artist: sale.artistName ? sale.artistName : sale.artistPk,
            collection: sale.title,
            collector: sale.buyer,
            price: sale.price,
            collectionLink: sale.url
          })
      })

      this.highlightTopSalesMonth = {
        src: sales[0].urlImg,
        author: sales[0].artistName ? sales[0].artistName : sales[0].artistPk,
        title: sales[0].title,
        collectionLink: sales[0].url,
      }

    },
    async getTop10SalesOfTheMonth() {
      this.top10SalesOfTheMonth = [];

      const sales = await getHighestSales(10, 30, "price", 0);
      sales.forEach(async (sale) => {
        this.top10SalesOfTheMonth.push(
          {
            artist: sale.artistName ? sale.artistName : sale.artistPk,
            collection: sale.title,
            price: sale.price,
            collectionLink: sale.url
          })
      })

      this.highlightTopSales = {
        src: sales[0].urlImg,
        author: sales[0].artistName ? sales[0].artistName : sales[0].artistPk,
        title: sales[0].title,
        collectionLink: sales[0].url,
      }
    },
    async getCollectionsDataWeekly() {
      const collections = await hotCollection(
        40,
        7,
        "namecollection"
      )

      collections.sort((a, b) => {
        return b.volumenAcumTz - a.volumenAcumTz;
      });

      const top5Volume = [...collections]

      const top5Collections = top5Volume.slice(0, 5);
      top5Collections.forEach((item) => {
        FXHASH.getCollectionInfo(item.slug_id).then((res) => {
          this.top5CollectionsByVolume.week.push({
            artist: res.author.name,
            collection: item.name,
            TezosVolume: item.volumenAcumTz,
            collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
          })
        })
      })

      collections.sort((a, b) => {
        if (a.incremento == Infinity) {
          return 1;
        }
        return b.incremento - a.incremento;
      });

      let top5Floor = [...collections]
      top5Floor = top5Floor.filter((element) => element.incremento !== Infinity);
      top5Floor.sort((a, b) => b.incremento - a.incremento);

      const top5CollectionsByFloorChange = top5Floor.slice(0, 5);
      top5CollectionsByFloorChange.forEach((item) => {
        FXHASH.getCollectionInfo(item.slug_id).then((res) => {
          this.top5CollectionsByFloorChange.week.push({
            artist: res.author.name,
            collection: item.name,
            floor: item.floor,
            change: item.incrementoAcum,
            collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
          })
        })
      })

      collections.sort((a, b) => {
        return b.volumenAcumNb - a.volumenAcumNb;
      });

      const top5Sales = [...collections]

      const top5CollectionsByAbsoluteSales = top5Sales.slice(0, 5);
      top5CollectionsByAbsoluteSales.forEach((item) => {
        FXHASH.getCollectionInfo(item.slug_id).then((res) => {
          this.top5CollectionsByAbsoluteSales.week.push({
            artist: res.author.name,
            collection: item.name,
            sales: item.volumenAcumNb,
            collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
          })
        })
      })

    },
    async getCollectionsDataMonthly() {
      const collections = await hotCollection(
        40,
        30,
        "namecollection"
      )

      collections.sort((a, b) => {
        return b.volumenAcumTz - a.volumenAcumTz;
      });

      const top5Volume = [...collections]

      const top5Collections = top5Volume.slice(0, 5);
      top5Collections.forEach((item) => {
        FXHASH.getCollectionInfo(item.slug_id).then((res) => {
          this.top5CollectionsByVolume.month.push({
            artist: res.author.name,
            collection: item.name,
            TezosVolume: item.volumenAcumTz,
            collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
          })
        })
      })

      collections.sort((a, b) => {
        if (a.incremento == Infinity) {
          return 1;
        }
        return b.incremento - a.incremento;
      });

      let top5Floor = [...collections]
      top5Floor = top5Floor.filter((element) => element.incremento !== Infinity);
      top5Floor.sort((a, b) => b.incremento - a.incremento);

      const top5CollectionsByFloorChange = top5Floor.slice(0, 5);
      top5CollectionsByFloorChange.forEach((item) => {
        FXHASH.getCollectionInfo(item.slug_id).then((res) => {
          this.top5CollectionsByFloorChange.month.push({
            artist: res.author.name,
            collection: item.name,
            floor: item.floor,
            change: item.incrementoAcum,
            collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
          })
        })
      })

      collections.sort((a, b) => {
        return b.volumenAcumNb - a.volumenAcumNb;
      });

      const top5Sales = [...collections]

      const top5CollectionsByAbsoluteSales = top5Sales.slice(0, 5);
      top5CollectionsByAbsoluteSales.forEach((item) => {
        FXHASH.getCollectionInfo(item.slug_id).then((res) => {
          this.top5CollectionsByAbsoluteSales.month.push({
            artist: res.author.name,
            collection: item.name,
            sales: item.volumenAcumNb,
            collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
          })
        })
      })

    },
    async getArtistDataWeekly() {
      const artists = await hotCollection(
        40,
        7,
        "artist"
      )

      artists.sort((a, b) => {
        return b.volumenAcumTz - a.volumenAcumTz;
      });

      const top5Volume = [...artists]

      // get just the first 5
      const top5 = top5Volume.slice(0, 5);
      top5.forEach((item) => {
        this.top5artistsByVolume.week.push({
          artist: item.author,
          TezosVolume: item.volumenAcumTz,
          artistLink: `https://kaloh.space/artist/${item.pkauthor}`
        })
      })


      artists.sort((a, b) => {
        // remove the ones with Infinity
        if (a.incrementoAcum === Infinity) {
          return 1;
        }
        return b.incrementoAcum - a.incrementoAcum;
      });

      let top5Floor = [...artists]
      top5Floor = top5Floor.filter((element) => element.incremento !== Infinity);
      top5Floor.sort((a, b) => b.incremento - a.incremento);

      // get just the first 5
      const top5ByFloorChange = top5Floor.slice(0, 5);
      top5ByFloorChange.forEach((item) => {
        this.top5artistsByFloorChange.week.push({
          artist: item.author,
          floor: item.floor,
          change: item.incrementoAcum,
          artistLink: `https://kaloh.space/artist/${item.pkauthor}`
        })
      })


      artists.sort((a, b) => {
        return b.volumenAcumNb - a.volumenAcumNb;
      });

      const top5Sales = [...artists]

      const top5ByAbsoluteSales = top5Sales.slice(0, 5);
      top5ByAbsoluteSales.forEach((item) => {
        this.top5artistsByAbsoluteSales.week.push({
          artist: item.author,
          sales: item.volumenAcumNb,
          artistLink: `https://kaloh.space/artist/${item.pkauthor}`
        })
      })

    },
    async getArtistDataMonthly() {
      const artists = await hotCollection(
        40,
        30,
        "artist"
      )

      artists.sort((a, b) => {
        return b.volumenAcumTz - a.volumenAcumTz;
      });

      const top5Volume = [...artists]

      // get just the first 5
      const top5 = top5Volume.slice(0, 5);
      top5.forEach((item) => {
        this.top5artistsByVolume.month.push({
          artist: item.author,
          TezosVolume: item.volumenAcumTz,
          artistLink: `https://kaloh.space/artist/${item.pkauthor}`
        })
      })


      artists.sort((a, b) => {
        // remove the ones with Infinity
        if (a.incrementoAcum === Infinity) {
          return 1;
        }
        return b.incrementoAcum - a.incrementoAcum;
      });

      let top5Floor = [...artists]
      top5Floor = top5Floor.filter((element) => element.incremento !== Infinity);
      top5Floor.sort((a, b) => b.incremento - a.incremento);

      // get just the first 5
      const top5ByFloorChange = top5Floor.slice(0, 5);
      top5ByFloorChange.forEach((item) => {
        this.top5artistsByFloorChange.month.push({
          artist: item.author,
          floor: item.floor,
          change: item.incrementoAcum,
          artistLink: `https://kaloh.space/artist/${item.pkauthor}`
        })
      })


      artists.sort((a, b) => {
        return b.volumenAcumNb - a.volumenAcumNb;
      });

      const top5Sales = [...artists]

      const top5ByAbsoluteSales = top5Sales.slice(0, 5);
      top5ByAbsoluteSales.forEach((item) => {
        this.top5artistsByAbsoluteSales.month.push({
          artist: item.author,
          sales: item.volumenAcumNb,
          artistLink: `https://kaloh.space/artist/${item.pkauthor}`
        })
      })

    },
  },


}
</script>
