<template>
  <div>
    <b-dropdown
      id="dropdown-1"
      :text="
        collectorData.domain_name
          ? collectorData.domain_name
          : collectorData.account
      "
      style="margin: 0">
      <b-dropdown-item
        v-for="(tezos, index) in collectorData.tezos" 
        :key="`${index}-tezos-profile`"
        target="_blank">
        <router-link
          :to="{ 
            name: 'artist', 
            params: { 
              artist: tezos
            },
            query: { 
              chain: 'tezos'
            }
          }"
          style="text-decoration: none">
          <div style="display: flex; align-items: center; gap: 1rem">
            <svg
              id="tezos"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              width="24"
              height="24"
              viewBox="0 0 128 128">
              <path
                d="m72 59.75 25.65-25.61v-3.51h-47.6V10.76L32.7 14.18v2.67s5.35-.09 5.35 5.1v8.68H24v6.3h14v35.25c0 3.61 3.46 13.77 16 13.77 8.51 0 10.09-4.32 10.09-4.32v-3.87a9.94 9.94 0 0 1-6.22 2.57c-3.87 0-7.89-2.93-7.89-9.23V36.93h33.8L59.61 61.24v5.94S86.38 71.11 87.86 91c.78 10.36-5.46 20.51-20.64 20.51-6.89 0-13.64-3.5-13.06-5a6.68 6.68 0 0 0 4-6.3 7 7 0 0 0-6.93-6.8c-6.13 0-8.24 4.77-8.24 7 0 10 8.61 16.84 26.26 16.84 25.09 0 31.27-16.93 31.27-26.92C100.53 69 72 59.75 72 59.75Z" />
            </svg>
            <p class="hidden-overflow">
              {{ tezos }}
            </p>
          </div>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="(ethereum, index) in collectorData.ethereum" 
        :key="`${index}-ethereum-profile`"
        target="_blank">
        <router-link
          :to="{ 
            name: 'artist', 
            params: { 
              artist: ethereum
            },
            query: { 
              chain: 'ethereum'
            }
          }"
          style="text-decoration: none">
          <div style="display: flex; align-items: center; gap: 1rem">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <path
                fill="black"
                d="m12 1.75l-6.25 10.5L12 16l6.25-3.75L12 1.75M5.75 13.5L12 22.25l6.25-8.75L12 17.25L5.75 13.5Z" />
            </svg>
            <p
              class="hidden-overflow">
              {{ ethereum }}
            </p>
          </div>
        </router-link>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        v-if="collectorData.twitter"
        :href="`https://twitter.com/${collectorData.twitter}`"
        target="_blank">
        <div style="display: flex; align-items: center; gap: 1rem">
          <b-icon icon="twitter" />
          <p
            class="hidden-overflow">
            {{ collectorData.twitter }}
          </p>
        </div>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="collectorData.discord" 
        :href="`https://discord.com/channels/@me/${collectorData.discord}`"
        target="_blank">
        <div style="display: flex; align-items: center; gap: 1rem">
          <b-icon icon="discord" />
          <p
            class="hidden-overflow">
            {{ collectorData.discord }}
          </p>
        </div>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="collectorData.github"
        :href="`https://github.com/${collectorData.github}`"
        target="_blank">
        <div style="display: flex; align-items: center; gap: 1rem">
          <b-icon icon="github" />
          <p
            class="hidden-overflow">
            {{ collectorData.github }}
          </p>
        </div>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        v-if="collectorData.website"
        :href="`${collectorData.website.replace('\%7D', '')}`"
        target="_blank">
        <div style="display: flex; align-items: center; gap: 1rem">
          <b-icon icon="globe2" />
          <p
            class="hidden-overflow">
            {{ collectorData.website.replace('\%7D', '') }}
          </p>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "UserLinks",
  props: {
    collectorData: {
      type: Object,
      required: true,
    },
  },
};
</script>