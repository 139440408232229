import { render, staticRenderFns } from "./SwitchButton.vue?vue&type=template&id=b4279860&scoped=true&"
import script from "./SwitchButton.vue?vue&type=script&lang=js&"
export * from "./SwitchButton.vue?vue&type=script&lang=js&"
import style0 from "./SwitchButton.vue?vue&type=style&index=0&id=b4279860&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4279860",
  null
  
)

export default component.exports