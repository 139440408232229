import { STATS_API } from '@/constants'

async function getArticles (top, days, origin = '') {
  const options = {
    method: 'GET'
  }
  let articles = []
  await fetch(
    `${STATS_API}/get/articles?days=${days}&top_n=${top}&origin=${origin}`,
    options
  )
    .then((response) => response.json())
    .then((response) => (articles = response))
    .catch((err) => console.error(err))
  return articles
}

export default getArticles
