<template>
  <div>
    <ol>
      <li
        v-for="item in items"
        :key="item.date">
        <a :href="item.url">{{ item.title }}</a>
        <span>
          source: {{ item.origin | artContent }}
        </span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "ArticlesTable",
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
