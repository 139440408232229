<template>
  <div>
    <b-row>
      <div
        style="gap: 1rem"
        class="referral-main-container">
        <img
          width="125"
          src="https://pbs.twimg.com/profile_images/1562353277647339521/UAZlyXN2_400x400.jpg">
        <h3><b>Kaloh's Newsletter</b></h3>
        <p style="text-align: center; color: rgba(255, 255, 255, 0.714)">
          A weekly column and community about <br>
          generative and crypto art trends and markets.<br>
          Join over 8,000 subscribers.
        </p>
        <PxSubstack :referral-link="$route.params.link" />
      </div>
    </b-row>
  </div>
</template>
<script>
import PxSubstack from "@/components/PxSubstack.vue";
export default {
  name: "ViewReferralSubscribe",
  components: {
    PxSubstack,
  },
};
</script>
<style></style>
