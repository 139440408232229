import numeral from 'numeral'

import { gql, request } from 'graphql-request';
import { FXHASH_API } from '@/constants';

import { getAccountInfo } from '@/API/teztokProfiles';


const types = {
  Sold: 'Sold',
  Bought: 'Bought',
  Minted: 'Minted',
  Transferred: 'Transferred',
}

const fxhashActionTypes = {
  MINTED_FROM: types.Minted,
  TRANSFERED: types.Transferred,
  LISTING_V1_ACCEPTED: types.Sold,
  LISTING_V2_ACCEPTED: types.Sold,
  LISTING_V3_ACCEPTED: types.Sold,
  MINTED: types.Minted,
  OFFER_ACCEPTED: types.Bought,
  COLLECTION_OFFER_ACCEPTED: types.Bought,
}

const actionFilter = (action, actor, issuer) => {
  if (action === types.Minted) {
    return types.Minted;
  }
  if (action === types.Sold && actor === issuer) {
    return types.Bought;
  }
  if (action === types.Sold && actor !== issuer) {
    return types.Sold;
  }
  if (action === types.Bought && actor === issuer) {
    return types.Sold;
  }
  if (action === types.Bought && actor !== issuer) {
    return types.Bought;
  }

}

/**
 * Returns an array of transaction objects for a given user and step using FXHash API.
  Each transaction object contains information about the name, iteration, collection, type, price, etc.
  @function fxhashTransactions
  @async
  @param {string} user - The user Id to retrieve transactions for.
  @param {number} step - The amount of transactions to skip.
  @returns {Object[]} transactions - An array of transactions objects for the given user.
*/
export const fxhashTransactions = async (user, step) => {
  const query = gql`
    query User($userId: String, $take: Int, $skip: Int) {
      user(id: $userId) {
        actions(take: $take, skip: $skip) {
          createdAt
          token {
            name
            pricingFixed {
              price
            }
          }
          type
          objkt {
            name
            iteration
            displayUri
            issuer {
              id
            }
          }
          numericValue
          issuer {
            id
          }
        }
      }
    }
  `;

  let response = await request(FXHASH_API, query, {
    "userId": user,
    "take": 20,
    "skip": step
  });
  if (!response.user) {
    return [];
  }
  // remove items that does not have objkt
  response = response.user.actions.filter((action) => action.objkt);

  let transactions = response
    .map((action) => ({
      Type: actionFilter(fxhashActionTypes[action.type], user, action.issuer.id),
      Collection: action.objkt.name.replace(` #${action.objkt.name.split('#')[1]}`, ''),
      Iteration: action.objkt.iteration,
      Price: `ꜩ ${numeral(action.numericValue/1000000).format('0,0.00')}`,
      DisplayUri: action.objkt.displayUri.replace('ipfs://', 'https://media.fxhash.xyz/w_128/'),
      CollectionId: action.objkt.issuer.id,
      createdAt: action.createdAt,
    }));

  transactions = transactions.filter((transaction) => transaction.Type !== fxhashActionTypes.TRANSFERED);
  transactions = transactions.filter((transaction) => transaction.Type);

  return transactions;

}
/**
 * Returns an array of transaction objects for a given user and step using FXHash API.
 *
 * Each transaction object contains information about the name, iteration, collection, type, price, etc.
 *
 * @function fxhashTransactions
 * @async
 * @param {string} user - The user Id to retrieve transactions for.
 * @param {number} step - The amount of transactions to skip.
 * @returns {Object[]} transactions - An array of transactions objects for the given user.
 */

/**
 * Returns an array of transaction objects for a given user.
 *
 * Retrieves account info for the user's address and then calls fxhashTransactions
 * with increasing step values (i * 20) until there are no more transactions to retrieve.
 *
 * @function transactionsByUser
 * @async
 * @param {string} address - The address of the user to retrieve transactions for.
 * @returns {Object[]} transactions - An array of transaction objects for the given user.
 */

const transactionsByUser = async (address,chain='tezos') => {
  if(chain === 'ethereum'){
    return [];
  }
  const accountInfo = await getAccountInfo(address);

  let transactions = [];

  for (let i = 0; i < 5; i++) {
    const newTransactions = await fxhashTransactions(accountInfo.account, i * 20);
    transactions = [...transactions, ...newTransactions];
    if (newTransactions.length < 20) {
      break;
    }
  }

  return transactions;
}

export default transactionsByUser;
