import { request, gql } from "graphql-request";
import { TEZTOK_API, FXHASH_API,STATS_API } from "@/constants";

import { isValidAddress } from "@/services/tezos";

const getUserFromAliasQuery = gql`
  query Tzprofiles($where: tzprofiles_bool_exp) {
    tzprofiles(where: $where) {
      account
      alias
      contract
      description
      discord
      domain_name
      ethereum
      github
      logo
      twitter
      website
    }
  }
`;

/**
 * Retrieves a user's profile given their alias from the Teztok API.
 *
 * @async
 * @function
 * @param {string} alias - The user's alias.
 * @returns {Promise<object>} A Promise that resolves with the user's profile object.
 * @throws {Error} Throws an error if the Teztok API request fails.
 */
const getUserFromAlias = async (alias) => {
  const teztokResponse = await request(TEZTOK_API, getUserFromAliasQuery, {
    where: {
      alias: {
        _like: alias,
      },
    },
  });

  return teztokResponse.tzprofiles[0];
};

/**
 * Retrieves a user's profile given their alias from the Teztok API.
 *
 * @async
 * @function
 * @param {string} alias - The user's public key.
 * @returns {Promise<object>} A Promise that resolves with the user's profile object.
 * @throws {Error} Throws an error if the Teztok API request fails.
 */
const getUserFromAccount = async (account) => {
  const teztokResponse = await request(TEZTOK_API, getUserFromAliasQuery, {
    where: {
      account: {
        _eq: account,
      },
    },
  });

  return teztokResponse.tzprofiles[0];
};

/**
 * Retrieves a user's profile given their alias from the FXHash API.
 * @async
 * @function
 * @param {string} account - The user's account.
 * @returns {Promise<object>} A Promise that resolves with the user's profile object.
 */
const getUserFromFXHash = async (account = '') => {
  const query = gql`
    query profileFxhash($account: String!) {
      user(id: $account) {
        avatarUri
        description
        id
        name
      }
    }
  `;
  const teztokResponse = await request(FXHASH_API, query, {
    account: account,
  });
  // console.log("teztokResponse",teztokResponse);
  const user = teztokResponse.user;
  return {
    account: user ? user.id : null,
    alias: user ? user.name : null,
    contract: "",
    description: user ? user.description : null,
    discord: null,
    domain_name: null,
    ethereum: null,
    github: null,
    logo: (user ? user.avatarUri : null)
      ? user.avatarUri.replace("ipfs://", "https://media.fxhash.xyz/w_256/")
      : null,
    twitter: null,
    website: null,
  };
};

/**
 * Get wallets cross chain using kaloh's service
 * @async
 * @function
 * @param {string} wallet - The user's account.
 * @param {string} chain - The chain to use. ["tezos", "ethereum"]
 */
const getWallets = async (wallet, chain) => {
  const res  = await fetch(`${STATS_API}/artist/crosschain?wallet=${wallet}&chain=${chain}`);
  const data = await res.json();
  return data;
}

/**
 * Returns user information based on the provided account or alias.
 *
 * @async
 * @function
 * @param {string} account - An tezos address or a user alias.
 * @param {string} [chain="tezos"] - The chain to use. ["tezos", "ethereum"]
 * @returns {Promise<Object>} The user information.
 */
export const getAccountInfo = async (account,chain="tezos") => {
  const wallets = await getWallets(account, chain);
  if (chain === "ethereum") {
    return {
      account: account,
      alias: account,
      contract: "",
      description: "",
      discord: "",
      domain_name: "",
      tezos: wallets.map( x => x.otherWallets),
      ethereum: [account],
      github: "",
      logo: "",
      twitter: "",
      website: "",
      mkpLink: `https://www.artblocks.io/user/${account}`,
    };
  } else {
    let output = {};
    if (!isValidAddress(account)) {
      output = await getUserFromAlias(account);
    }
    const info = await getUserFromAccount(account);
    if (info) {
      output = info;
    }
    output= await getUserFromFXHash(account);
    if(output.ethereum !== '' && output.ethereum !== null && output.ethereum !== undefined){
      output.ethereum = [output.ethereum, ...wallets.map( x => x.otherWallets)];
    } else {
      output.ethereum = wallets.map( x => x.otherWallets);
    }
    output.tezos = [account];
    output.mkpLink = `https://www.fxhash.xyz/u/${output.alias}`;
    return output;
  }
};
