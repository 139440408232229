import { Scatter, mixins } from 'vue-chartjs'
// Chart.defaults.global.legend.display = false;
Chart.defaults.borderColor = ['#fff'];
const { reactiveProp } = mixins


export default {
  extends: Scatter,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
  
    this.renderChart(this.chartData, this.options,
      {responsive: true, maintainAspectRatio: false})
  }
}