import { STATS_API } from "@/constants";

/**
 * @name getHighestSales
 * @description Get highest sales un fxhash using internal api
 * @example
 * getHighestSales();
 * @returns {List} data with secondary sales
 */
async function getHighestSales(top = 30, days = 1, order_by="price", filter_price=0) {
  const options = {
    method: "GET",
  };
  let stats = [];
  // URL/get/high_sales?days=1&top_n=100&filter=0&order_by=price
  await fetch(
    `${STATS_API}/get/high_sales?days=${days}&top_n=${top}&filter=${filter_price}&order_by=${order_by}`,
    options
  )
    // .then((response) => response.text())
    .then((response) => response.json())
    .then((response) => (stats = response))
    .catch((err) => console.error(err));

  // using map, concatenate in urlImg attribute if chain is ethereum
  stats = stats.map((item) => {
    if (item.chain === "ethereum") {
      item.urlImg = `https://res.cloudinary.com/art-blocks/image/fetch/f_auto,c_limit,w_120,q_auto/${item.urlImg}`;
    }
    return item;
  });
  return stats;
}

export default getHighestSales;
