import { STATS_API } from "@/constants";
import getURL from "@/API/getUrl.js";
import {
  groupBy,
  percentFilter,
  tezosFilter,
  etherFilter,
  quantityFilter,
} from "./Utils";

export function mergeArtist(data = []) {
  const mergedArtistsConst = new Array(0);
  // convert to for ciclo
  // this.dataToBoard.forEach((artist) => {
  const toAdd = [];
  for (let i = 0; i < data.length; i++) {
    const aux = {};
    const artist = data[i];
    const artistIndex = mergedArtistsConst.findIndex((item) => {
      return item.globalName == artist.globalName;
    });
    if (artistIndex === -1) {
      mergedArtistsConst.push(artist);
    } else {
      new Promise((resolve) => setTimeout(resolve, 30000));
      aux.index = artistIndex;
      aux.floorUSD = Math.min(
        parseFloat(mergedArtistsConst[artistIndex].floorUSD),
        parseFloat(artist.floorUSD)
      );
      aux.volumenAcumUSD =
        parseFloat(mergedArtistsConst[artistIndex].volumenAcumUSD) +
        parseFloat(artist.volumenAcumUSD);
      aux.volumenAcumNb =
        parseFloat(mergedArtistsConst[artistIndex].volumenAcumNb) +
        parseFloat(artist.volumenAcumNb);
      aux.chainTxt = [mergedArtistsConst[artistIndex].chain, artist.chain];
      toAdd.push(aux);
    }
  }
  toAdd.forEach((item) => {
    mergedArtistsConst[item.index].floorUSD = item.floorUSD;
    mergedArtistsConst[item.index].volumenAcumUSD = item.volumenAcumUSD;
    mergedArtistsConst[item.index].volumenAcumNb = item.volumenAcumNb;
    mergedArtistsConst[item.index].chainTxt = item.chainTxt;
  });
  const artistMerged = [...mergedArtistsConst];
  return artistMerged;
}

export async function hotCollection(top, days, type) {
  const options = {
    // mode: "cors",
    // headers: {
    // accept: "*/*",
    // "accept-language": "es-US,es-419;q=0.9,es;q=0.8",
    // "content-type": "application/json",
    //   //   // "sec-ch-ua": '"Not_A Brand";v="99", "Google Chrome";v="109", "Chromium";v="109"',
    //   //   // "sec-ch-ua-mobile": "?0",
    //   //   // "sec-ch-ua-platform": '"Windows"',
    //   //   // "sec-fetch-dest": "empty",
    //   //   // "sec-fetch-mode": "no-cors",
    //   "Access-Control-Allow-Origin": "*",
    //   //   // "Access-Control-Allow-Origin": "http://localhost:8080",
    //   // "sec-fetch-site": "same-site",
    //   //   // Referer: "https://stats-dot-curious-domain-369714.uc.r.appspot.com/",
    //   //   // "Referrer-Policy": "strict-origin-when-cross-origin",
    // },
    method: "GET",
  };
  let stats_aux = [];
  await fetch(
    `${STATS_API}/get/stats?days=${days}&top_n=${top}&type=${type}`,
    options
  )
    // .then((response) => response.text())
    .then((response) => response.json())
    .then((response) => (stats_aux = response))
    .catch((err) => console.error(err));
  // concat stats.stats y stats.ether
  // const stats = stats_aux.stats.concat(stats_aux.etherStats)
  const stats = stats_aux.stats;
  const aggregatorStats = async function (stats, chain) {
    const agrupador = type === "artist" ? "author" : "slug_id";
    const statsByCollection = groupBy(stats, agrupador);
    let urls = [];
    if (type === "artist") {
      const pkArtist = [];
      for (const slugId in statsByCollection) {
        pkArtist.push(statsByCollection[slugId][0].pkauthor);
      }
      if (chain === "tezos") {
        urls = await getURL(pkArtist, type);
        urls = groupBy(urls, "name");
      } else {
        urls = {};
      }
    } else {
      if (chain === "tezos") {
        function toNumber(value) {
          return Number(value);
        }
        urls = await getURL(
          Object.getOwnPropertyNames(statsByCollection).map(toNumber),
          type
        );
        urls = groupBy(urls, "id");
      } else {
        urls = {};
      }
    }
    for (const slugId in statsByCollection) {
      // el vector esta ordenado, el primero no tiene incremento
      statsByCollection[slugId][0].incrementoAcum = 0;
      statsByCollection[slugId][0].volumenAcumTz =
        statsByCollection[slugId][0].secVolumeTz || 0;
      statsByCollection[slugId][0].volumenAcumUSD =
        statsByCollection[slugId][0].secVolumeUSD || 0;
      // add link to collection
      if (chain === "tezos") {
        statsByCollection[slugId][0].volumenAcumNb =
          (statsByCollection[slugId][0].secVolumeNb || 0) * 1000000;
      } else {
        statsByCollection[slugId][0].volumenAcumNb =
          statsByCollection[slugId][0].secVolumeNb || 0;
        statsByCollection[slugId][0].linkLogo = statsByCollection[slugId][0]
          .link
          ? `https://res.cloudinary.com/art-blocks/image/fetch/f_auto,c_limit,w_120,q_auto/${statsByCollection[slugId][0].link}`
          : "";
      }
      for (let index = 1; index < statsByCollection[slugId].length; index++) {
        if (index === statsByCollection[slugId].length - 1) {
          // floor incremental take initial and last
          const aux1 = statsByCollection[slugId][index].floor || 0;
          const aux2 = statsByCollection[slugId][0].floor || 0;
          statsByCollection[slugId][index].incremento = (aux1 / aux2 - 1) * 100;
          // acumulado incremental
          statsByCollection[slugId][index].incrementoAcum =
            (statsByCollection[slugId][0].incrementoAcum || 0) +
            (statsByCollection[slugId][index].incremento || 0);
        }
        // acumulado volumen tz:
        statsByCollection[slugId][index].volumenAcumTz =
          (statsByCollection[slugId][index - 1].volumenAcumTz || 0) +
          (statsByCollection[slugId][index].secVolumeTz || 0);

        statsByCollection[slugId][index].volumenAcumUSD =
          (statsByCollection[slugId][index - 1].volumenAcumUSD || 0) +
          (statsByCollection[slugId][index].secVolumeUSD || 0);

        // add link to collection
        // piezas Nb
        if (chain === "tezos") {
          statsByCollection[slugId][index].volumenAcumNb =
            (statsByCollection[slugId][index - 1].volumenAcumNb || 0) +
            (statsByCollection[slugId][index].secVolumeNb || 0) * 1000000;
          statsByCollection[slugId][index].linkLogo = urls[slugId]
            ? urls[slugId][0].displayUri
            : "";
        } else {
          statsByCollection[slugId][index].volumenAcumNb =
            (statsByCollection[slugId][index - 1].volumenAcumNb || 0) +
            (statsByCollection[slugId][index].secVolumeNb || 0);
          statsByCollection[slugId][index].linkLogo = statsByCollection[slugId][
            index
          ].link
            ? `https://res.cloudinary.com/art-blocks/image/fetch/f_auto,c_limit,w_120,q_auto/${statsByCollection[slugId][index].link}`
            : "";
        }
      }
    }

    const filteredStats = [];
    let decimalFilter;
    if (chain === "tezos") {
      decimalFilter = (value) => {
        return tezosFilter(value);
      };
    } else {
      decimalFilter = (value) => {
        return etherFilter(value);
      };
    }
    for (const slugId in statsByCollection) {
      // agregadores para trend
      const floorTrend = [];
      const volumenAcumTzTrend = [];
      const volumenAcumNbTrend = [];
      // el vector esta ordenado, el primero no tiene incremento
      for (let index = 0; index < statsByCollection[slugId].length; index++) {
        // add trend data
        floorTrend.push(statsByCollection[slugId][index].floor);
        volumenAcumTzTrend.push(statsByCollection[slugId][index].volumenAcumTz);
        volumenAcumNbTrend.push(statsByCollection[slugId][index].volumenAcumNb);
        if (index === statsByCollection[slugId].length - 1) {
          // stats incremental
          statsByCollection[slugId][index].incrementoAcum = percentFilter(
            statsByCollection[slugId][index].incrementoAcum
          );
          statsByCollection[slugId][index].volumenAcumTz = decimalFilter(
            statsByCollection[slugId][index].volumenAcumTz
          );
          statsByCollection[slugId][index].volumenAcumUSD = decimalFilter(
            statsByCollection[slugId][index].volumenAcumUSD
          );
          statsByCollection[slugId][index].floor = decimalFilter(
            statsByCollection[slugId][index].floor
          );
          statsByCollection[slugId][index].floorUSD = decimalFilter(
            statsByCollection[slugId][index].floorUSD
          );
          statsByCollection[slugId][index].median = decimalFilter(
            statsByCollection[slugId][index].median
          );
          statsByCollection[slugId][index].volumenAcumNb = quantityFilter(
            statsByCollection[slugId][index].volumenAcumNb
          );
          //trend data
          statsByCollection[slugId][index].floorTrend = floorTrend;
          statsByCollection[slugId][index].volumenAcumTzTrend =
            volumenAcumTzTrend;
          statsByCollection[slugId][index].volumenAcumNbTrend =
            volumenAcumNbTrend;
          // add chain id
          statsByCollection[slugId][index].chain = chain;
          // save data
          filteredStats.push(statsByCollection[slugId][index]);
        }
      }
    }
    return filteredStats;
  };
  const filteredStatsTezos = await aggregatorStats(stats, "tezos");
  const filteredStatsEther = await aggregatorStats(
    stats_aux.etherStats,
    "ether"
  );
  return [...filteredStatsTezos, ...filteredStatsEther];
}

