<template>
  <div>
    <b-alert
      :show="dismissCountDown"
      fade
      variant="danger"
      class="alert-position"
      @dismiss-count-down="countDownChanged">
      <span>Bookmark deleted</span>
    </b-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>
<style></style>
