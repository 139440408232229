import { gql, request } from 'graphql-request';
import { FXHASH_API } from '@/constants';

import { getAccountInfo } from '@/API/teztokProfiles';


const fxhashHoldings = async (address) => {
  const query = gql`
    query Query($userId: String,$skip: Int,$take: Int) {
      user(id: $userId) {
        objkts (skip: $skip, take: $take){
          name
          mintedPrice
          issuer {
            name
            id
            author {
              name
              id
              collaborators {
                name
                id
              }
            }
            marketStats {
              floor
              median
              highestSold
              lowestSold
            }
          }
        }
        actions(skip: $skip, take: $take,filters: {
          type_in: [LISTING_V1_ACCEPTED, LISTING_V2_ACCEPTED, LISTING_V3_ACCEPTED,
          COLLECTION_OFFER_ACCEPTED,OFFER_ACCEPTED,MINTED,MINTED_FROM,GENTK_SIGNED,AUCTION_FULFILLED]
        }) {
          id
          issuer {
            name
            id
          }
          numericValue
          target {
            name
            id
          }
          objkt {
            name
          }
          type
        }
      }
    }
  `;
  
  // make request to fxhash api until we get the data null
  let allData = [];
  let allActions = [];
  for (let skip = 0; skip < 1000; skip += 50) {
    const response = await request(FXHASH_API, query, {
      "userId": address,"skip": skip,"take": 50
    });
    // console.log("response",response);
    if (!response.user) {
      break;
    }
    if (response.user.objkts.length === 0 && response.user.actions.length === 0) {
      break;
    }
    allData = allData.concat(response.user.objkts);
    allActions = allActions.concat(response.user.actions);
  }

  const byCollection = {}
  const byArtist = {}
  const allStats = [];

  // get total volume in actions when issuer id === address
  const totalVolume = allActions.reduce((acc, action) => {
    if (action.issuer.id === address) {
      return acc + action.numericValue/1000000;
    }
    return acc;
  }, 0);

  // byCollection [{ name: '...', amount: 1 }]
  // byArtist [{ name: '...', amount: 1 }]
  allData.forEach(objkt => {
    const collection = objkt.issuer.name;
    const collectionId = objkt.issuer.id;
    // check if objkt is a collaboration or not, if is a collaboration, get all the collaborators
    const artist = objkt.issuer.author.collaborators ? objkt.issuer.author.collaborators.map(collaborator => collaborator.name).join(', ') : objkt.issuer.author.name;
    const artistId = objkt.issuer.author.collaborators ? objkt.issuer.author.collaborators.map(collaborator => collaborator.id).join(', ') : objkt.issuer.author.id;
    if (byCollection[collection]) {
      byCollection[collection].Currently_holding += 1;
    } else {
      byCollection[collection] = {
        Collection: collection,
        CollectionId: collectionId,
        Currently_holding: 1,
      }
    }

    if (byArtist[artist]) {
      byArtist[artist].Currently_holding += 1;
    } else {
      byArtist[artist] = {
        Artist: artist,
        ArtistId: artistId,
        Currently_holding: 1,
      }
    }
    //get marketStats and save in allStats
    allStats.push(objkt.issuer.marketStats);
  })
  // sum all floor prices
  const minPrice = allStats.reduce((acc, stats) => acc + stats.floor/1000000, 0);
  // sum all highestSold prices
  const maxPrice = allStats.reduce((acc, stats) => acc + stats.highestSold/1000000, 0);
  // sum all median prices
  const averagePrice = allStats.reduce((acc, stats) => acc + stats.median/1000000, 0);
  const holdings = {
    byCollection: Object.values(byCollection),
    byArtist: Object.values(byArtist),
    statsCollector: {
      floor: minPrice,
      median: averagePrice,
      high: maxPrice,
    },
    totalVolume: totalVolume,
  }
  return holdings;
}


const getHoldingsByUser = async (address,otherWallets=[],chain='tezos') => {
  if (chain === "ethereum"){
    return {
      byCollection: [],
      byArtist: [],
      statsCollector: {
        floor: 0,
        median: 0,
        high: 0,
      },
      totalVolume: 0,
      accountInfo: {
        account: "",
        alias: "",
        contract: "",
        description: null,
        discord: null,
        domain_name: null,
        ethereum: [],
        github: null,
        logo: "",
        twitter: null,
        website: null,
        tezos: [],
      },
    };
  }
  const accountInfo = await getAccountInfo(address);
  const holdings = await fxhashHoldings(accountInfo.account);
  // add account info to holdings
  holdings.accountInfo = accountInfo;
  // if there are other wallets, get their holdings too
  if (otherWallets.length > 0) {
    const otherWalletsHoldings = await Promise.all(otherWallets.map(async (wallet) => {
      const holdings = fxhashHoldings(wallet);
      return holdings;
    }));
    // merge all the holdings
    otherWalletsHoldings.forEach((walletHoldings) => {
      walletHoldings.byCollection.forEach((collection) => {
        const index = holdings.byCollection.findIndex((item) => item.CollectionId === collection.CollectionId);
        if (index !== -1) {
          holdings.byCollection[index].Currently_holding += collection.Currently_holding;
        } else {
          holdings.byCollection.push(collection);
        }
      });
      walletHoldings.byArtist.forEach((artist) => {
        const index = holdings.byArtist.findIndex((item) => item.ArtistId === artist.ArtistId);
        if (index !== -1) {
          holdings.byArtist[index].Currently_holding += artist.Currently_holding;
        } else {
          holdings.byArtist.push(artist);
        }
      });
      holdings.statsCollector.floor += walletHoldings.statsCollector.floor;
      holdings.statsCollector.median += walletHoldings.statsCollector.median;
      holdings.statsCollector.high += walletHoldings.statsCollector.high;
      holdings.totalVolume += walletHoldings.totalVolume;
    });
  }

  return holdings;
}


export default getHoldingsByUser;
