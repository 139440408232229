import { request, gql } from "graphql-request";
import { FXHASH_API, STATS_API } from "@/constants.js";
import { percentFilter, ParseDataToView } from "@/API/Utils.js";

import { getAccountInfo } from "@/API/teztokProfiles";

/**This function utilizes a GraphQL API to retrieve historical market statistics data of generative tokens for a specific artist within a specified time range, and parse the data using a given parser tool.
  @async
  @function historyGetter
  @param {string} artistId - The ID of the artist whose generative token data is to be retrieved.
  @param {string} timeFrom - The starting timestamp of the time range.
  @param {string} timeTo - The ending timestamp of the time range.
  @param {Object} parser - An object containing a method to parse the data retrieved.
  @return {Object} Returns the parsed data.
*/
async function historyGetter(artistId, timeFrom, timeTo, parser) {
  const tokenQuery = gql`
        query artistData {
          user(id: "${artistId}") {
            # avatarUri
            # createdAt
            # description
            generativeTokens {
              id
              name
              marketStatsHistory(filters: {
                from: "${timeFrom}",
                to: "${timeTo}"
              }) {
                from
                secVolumeTz
              }
            }
          }
        }
      `;
  const dataArtist = await request(FXHASH_API, tokenQuery);
  const historical = dataArtist.user.generativeTokens.flatMap((token) => {
    return token.marketStatsHistory;
  });
  // order by date
  const historicalOrdered = historical.sort((a, b) => {
    return new Date(a.from) - new Date(b.from);
  });
  const bartPlotData = parser.parseBartPlot(historicalOrdered);
  return bartPlotData;
}

/**This async function retrieves generative token data for an artist from a GraphQL API and flattened the entire collections array of the generative tokens. It then parses the data using a given parser's ownerAndUniques method.
  @function collectionInfoData
  @async
  @param {string} artistId - The ID of the artist whose generative token data is to be retrieved.
  @param {Object} parser - An object containing a ownerAndUniques method to parse the data retrieved.
  @param {string} [chain="tezos"] - The blockchain to which the generative tokens of the artist belong.
  @returns {Object} Returns the parsed data containing the artist's name, avatarUri, id, balance, generativeTokens' entire collections flattened into an array, total unique number of tokens in the entire collections, token Id and the collection Id for all tokens.
*/
async function collectionInfoData(artistId, parser) {
  const tokenQuery = gql`
        query artistData {
          user(id: "${artistId}") {
            generativeTokens {
              author {
                name
                avatarUri
                id
                collaborators {
                  name
                  avatarUri
                  id
                }
              }
              balance
              id
              name
              objktsCount
              originalSupply
              supply
              displayUri
              thumbnailUri
              entireCollection {
                id
                slug
                name
                owner {
                  name
                  id
                }
              }
              marketStats {
                #from
                #to
                secVolumeTz
                listed
                floor
              }
            }
          }
        }
      `;
  const data = await request(FXHASH_API, tokenQuery);
  const collections = data.user;
  collections.entireCollection = collections.generativeTokens.flatMap(
    (obj) => obj.entireCollection
  );
  return parser.ownerAndUniques(collections);
}
/**
 * This async function retrieves data of an artist's generative token actions from a GraphQL API
 * and parses the data using a given parser's actionsTableParser method.
 *
 * @function actionsGetter
 * @async
 * @param {string} artistId - The ID of the artist whose generative token data is to be retrieved.
 * @param {number} [skip=0] - The number of items to skip at the beginning of the list of sales.
 * @param {number} [take=50] - The maximum number of items to retrieve.
 * @param {Object} parser - An object containing an actionsTableParser method to parse the data retrieved.
 * @returns {Promise} Returns a Promise that resolves to the parsed data containing the actions of the artist's generative tokens
 * in a table format.
 */
async function actionsGetter(artistId, skip = 0, take = 50, parser) {
  const tokenQuery = gql`
        query artistData {
          user(id: "${artistId}") {
            sales (skip: ${skip}, take: ${take}) {
              createdAt
              numericValue
              issuer {
                id
                name
              }
              target {
                id
                name
              }
              objkt {
                id
                name
                displayUri
              }
              type
            }
          }
        }
      `;
  const sales = await request(FXHASH_API, tokenQuery);
  const actions = sales.user.sales;
  const actionsTable = parser.actionsTableParser(actions);
  return actionsTable;
}

/** This async function retrieves and aggregates data of an artist's generative token actions and sales from an Ecosystem API. It then parses the data using a given parser's methods and formats it for display in a view.
  @function getArtistView
  @async
  @param {string} artist - The wallet address of the artist whose data is to be retrieved and aggregated.
  @param {string} marketplace - The marketplace to which the generative tokens of the artist belong.
  @param {string} [timeFrom="2021-01-01T15:00:00.000Z"] - The start time to aggregate data from.
  @param {string} [chain="tezos"] - The blockchain to which the generative tokens of the artist belong.
  @returns {Object} Returns an object containing aggregated and formatted data of an artist's generative tokens and sales.
 */
async function getArtistView(
  artist,
  marketplace,
  chain = "tezos",
  timeFrom = "2021-01-01T15:00:00.000Z"
) {
  if (chain === "tezos") {
    const artistInfo = await getAccountInfo(artist, chain);
    const publicKey = artistInfo.account;
    // set config
    let time_to = new Date();
    time_to = time_to.toISOString();
    const artistId = String(publicKey);
    const parser = new ParseDataToView();
    // get sales
    const owners = await collectionInfoData(artistId, parser, chain);
    owners.preArtistStats = owners.collections.generativeTokens.flatMap(
      (obj) => obj.marketStats
    );
    // sum secVolumeTz
    owners.artistStats = owners.preArtistStats.reduce(
      (acc, obj) => {
        acc.secVolumeTz += obj.secVolumeTz / 1000000;
        return acc;
      },
      { secVolumeTz: 0 }
    );
    // sum originalSupply
    owners.artistStats.originalSupply =
      owners.collections.generativeTokens.reduce((acc, obj) => {
        let accAux = acc;
        accAux += obj.originalSupply;
        return accAux;
      }, 0);
    owners.artistStats.totalCollections = owners.preArtistStats.length;
    // total listed
    owners.artistStats.listed = owners.preArtistStats.reduce((acc, obj) => {
      let accAux = acc;
      accAux += obj.listed;
      return accAux;
    }, 0);

    // collection table
    owners.collectionTable = owners.collections.generativeTokens.map((obj) => {
      return {
        name: obj.name,
        minted: String(obj.objktsCount) + "/" + String(obj.originalSupply),
        floor: obj.marketStats.floor / 1000000,
        totalVolume: obj.marketStats.secVolumeTz / 1000000,
        id: obj.id,
      };
    });

    // parse to front
    return {
      // metadata
      artistStats: owners.artistStats,
      editions: owners.collections.generativeTokens.originalSupply,
      minted: owners.collections.generativeTokens.objktsCount,
      uniqueCollectors: {
        value: owners.collections.statsUnique.uniqueMinters,
        percent: percentFilter(
          (owners.collections.statsUnique.uniqueMinters /
            owners.collections.statsUnique.totalMints) *
          100
        ),
      },
      collectionTable: owners.collectionTable,
      listForSale: {
        value: owners.artistStats.listed,
        percent: percentFilter(
          (owners.artistStats.listed /
            owners.collections.statsUnique.totalMints) *
          100
        ),
      },
      holders: owners.holders,
      // secondary sales
      secondarySales: async function () {
        return historyGetter(artistId, timeFrom, time_to, parser).then(
          (bartPlotData) => {
            return {
              labels: bartPlotData.map(function (item) {
                return item.dateIndex;
              }),
              data: bartPlotData.map(function (item) {
                return item.sales;
              }),
            };
          }
        );
      },
      // actions table promise
      actions: async function () {
        return actionsGetter(artistId, 0, 50, parser).then((actionsTable) => {
          return {
            secondaryActions: actionsTable.map(function (item) {
              return { x: item.date, y: item.price };
            }),
            secondaryActionsTable: actionsTable,
          };
        });
      },
      mkp: marketplace,
    };
  } else {
    // get data from STATS_API /ethereum/artist/stats with query wallet=artistId
    const options = {
      method: "GET",
    };
    try {
      const statsEther = await fetch(
        `${STATS_API}/ethereum/artist/stats?wallet=${artist}`,
        options
      );
      const statsEtherJson = await statsEther.json();
      return statsEtherJson;
    } catch (err) {
      console.error(err);
      return {};
    }
  }
}

export default getArtistView;
