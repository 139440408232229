<template>
  <div>
    <b-row
      v-if="!$route.params.link.includes('TpSV5Cy')"
      id="header-art"
      style="max-height: 180px">
      <div
        style="position: relative"
        class="m-0 p-0">
        <img
          style="position: absolute; z-index: 1; bottom: -25px"
          class="p-0"
          width="100%"
          src="https://cdn.discordapp.com/attachments/987378128106168403/1158836183497986198/wave.png?ex=651db1cc&is=651c604c&hm=4c6f2a63d4e6a6ee03605077ff912a8799e4f8bc07346eec1f86cbbefc08c1b1&">
        <img
          class="p-0"
          width="100%"
          src="https://cdn.discordapp.com/attachments/987378128106168403/1158833384013967420/coverart.png?ex=651daf30&is=651c5db0&hm=aab6be05a3e9c1427f3d1af310ddb2805717878b692c7e8bfa07d433eaff0e51&">
      </div>
    </b-row>
    <div
      class="d-flex flex-column justify-content-between"
      style="height: 100vh">
      <b-container class="landing-first-container">
        <b-row class="align-items-center justify-content-center">
          <b-col

            lg="6">
            <div class="d-flex align-items-center newsletter-logo">
              <img
                style="z-index: 2"
                class="p-4"
                src="https://cdn.discordapp.com/attachments/987378128106168403/1158836493264093214/Frame_70.png?ex=651db216&is=651c6096&hm=d91bb41dcf5ede55917f7d96e20ecb3164bd1c70176bc5b2c2d6183880095b6c&">
            </div>
            <hr>
            <h1
              :style="$route.params.link.includes('x1AAmVh') ? 'font-size:42px':''"
              class="landing-title">
              {{ messages[$route.params.link].text }}
              <span style="font-weight: bolder; color: #ff184d">
                {{ messages[$route.params.link].highlightedText }}
              </span>
              {{ messages[$route.params.link].lastText }}
            </h1>
            <p :style="$route.params.link.includes('x1AAmVh') ? 'max-width:525px':''">
              Join 10,000+ subscribers and get our 5 min weekly newsletter on
              what matters in
              <span
                v-if="!$route.params.link.includes('x1AAmVh')"
                style="font-weight: bold">
                generative art.
              </span>
              <span
                v-else
                style="font-weight: bold">
                AI art.
              </span>
            </p>
            <PxSubstack
              input-class="subscribe-input-md"
              btn-class="subscribe-btn-md"
              :referral-link="$route.params.link"
              :social="$route.query.social" />
            <div :class="!$route.params.link.includes('TpSV5Cy') ? '': 'brands-guide'">
              <p>Read by beginners, artists, and collectors from:</p>
              <div
                style="gap: 2.5rem"
                class="d-flex flex-wrap align-items-center justify-content-center">
                <div
                  class="brand"
                  style="width: 100px;">
                  <img
                    width="100%"
                    src="https://cdn.discordapp.com/attachments/987378128106168403/1158815992420515920/proof.png?ex=651d9efe&is=651c4d7e&hm=004a5f855f226f74dc5e379ae4ae09a0b7de850fca7e67a44d035c73435c217b&">
                </div>
                <div class="brand">
                  <img
                    width="100%"
                    src="https://cdn.discordapp.com/attachments/987378128106168403/1158815993141936198/Sothebys.png?ex=651d9efe&is=651c4d7e&hm=da8293ebb83e92ebd815667163dc6fa83e3a6c21bd7fe7ad1326946e2037cc0a&">
                </div>
                <div class="brand">
                  <img
                    width="100%"
                    src="https://cdn.discordapp.com/attachments/987378128106168403/1158815992705732668/fxhash.png?ex=651d9efe&is=651c4d7e&hm=12ed71e82581ee3ab2d6a47321f320341eb4c698440e31008099d2584146916e&">
                </div>
                <div class="brand">
                  <img
                    width="100%"
                    src="https://cdn.discordapp.com/attachments/987378128106168403/1158815992919621742/artblocks.png?ex=651d9efe&is=651c4d7e&hm=296ba31d399bc3e6eed88197071ebe7becb60a8dbfadcfc3e1a1be745dc94b43&">
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            lg="6">
            <Swiper
              v-if="!$route.params.link.includes('TpSV5Cy')"
              class="d-none d-lg-block w-75"
              style="float: right"
              :modules="modules"
              :space-between="50"
              :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
              }">
              <SwiperSlide
                v-for="(slide, indexSlide) in slideArt"
                :key="indexSlide">
                <div style="width: auto">
                  <div>
                    <img
                      width="100%"
                      :src="slide.url">
                    <span class="small text-secondary">{{ slide.credits }}</span>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div
              v-else
              class="p-4 w-100 d-flex align-items-center justify-content-center">
              <img
                class="w-75"
                width="100%"
                src="https://cdn.discordapp.com/attachments/987378128106168403/1159194403756326933/Beginners-guide.png?ex=651eff6a&is=651dadea&hm=969486729548810e4ebb602e96731fc2c8b2734dcbfd146afabe5df59a7ff870&">
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container
        class="mt-5"
        fluid
        style="background-color: #090b24; color: #fff">
        <b-container
          class="py-4"
          fluid="lg">
          <h3 class="pb-4">
            What the community says
          </h3>
          <b-row>
            <b-col
              cols="12"
              lg="4">
              <a
                style="text-decoration: none"
                href="https://twitter.com/CaballeroAnaMa/status/1707584648581165137?s=20"
                target="_blank">
                <div class="twitter-post">
                  <div
                    class="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <p
                        class="m-0"
                        style="font-weight: bold">
                        Ana Maria Caballero
                      </p>
                      <p class="m-0">@CaballeroAnaMa</p>
                    </div>
                    <div>
                      <Icon
                        icon="fa6-brands:square-x-twitter"
                        color="black"
                        width="30px" />
                    </div>
                  </div>
                  <div class="mt-4">
                    <p class="small">
                      before i collapse into bed tonight, wanted to give a major
                      shout out to @Kaloh_nft —he's been supporting artists
                      (both emerging + not) for years in the most selfless,
                      joyous way.
                    </p>
                  </div>
                </div>
              </a>
            </b-col>
            <b-col
              cols="12"
              lg="4">
              <a
                style="text-decoration: none"
                href="https://twitter.com/eli_schein/status/1697313760111702383?s=20"
                target="_blank">
                <div class="twitter-post">
                  <div
                    class="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <p
                        class="m-0"
                        style="font-weight: bold">
                        Eli Scheinman
                      </p>
                      <p class="m-0">@eli_schein</p>
                    </div>
                    <div>
                      <Icon
                        icon="fa6-brands:square-x-twitter"
                        color="black"
                        width="30px" />
                    </div>
                  </div>
                  <div class="mt-4">
                    <p class="small">Long time fan of Kaloh...</p>
                  </div>
                </div>
              </a>
            </b-col>
            <b-col
              cols="12"
              lg="4">
              <a
                style="text-decoration: none"
                href="https://twitter.com/BCasten34/status/1583072848771706880?s=20"
                target="_blank">
                <div class="twitter-post">
                  <div
                    class="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <p
                        class="m-0"
                        style="font-weight: bold">
                        THOTH
                      </p>
                      <p class="m-0">@BCasten34</p>
                    </div>
                    <div>
                      <Icon
                        icon="fa6-brands:square-x-twitter"
                        color="black"
                        width="30px" />
                    </div>
                  </div>
                  <div class="mt-4">
                    <p class="small">
                      Great content and even better community on discord!
                    </p>
                  </div>
                </div>
              </a>
            </b-col>
            <p
              style="font-size: small"
              class="text-center">
              © 2023 Kaloh's Newsletter. All rights reserved
            </p>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>
<script>
import { Pagination, EffectFade, Navigation, Autoplay } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { Icon } from "@iconify/vue2";
import PxSubstack from "@/components/PxSubstack.vue";
import { AD_MESSAGES } from "@/constants";

SwiperCore.use([Pagination, Navigation, EffectFade, Autoplay]);
export default {
  name: "ViewReferralSubscribe",
  components: {
    Icon,
    PxSubstack,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      messages: AD_MESSAGES,
      modules: [Pagination, EffectFade, Navigation, Autoplay],
      slideArt:[
        {
          url: 'https://cdn.discordapp.com/attachments/987378128106168403/1163507069979201626/6.png?ex=653fd366&is=652d5e66&hm=5dea7f8180e6af4911be22dac6d341f1abc600e3b406a0dc82daa4213ce57e9f&',
          credits:'Garden, Monoliths by Zancan'
        },
        {
          url: 'https://cdn.discordapp.com/attachments/987378128106168403/1163510486587932863/2.png?ex=653fd694&is=652d6194&hm=008e2bbb5636c7304b12f1adc3b2cf7c8af3e998bf419e5da1502e16a0799e14&',
          credits:'Polyphonic Prose: Reprise by ganbrood'
        },
        {
          url: 'https://cdn.discordapp.com/attachments/987378128106168403/1163510819506634772/3.png?ex=653fd6e4&is=652d61e4&hm=8244c5d2b5d854895841f6792ca4fad7c4a3975e9d6ab53e8c7a5ff98d022888&',
          credits:'Coronado by Jeres'
        },
        {
          url:'https://cdn.discordapp.com/attachments/987378128106168403/1163508193067016272/2.png?ex=653fd472&is=652d5f72&hm=5b55b5d6cdfd4f8857fa8d0a1ccb52caddeebb4bc20bc46fc1a856663bc484dd&',
          credits:'The Muses by Danielle King'   
        },
        {
          url:'https://cdn.discordapp.com/attachments/987378128106168403/1163511158020509736/5.png?ex=653fd735&is=652d6235&hm=41a3a5145ffd32c5bdbf51e1cc43c27a9d512650a23912e39421daa8d3ded319&',
          credits:'Humans Being by JVDE'   
        }
      ]
    };
  },

  created() {
    document.body.classList.add("referral");
  },
  beforeDestroy() {
    document.body.classList.remove("referral");
  },
};
</script>
<style>
.twitter-post {
  padding: 1rem;
  background-color: #fff;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: ease 0.3s;
}

.twitter-post:hover {
  transition: ease 0.3s;
  scale: 1.01;
}

#header-art {
  display: none;
}

.landing-first-container {
  padding: 6rem 0rem;
}

.landing-title {
  font-size: 54px;
}

.brands-guide {
  display:block
}

.brand {
  width: 125px;
}

.newsletter-logo img{
  width: 250px;
}

@media (max-width: 768px) {
  .newsletter-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newsletter-logo img{
    width: 400px;
  }
}

@media (max-width: 450px) {
  .brands-guide{
    display:none
  }
  .newsletter-logo img{
    width: 320px;
  }
  .landing-first-container {
    padding: 0rem 0rem;
  }
  .landing-title {
    font-size: 42px;
  }
  #header-art {
    display: block;
  }
}

@media (max-width: 424px) {
  .brand {
    width: 110px;
  }
}

@media (max-width: 360px) {
  .brand {
    width: 85px;
  }
}
</style>
