<template>
  <div>
    <b-table
      v-if="transactions != 0"
      class="general-table"
      hover
      responsive
      sticky-header
      striped
      :items="transactions"
      :fields="[
        { key: 'Type', sortable: true },
        { key: 'Collection', sortable: true, class: 'hidden-overflow' },
        { key: 'Iteration', label: 'Iteration Interact.', sortable: true },
        { key: 'Price', sortable: true },
      ]"
      :busy="loading"
      no-sort-reset
      label-sort-asc=""
      label-sort-desc="">
      <template #table-busy>
        <div class="text-center spinner-loading">
          <b-spinner />
        </div>
      </template>
      <template #cell(Price)="row">
        <td>
          {{ row.item.Price | tezosExact }}
        </td>
      </template>
      <template #cell(Collection)="row">
        <router-link
          :to="{ name: 'collection', query: { id: row.item.CollectionId } }"
          :replace="false"
          style="text-decoration: none">
          <a
            :title="row.item.Collection"
            class="collection-name"
            target="_blank">
            {{ row.item.Collection }}
          </a>
        </router-link>
      </template>
      <template #cell(Iteration)="row">
        <div
          :id="`popover-1-${row.item.DisplayUri}`"
          :title="row.item.Iteration"
          style="cursor: pointer">
          <div>
            {{ row.item.Iteration }}
          </div>
        </div>
        <b-popover
          :target="`popover-1-${row.item.DisplayUri}`"
          triggers="hover"
          placement="left"
          class="popover">
          <!-- max width 70px -->
          <img :src="row.item.DisplayUri">
        </b-popover>
      </template>
    </b-table>
    <NoDataIndividual v-if="!loading && transactions < 1" />
  </div>
</template>

<script>
import NoDataIndividual from "./NoDataIndividual.vue";
export default {
  name: "TransactionsTable",
  components: { NoDataIndividual },
  props: {
    transactions: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style>
.popover {
  --bs-popover-max-width: 100%;
  --bs-popover-bg: transparent;
  --bs-popover-padding-y: 1 rem;
}
</style>
