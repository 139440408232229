var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('div',{staticClass:"collection-info"},[_c('div',{staticClass:"profile-img-container"},[_c('b-avatar',{attrs:{"href":_vm.metadataStats?.mkpLink,"target":"_blank","size":"10rem","square":"","src":_vm.collectionData?.collectionPreview}}),_c('div',{staticClass:"profile-label"},[_c('p',[_vm._v("COLLECTION PROFILE")])])],1),_c('div',{staticClass:"info-left h-25",staticStyle:{"min-width":"270px"}},[_c('div',[_c('b-avatar-group',_vm._l((_vm.collectionData?.artistInfo),function(authorInfo){return _c('div',{key:authorInfo.artistName,staticClass:"avatar-stack"},[_c('b-avatar',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(authorInfo.artistName),expression:"authorInfo.artistName",modifiers:{"hover":true,"top":true}}],staticStyle:{"border":"2px solid #fff"},attrs:{"to":{
                  name: 'artist',
                  params: {
                    artist: authorInfo.pkh,
                  },
                },"size":"2rem","src":authorInfo.artistPreview}}),_c('b-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.collectionData?.artistInfo.length === 1),expression:"collectionData?.artistInfo.length === 1"}],staticClass:"collection-name",attrs:{"to":{
                  name: 'artist',
                  params: {
                    artist: authorInfo.pkh,
                  },
                }}},[_vm._v(" "+_vm._s(authorInfo.artistName)+" ")])],1)}),0),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.7rem"}},[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.metadataStats.collectionName)+" ")]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.isWalletConnected === false
                  ? _vm.doConnectWallet()
                  : _vm.toggleBookmark()}}},[_c('Icon',{attrs:{"width":"26px","color":_vm.bookmark === true ? 'red' : '',"icon":_vm.bookmark === false
                    ? 'material-symbols:bookmark-add-outline'
                    : 'material-symbols:bookmark'}})],1)]),_c('p',[_vm._v(" "+_vm._s(_vm.metadataStats.editions)+" Editions ")])],1)]),_c('div',{staticClass:"vertical-line"}),_c('div',{staticClass:"info-right",staticStyle:{"align-items":"start"}},[_c('div',[_c('div',{staticStyle:{"margin-bottom":"1rem"}},[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.metadataStats.uniqueCollectors.value)+" | "+_vm._s(_vm.metadataStats.uniqueCollectors.percent)+" ")]),_c('p',{staticClass:"conceptInfo"},[_vm._v(" Unique collectors ")])]),_c('div',[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.metadataStats.listForSale.value)+" | "+_vm._s(_vm.metadataStats.listForSale.percent)+" ")]),_c('p',{staticClass:"conceptInfo"},[_vm._v(" Listed for sale ")])])]),_c('div',[_c('div',{staticStyle:{"margin-bottom":"1rem"}},[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.metadataStats.floor)+" ")]),_c('p',{staticClass:"conceptInfo"},[_vm._v(" Floor Price ")])]),_c('div',[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.metadataStats.highestSold)+" ")]),_c('p',{staticClass:"conceptInfo"},[_vm._v(" Highest Sale ")])])]),_c('div',[_c('div',[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.metadataStats.secVolumeTz)+" ")]),_c('p',{staticClass:"conceptInfo"},[_vm._v(" Secondary Sales ")])])])])])]),(
      _vm.metadataStats.uniqueCollectors.value == 0 &&
        _vm.metadataStats.listForSale.value == 0 &&
        _vm.loading === false
    )?_c('NoData'):_vm._e(),(
      _vm.metadataStats.uniqueCollectors.value != 0 ||
        _vm.metadataStats.listForSale.value != 0
    )?_c('b-row',{staticStyle:{"margin-top":"2rem"}},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"stats-container",staticStyle:{"margin-top":"2rem"}},[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Secondary volume ")]),(_vm.loading)?_c('div',[_c('div',{staticClass:"text-center spinner-loading",staticStyle:{"opacity":"0.5"}},[_c('b-spinner')],1)]):_vm._e(),(!_vm.loading && _vm.items.length < 1)?_c('NoDataIndividual'):_vm._e(),(!_vm.loading && _vm.items.length != 0)?_c('div',[_c('BarChart',{attrs:{"height":233,"options":_vm.optionsBarPlot,"chart-data":_vm.dataBartPlot,"plugins":_vm.plugins}})],1):_vm._e(),_c('div',{staticClass:"stats-container"},[_c('h5',{staticStyle:{"font-weight":"bold","margin-top":"2rem"}},[_vm._v(" Secondary Sales ")]),(_vm.loading)?_c('div',[_c('div',{staticClass:"text-center spinner-loading",staticStyle:{"opacity":"0.5"}},[_c('b-spinner')],1)]):_vm._e(),(!_vm.loading && _vm.items.length < 1)?_c('NoDataIndividual'):_vm._e(),(!_vm.loading && _vm.items != 0)?_c('div',[_c('ScatterChart',{attrs:{"height":233,"options":_vm.optionsScaterPlot,"chart-data":_vm.dataScatterPlot}})],1):_vm._e()],1),_c('div',{staticClass:"stats-container"},[_c('h5',{staticStyle:{"font-weight":"bold","margin-top":"2rem"}},[_vm._v(" All secondary sales ")]),_c('SalesTable',{attrs:{"items":_vm.items,"busy":_vm.loading,"filter-coin":_vm.filterCoin}})],1)],1)]),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"stats-container"},[_c('h5',{staticStyle:{"font-weight":"bold","margin-top":"2rem"}},[_vm._v(" Available on the market ")]),_c('div',[_c('OffersTable',{attrs:{"offer-data":_vm.offerData,"busy":_vm.loading}})],1)]),_c('div',{staticClass:"stats-container"},[_c('h5',{staticStyle:{"font-weight":"bold","margin-top":"2rem"}},[_vm._v(" Collector holdings ")]),_c('CollectorTable',{attrs:{"items":_vm.itemsTres}})],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }