<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="4"
        style="position: relative; margin-bottom: 1rem">
        <HighestSalesTable
          :highest-sales="firstTen"
          :chain-filter-coin="chainFilterCoin"
          :is-u-s-d="isUSD"
          :start-index="1"
          :busy="busy" />
      </b-col>
      <b-col
        cols="12"
        lg="4"
        style="position: relative; margin-bottom: 1rem">
        <HighestSalesTable
          :highest-sales="secondTen"
          :chain-filter-coin="chainFilterCoin"
          :is-u-s-d="isUSD"
          :start-index="firstTen.length + 1"
          :busy="busy" />
      </b-col>
      <b-col
        cols="12"
        lg="4"
        style="position: relative; margin-bottom: 1rem">
        <HighestSalesTable
          :highest-sales="thirdTen"
          :chain-filter-coin="chainFilterCoin"
          :is-u-s-d="isUSD"
          :start-index="firstTen.length + secondTen.length + 1 "
          :busy="busy" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import HighestSalesTable from "@/components/Atomics/HighestSaleTable.vue"

export default {
  name: "PxHighestSales",
  components: {
    HighestSalesTable,
  },
  props: {
    highestSales: {
      type: Array,
      required: true,
    },
    chainFilterCoin: {
      type: Function,
      required: true,
    },
    isUSD: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      firstTen: [],
      secondTen: [],
      thirdTen: [],

      busy: true,
    };
  },
  watch: {
    highestSales: {
      handler() {
        this.busy = true;
        return this.splitHighestSales();
      },
      deep: true,
    }
  },
  
  methods: {
    splitHighestSales(){
      // wait for highestSales to be populated
      if (!this.highestSales.length) {
        setTimeout(() => {
          this.splitHighestSales();
        }, 1000);
        return null;
      }
      // order by priceUSD
      let highestSalesOrder = [...this.highestSales];
      highestSalesOrder = highestSalesOrder.sort((a, b) => {
        return b.priceUSD - a.priceUSD;
      });
      // split highestSales into three arrays
      const firstTen = highestSalesOrder.slice(0, highestSalesOrder.length / 3);
      const secondTen = highestSalesOrder.slice( highestSalesOrder.length / 3, (highestSalesOrder.length / 3) * 2);
      const thirdTen = highestSalesOrder.slice((highestSalesOrder.length / 3) * 2, highestSalesOrder.length);
      // set data to new arrays
      this.firstTen = firstTen;
      this.secondTen = secondTen;
      this.thirdTen = thirdTen;
      
      this.busy = false;
      
    
      return null;
      
    },

   
  }
};
</script>
<style></style>
