<template>
  <div>
    <ol>
      <li
        v-for="item in items"
        :key="item.date">
        <a :href="item.link">{{ item.name }}</a>
        <span>
          , {{ item.description }}
        </span>
        <span>
          , {{ item.date }}
        </span>
        <span v-if="item.city">
          , {{ item.city }}
        </span>
        <span v-if="item.country">
          , {{ item.country }}
        </span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "EventsTable",
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
