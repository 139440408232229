import { request, gql } from 'graphql-request'
import { TEZTOK_API } from '@/constants'

function generateQuery () {
  const queryMaker = `
    query userSuggest ($limit: Int, $alias: String) {
      tzprofiles(limit: $limit,
        where: {alias: {_ilike: $alias}},
        order_by: {alias: asc_nulls_last, twitter: asc_nulls_last}) {
        alias
        domain_name
        ethereum
        logo
        twitter
        website
      }
    }
  `

  const TokenQuery = gql`
    ${queryMaker}
  `

  return TokenQuery
}

async function aliasSuggest (alias, limit = 5) {
  const data = await request(TEZTOK_API, generateQuery(), {
    limit: parseInt(limit),
    alias: alias + '%'
  })
  return data
}
export default aliasSuggest
