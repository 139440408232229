export function tezosAddress ({ tezosAddress }) {
  return tezosAddress
}

export function isWalletConnected ({ isWalletConnected }) {
  return isWalletConnected
}

export function wallet ({ wallet }) {
  return wallet
}

export function isAllowed ({ isAllowed }) {
  return isAllowed
}

export function addedWallets ({ addedWallets }) {
  return addedWallets
}
