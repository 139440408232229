import { gql, request } from 'graphql-request';
import { OBJKT_API, MARKETPLACES } from '@/constants';

import { getAccountInfo } from '@/API/teztokProfiles';


/**
  Queries for active listings of a collector
  @async
  @function objktListings
  @param {string} address - address of the collector
  @returns {Array.} - array of objects representing active listings
  @throws {Error} - throws an error if the request to OBJKT_API fails
*/
const objktListings = async (address) => {
  const objktQuery = gql`
    query Holder_by_pk($address: String!, $where: listing_active_bool_exp, $limit: Int) {
      holder_by_pk(address: $address) {
        listings_active(where: $where, limit: $limit) {
          amount_left
          price
          bigmap_key
          marketplace_contract
          marketplace {
            contract
            name
            subgroup
          }
          token {
            name
            thumbnail_uri
          }
        }
      }
    }
  `;

  const response = await request(OBJKT_API, objktQuery, {
    "address": address,
    "where": {
      "fa_contract": {
        "_eq": MARKETPLACES.fxhash.gentkV2
      }
    },
    "limit": null
  });

  const listings = response.holder_by_pk.listings_active
  .flatMap((listing) => ({
    cid: `https://gateway.fxhash.xyz/ipfs/${listing.token.thumbnail_uri}`.replace(`ipfs://`, ''),
    collection: listing.token.name.split(` #${listing.token.name.split('#')[1]}`)[0],
    iteration: listing.token.name.match(/#(\d+)/) ? listing.token.name.match(/#(\d+)/)[1] : 1,
    price: listing.price / 1000000,
    marketplace: {
      address: listing.marketplace_contract,
      name: listing.marketplace.name.includes('fxhash') ? MARKETPLACES.fxhash.name : MARKETPLACES.objkt.name,
      id: listing.bigmap_key,
      price: listing.price / 1000000,
    }
  }));

  return listings;
}


/**
  Async function that retrieves objkt listings for a specific collector
  @async
  @function
  @param {string} collector - The collector name to retrieve objkt listings for
  @returns {Promise<Object[]>} - Promise object represents an array of objkt listings sorted by price in ascending order
*/
const getOffersByCollector = async (collector,chain='tezos') => {
  if(chain === 'ethereum') return [];
  const accountInfo = await getAccountInfo(collector);
  const address = accountInfo.account ?? collector;
  const objktListingsData = await objktListings(address);
  const listings = objktListingsData.sort((a, b) => a.price - b.price);

  return listings;

}

export default getOffersByCollector;
