<template>
  <div>
    <b-table
      v-if="items != 0"
      class="general-table"
      sticky-header
      :busy="busy"
      striped
      responsive
      hover
      sort-by="Currently_holding"
      sort-desc="true"
      label-sort-asc=""
      label-sort-desc=""
      no-sort-reset
      :items="items"
      :fields="[
        {
          key: 'Collector',
          label: 'Collector',
          class: 'hidden-overflow',
          sortable: true,
        },
        { key: 'Currently_holding', sortable: true },
      ]">
      <template #table-busy>
        <div class="text-center spinner-loading">
          <b-spinner />
        </div>
      </template>
      <template #cell(Collector)="row">
        <router-link
          :to="{
            name: 'collector',
            params: { collector: row.item.collectorPk },
          }"
          :replace="false"
          style="text-decoration: none">
          <a
            :title="row.item.Collector"
            class="wallet-name"
            target="_blank">
            {{ row.item.Collector }}
          </a>
        </router-link>
      </template>
    </b-table>
    <NoDataIndividual v-if="!loading && items < 1" />
  </div>
</template>

<script>
export default {
  name: "CollectorTable",
  props: {
    items: {
      type: Object,
      required: true,
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style>
.wallet-name {
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all ease 0.3s;

  display: table-cell;
}
.wallet-name:hover {
  color: #ff184d;
}
@media (max-width: 425px) {
  .wallet-name {
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all ease 0.3s;
    max-width: 12ch;
    display: table-cell;
  }
}
</style>
