var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-dropdown',{staticStyle:{"margin":"0"},attrs:{"id":"dropdown-1","text":_vm.collectorData.domain_name
        ? _vm.collectorData.domain_name
        : _vm.collectorData.account}},[_vm._l((_vm.collectorData.tezos),function(tezos,index){return _c('b-dropdown-item',{key:`${index}-tezos-profile`,attrs:{"target":"_blank"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ 
          name: 'artist', 
          params: { 
            artist: tezos
          },
          query: { 
            chain: 'tezos'
          }
        }}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"1rem"}},[_c('svg',{attrs:{"id":"tezos","xmlns":"http://www.w3.org/2000/svg","data-name":"Layer 1","width":"24","height":"24","viewBox":"0 0 128 128"}},[_c('path',{attrs:{"d":"m72 59.75 25.65-25.61v-3.51h-47.6V10.76L32.7 14.18v2.67s5.35-.09 5.35 5.1v8.68H24v6.3h14v35.25c0 3.61 3.46 13.77 16 13.77 8.51 0 10.09-4.32 10.09-4.32v-3.87a9.94 9.94 0 0 1-6.22 2.57c-3.87 0-7.89-2.93-7.89-9.23V36.93h33.8L59.61 61.24v5.94S86.38 71.11 87.86 91c.78 10.36-5.46 20.51-20.64 20.51-6.89 0-13.64-3.5-13.06-5a6.68 6.68 0 0 0 4-6.3 7 7 0 0 0-6.93-6.8c-6.13 0-8.24 4.77-8.24 7 0 10 8.61 16.84 26.26 16.84 25.09 0 31.27-16.93 31.27-26.92C100.53 69 72 59.75 72 59.75Z"}})]),_c('p',{staticClass:"hidden-overflow"},[_vm._v(" "+_vm._s(tezos)+" ")])])])],1)}),_vm._l((_vm.collectorData.ethereum),function(ethereum,index){return _c('b-dropdown-item',{key:`${index}-ethereum-profile`,attrs:{"target":"_blank"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ 
          name: 'artist', 
          params: { 
            artist: ethereum
          },
          query: { 
            chain: 'ethereum'
          }
        }}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"1rem"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"black","d":"m12 1.75l-6.25 10.5L12 16l6.25-3.75L12 1.75M5.75 13.5L12 22.25l6.25-8.75L12 17.25L5.75 13.5Z"}})]),_c('p',{staticClass:"hidden-overflow"},[_vm._v(" "+_vm._s(ethereum)+" ")])])])],1)}),_c('b-dropdown-divider'),(_vm.collectorData.twitter)?_c('b-dropdown-item',{attrs:{"href":`https://twitter.com/${_vm.collectorData.twitter}`,"target":"_blank"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"1rem"}},[_c('b-icon',{attrs:{"icon":"twitter"}}),_c('p',{staticClass:"hidden-overflow"},[_vm._v(" "+_vm._s(_vm.collectorData.twitter)+" ")])],1)]):_vm._e(),(_vm.collectorData.discord)?_c('b-dropdown-item',{attrs:{"href":`https://discord.com/channels/@me/${_vm.collectorData.discord}`,"target":"_blank"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"1rem"}},[_c('b-icon',{attrs:{"icon":"discord"}}),_c('p',{staticClass:"hidden-overflow"},[_vm._v(" "+_vm._s(_vm.collectorData.discord)+" ")])],1)]):_vm._e(),(_vm.collectorData.github)?_c('b-dropdown-item',{attrs:{"href":`https://github.com/${_vm.collectorData.github}`,"target":"_blank"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"1rem"}},[_c('b-icon',{attrs:{"icon":"github"}}),_c('p',{staticClass:"hidden-overflow"},[_vm._v(" "+_vm._s(_vm.collectorData.github)+" ")])],1)]):_vm._e(),_c('b-dropdown-divider'),(_vm.collectorData.website)?_c('b-dropdown-item',{attrs:{"href":`${_vm.collectorData.website.replace('\%7D', '')}`,"target":"_blank"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"1rem"}},[_c('b-icon',{attrs:{"icon":"globe2"}}),_c('p',{staticClass:"hidden-overflow"},[_vm._v(" "+_vm._s(_vm.collectorData.website.replace('\%7D', ''))+" ")])],1)]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }