import Vue from 'vue'
import Router from 'vue-router'

import ViewMain from '@/views/ViewHome.vue'

import ViewArtist from '@/views/ViewArtist.vue'
import ViewCollection from '@/views/ViewCollection.vue'
import ViewCollector from '@/views/ViewCollector.vue'
import ViewReferral from '@/views/referral/index.vue'
import ViewReferralLink from '@/views/referral/referredInfo.vue'
import ViewReferralSubscribe from '@/views/referral/subscribe.vue'
import ViewReferralDynamicLandingPage from '@/views/referral/dynamicLandingPage.vue'
import ViewReports from '@/views/reports/view.vue'
import ViewBookmarks from '@/views/ViewBookmarks.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'home', component: ViewMain },
    { path: '/artist/:artist', name: 'artist', component: ViewArtist },
    { path: '/collection', name: 'collection', component: ViewCollection },
    { path: '/collector/:collector', name: 'collector', component: ViewCollector },
    { path: '/referral', name: 'referral', component: ViewReferral },
    { path: '/referral-link/:link', name: 'referral-link', component: ViewReferralLink },
    { path: '/referral-subscribe/:link', name: 'referral-subscribe', component: ViewReferralSubscribe },
    { path: '/referral-landing/:link', name: 'referral-landing', component: ViewReferralDynamicLandingPage },
    { path: '/reports', name: 'reports', component: ViewReports },
    { path: '/bookmarks', component: ViewBookmarks },
  ]
})
