<template>
  <div>
    <b-row>
      <div class="collection-info">
        <div class="profile-img-container">
          <b-avatar
            :href="artistData.mkpLink"
            target="_blank"
            size="10rem"
            square
            :src="`https://services.tzkt.io/v1/avatars/${artistData.account}`" />

          <div class="profile-label">
            <p>ARTIST PROFILE</p>
          </div>
        </div>
        <div class="info-left">
          <div>
            <div
              class="d-flex"
              style="gap: 0.7rem">
              <h5 style="font-weight: bold">
                {{ artistData.alias }}
              </h5>
              <span
                style="cursor: pointer"
                @click="
                  isWalletConnected === false
                    ? doConnectWallet()
                    : toggleBookmark()
                ">
                <Icon
                  width="26px"
                  :color="bookmark === true ? 'red' : ''"
                  :icon="bookmark === false
                    ? 'material-symbols:bookmark-add-outline'
                    : 'material-symbols:bookmark'
                  " />
              </span>
            </div>
            <h6>Wallets: </h6>
            <!--DROPDOWN-->
            <UserLinks :collector-data="artistData" />
          </div>
        </div>
        <div class="vertical-line" />
        <!--STATS-->
        <div class="info-right">
          <div>
            <div style="margin-bottom: 1rem">
              <h5 style="font-weight: bold; margin: 0">
                {{ metadataStats?.totalCollections }}
              </h5>
              <p class="conceptInfo">
                Total collections
              </p>
            </div>
            <div>
              <h5 style="font-weight: bold; margin: 0">
                {{ metadataStats?.originalSupply | quantity }}
              </h5>
              <p class="conceptInfo">
                Total pieces
              </p>
            </div>
          </div>
          <div>
            <div style="margin-bottom: 1rem">
              <h5 style="font-weight: bold; margin: 0">
                {{ metadataStats?.secVolumeTzFilter }}
              </h5>
              <p class="conceptInfo">
                Total volume
              </p>
            </div>
            <div>
              <h5 style="font-weight: bold; margin: 0">
                {{ metadataStats?.uniqueCollectors?.value }} |
                {{ metadataStats?.uniqueCollectors?.percent }}
              </h5>
              <p class="conceptInfo">
                Unique collectors
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <!--FILTERS TAGS-->
    <b-row
      class="d-flex flex-direction-row alig-items-center justify-content-between flex-wrap mt-4 mb-4">
      <div
        class="d-flex"
        style="gap:1rem">
        <div
          v-for="market in markets"
          :key="market.value"
          :class="market.class"
          style="border-radius: 50px; gap:0.5rem"
          @click="doSelectMarket(market.value)">
          <b-avatar
            class="border"
            size="1rem"
            :src="market.logo" />
          <p
            class="m-0">
            {{ market.text }}
          </p>
        </div>
      </div>
    </b-row>

    <NoData
      v-if="metadataStats?.originalSupply == 0 &&
        metadataStats?.secVolumeTz == 0 &&
        metadataStats?.totalCollections == 0 &&
        collectionData?.uniqueCollectors?.value == 0 &&
        loading === false
      " />
    <b-row
      v-if="metadataStats?.originalSupply != 0 ||
        metadataStats?.secVolumeTz != 0 ||
        metadataStats?.totalCollections != 0 ||
        collectionData?.uniqueCollectors?.value != 0
      "
      class="mt-4">
      <b-col
        cols="12"
        lg="6">
        <!--ALL COLLECTIONS TABLE-->
        <div class="stats-container">
          <h5
            style="font-weight: bold; margin-top: 2rem; ">
            All collections
          </h5>
          <NoDataIndividual v-if="!loading && items2.length < 1" />
          <b-table
            v-if="items2 != 0"
            class="general-table"
            sticky-header
            striped
            :busy="loading"
            hover
            :items="items2"
            sort-by="totalVolume"
            sort-desc="true"
            label-sort-asc=""
            label-sort-desc=""
            no-sort-reset
            :fields="[
              { key: 'name', label: 'Name', sortable: false },
              { key: 'minted', label: 'Minted', sortable: false },
              { key: 'floor', label: 'Floor', sortable: true },
              { key: 'totalVolume', label: 'Total Volume', sortable: true },
            ]">
            <template #table-busy>
              <div class="text-center spinner-loading">
                <b-spinner />
              </div>
            </template>
            <template #cell(name)="row">
              <router-link
                :to="collectionRedirect(row)"
                :replace="false"
                style="text-decoration: none">
                <a
                  :title="row.item.name"
                  class="collection-name"
                  style=" max-width: 200ch;"
                  target="_blank">
                  {{ row.item.name }}
                </a>
              </router-link>
            </template>
            <template #cell(floor)="row">
              <td>
                {{ filterCoin(row.item.floor, "0,000.00") }}
              </td>
            </template>
            <template #cell(totalVolume)="row">
              <td>
                {{ filterCoin(row.item.totalVolume, "0,000.00") }}
              </td>
            </template>
          </b-table>
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Secondary sales
          </h5>
          <NoDataIndividual v-if="!loading && items < 1" />
          <div v-if="loading">
            <div
              class="text-center spinner-loading"
              style="opacity: 0.5">
              <b-spinner />
            </div>
          </div>
          <div v-if="!loading && items != 0">
            <ScatterChart
              :height="210"
              :plugins="plugins"
              :options="optionsScaterPlot"
              :chart-data="dataScatterPlot" />
          </div>
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            All secondary sales
          </h5>

          <SalesTable
            :items="items"
            :busy="loading"
            :filter-coin="filterCoin" />
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="6">
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Available on the market
          </h5>
          <div>
            <OffersTable :offer-data="offerData" />
          </div>
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Secondary volume
          </h5>
          <NoDataIndividual v-if="!loading && items < 1" />
          <div v-if="loading">
            <div
              class="text-center spinner-loading"
              style="opacity: 0.5">
              <b-spinner />
            </div>
          </div>
          <div v-if="!loading && items != 0">
            <BarChart
              :height="233"
              :options="optionsBarPlot"
              :chart-data="dataBartPlot"
              :plugins="plugins" />
          </div>
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Collector holdings
          </h5>
          <CollectorTable :items="items3" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import numeral from "numeral";
import BarChart from "@/components/Chars/BarChart.js";
import ScatterChart from "@/components/Chars/ScatterChart";

import getOffersByArtist from "@/API/getOffersByArtist.js";
import OffersTable from "@/components/OffersTable.vue";
import CollectorTable from "@/components/CollectorTable.vue";
import SalesTable from "@/components/SalesTable.vue";
import { getAccountInfo } from "@/API/teztokProfiles";
import getArtistView from "@/API/artistView";
import UserLinks from "@/components/Atomics/UserLinks.vue";
import NoData from "@/components/NoData.vue";
import NoDataIndividual from "@/components/NoDataIndividual.vue";

import { MARKETPLACES, SERVICE_API } from "@/constants";
import { tezosFilter, etherFilter } from "@/filters.js";
import { mapGetters } from "vuex";

import { Icon } from "@iconify/vue2";

export default {
  name: "ViewArtist",
  components: {
    BarChart,
    ScatterChart,
    OffersTable,
    CollectorTable,
    SalesTable,
    UserLinks,
    NoData,
    NoDataIndividual,
    Icon,
  },
  data() {
    return {
      bookmark: false,
      loading: true,
      offerData: {
        offers: [],
        fields: [],
        isBusy: true,
      },
      selectedMarkets: [],
      markets: [
        { text: 'fxhash', value: 'fxhash', logo:'https://pbs.twimg.com/profile_images/1468745106786242566/hgMjtzcV_400x400.png', class:'selected-market'},
        { text: 'Art Blocks', value: 'artblocks', logo:'https://pbs.twimg.com/profile_images/1582886125336608768/ZeBztIcV_400x400.png', class:'selected-market'},
      ],
      metadataStats: undefined,
      artistData: {
        account: "",
        alias: "",
        contract: "",
        description: "",
        discord: "",
        domain_name: "",
        ethereum: "",
        github: "",
        logo: "",
        twitter: "",
        website: "",
        mkpLink: "",
      },
      collectionData: undefined,
      dataBartPlot: undefined,
      optionsBarPlot: undefined,
      dataScatterPlot: undefined,
      optionsScaterPlot: undefined,
      plugins: {
        loading: {
          display: true,
          color: "#fff",
          font: {
            size: 16,
          },
          messages: {
            loading: "Fetching data to graph...",
            noData: "Something went wrong, please try again later",
          },
        },
      },
      items: [],
      items2: undefined,
      items3: undefined,

      noDataTrigger: false,
    };
  },
  computed: {
    ...mapGetters("tezosWallet", [
      "tezosAddress",
      "isWalletConnected",
      "isAllowed",
    ]),
  },
  // watch: {
  //   selectedMarkets(newSelectedMarkets){
  //     // this.filterTableItems(newSelectedMarkets);
  //   },
  // },
  created() {
    this.selectedMarkets = this.markets.map((market) => market);
    if (this.isWalletConnected) {
      this.isBookmarked();
    } else {
      this.$store.watch(
        (state) => state.tezosWallet.isWalletConnected,
        (value) => {
          if (value) {
            this.isBookmarked();
          }
        }
      );
    }
    getOffersByArtist(this.$route.params.artist, this.$route.query.chain).then(
      (res) => {
        this.offerData.fields = [
          { key: "cid", sortable: false },
          { key: "collection", sortable: true },
          { key: "iteration", sortable: true },
          { key: "price", sortable: true },
          { key: "marketplace", sortable: false },
        ];
        this.offerData.offers = res;
        this.offerData.isBusy = false;
      }
    );
    getAccountInfo(this.$route.params.artist, this.$route.query.chain).then((res) => {
      this.artistData = res;
    });
    this.fetchData();
  },

  methods: {
    coinLabel(){
      const coinLabel = {
        ethereum: 'ETH',
        tezos: 'Tezos',
      }
      if(this.selectedMarkets.length === 1){
        return coinLabel[this.selectedMarkets[0].value]
      }else{
        return 'USD'
      }
    },
    async fetchData(){
      getArtistView(this.$route.params.artist, MARKETPLACES.fxhash.name, this.$route.query.chain).then(async (x) => {
        this.collectionData = x;
        // all the metadata stats
        const ethereum = this.$route.query.chain === 'ethereum';
        const metadata = ethereum ? x?.stats[0] : x.artistStats;
        this.metadataStats = {
          secVolumeTz: ethereum ? metadata.totalVolume : metadata.secVolumeTz,
          secVolumeTzFilter: ethereum ? etherFilter(metadata.totalVolume) : tezosFilter(metadata.secVolumeTz),
          originalSupply: ethereum ? metadata.totalSupply : metadata.originalSupply,
          totalCollections: ethereum ? metadata.totalCollection : metadata.totalCollections,
          uniqueCollectors: {
            value: ethereum ? metadata.totalOwners : x.uniqueCollectors.value,
            percent: ethereum ? `${numeral((metadata.totalOwners / metadata.totalSupply)).format('0.00')} %` : x.uniqueCollectors.percent,
          },
        };

        let barplot = () => Promise.resolve();
        let scatterplot = () => Promise.resolve();
        if (this.$route.query.chain === 'ethereum') {
          this.artistData.alias = x?.collection[0]?.artistName;
          this.items3 = x.sales.map((sale) => {
            return {
              Collector: sale.buyer,
              Currently_holding: sale.number_of_items,
              collectorPk: sale.buyer,
            };
          });
          this.items2 = x.collection.map((collection) => {
            return {
              name: collection.name,
              minted: collection.totalSupply,
              floor: collection.floor,
              totalVolume: collection.totalVolume,
              id: collection.slug_id,
            };
          })
          // get promises
          barplot = async function () {
            // aggregate sales by labels
            const sales = x.sales.sort((a, b) => {
              const aDate = new Date(a.block_time);
              const bDate = new Date(b.block_time);
              return aDate - bDate;
            });
            const aggregated = sales.reduce((acc, sale) => {
              const date = new Date(sale.block_time);
              const month = date.toLocaleString("default", { month: "short" });
              const year = date.getFullYear();
              const label = `${month} ${year}`;
              if (!acc[label]) {
                acc[label] = 0;
              }
              acc[label] += sale.price;
              return acc;
            }, {});
            const labels = Object.keys(aggregated);
            const data = Object.values(aggregated);
            return ({
              labels: labels,
              data: data
            })
          };
          scatterplot = async function () {
            return ({
              secondaryActions: x.sales.map((sale) => {
                return {
                  // x: new Date(sale.block_time),
                  x: sale.block_time,
                  y: sale.price,
                };
              }),
              secondaryActionsTable: x.sales.map((sale) => {
                return {
                  date: new Date(sale.block_time),
                  buyer: sale.buyer,
                  seller: sale.seller,
                  price: 0.01,//sale.price,
                  buyerPk: sale.buyer,
                  sellerPk: sale.seller,
                  objktId: sale.slug_id,
                  objktName: sale.collectionName,
                  displayUri: `https://res.cloudinary.com/art-blocks/image/fetch/f_auto,c_limit,w_320,q_auto/${sale.imgURL}`,
                };
              }),
            })
          };
        } else {
          // add data to holders table
          this.items3 = x.holders.map((x) => {
            return {
              Collector: x.name,
              Currently_holding: x.value,
              collectorPk: x.pk,
            };
          });
          this.items2 = x.collectionTable;
          // get promises
          barplot = x.secondarySales;
          scatterplot = x.actions;
        }
        // Return a new promise that resolves when both barplot and scatterplot are done
        return Promise.allSettled([barplot(), scatterplot()]);
      })
        .then(([barplotResult, scatterplotResult]) => {
          const coin = this.$route.query.chain === "ethereum" ? " Ξ " : " ꜩ ";
          const coinLabel = this.coinLabel();
          const filterCoin = this.$route.query.chain === "ethereum" ? etherFilter : tezosFilter;
          // secondary sales
          if (barplotResult.status === "rejected") {
            this.dataBartPlot = undefined;
            this.optionsBarPlot = undefined;
          } else {
            this.dataBartPlot = {
              labels: barplotResult?.value?.labels,
              datasets: [
                {
                  data: barplotResult?.value?.data.map((value) => {
                    if (!value) return "0";
                    return `${numeral(value).format("00.00")}`;
                  }),
                  label: "Volume",
                  backgroundColor: "#16C784",
                  fill: false,
                },
              ],
            };

            this.optionsBarPlot = {
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      color: "#46475a",
                      drawOnChartArea: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      color: "#46475a",
                      drawOnChartArea: false,
                    },
                    ticks: {
                      beginAtZero: true,
                      callback: function (value) {
                        // Formatear el valor con el sufijo "Tz"
                        return value + coin;
                      },
                    },
                    scaleLabel: {
                      display: true,
                      labelString: `${coinLabel} Volume`,
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    const datasetLabel =
                      data.datasets[tooltipItem.datasetIndex].label || "";
                    const value = tooltipItem.yLabel;
                    return `${datasetLabel}: ${filterCoin(value)}`;
                  },
                },
              },
            };
          }
          // actions
          // add data to scatter plot
          if (scatterplotResult.status === "rejected") {
            this.dataScatterPlot = undefined;
            this.optionsScaterPlot = undefined;
          } else {
            this.dataScatterPlot = {
              datasets: [
                {
                  data: scatterplotResult?.value?.secondaryActions,
                  label: "Volume",
                  backgroundColor: "#16C784",
                  fill: false,
                },
              ],
            };
            this.optionsScaterPlot = {
              scales: {
                xAxes: [
                  {
                    type: "time",
                    time: {
                      unit: "day",
                      tooltipFormat: "DD-MM-YYYY",
                      // set format
                      displayFormats: {
                        day: "MMM YYYY",
                      },
                    },
                    gridLines: {
                      color: "#46475a",
                      drawOnChartArea: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    type: "linear",
                    ticks: {
                      beginAtZero: true,
                      callback: function (value) {
                        return value + coin;
                      },
                    },
                    gridLines: {
                      color: "#46475a",
                      drawOnChartArea: false,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: `${coinLabel} price`,
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    const datasetIndex = tooltipItem?.datasetIndex;
                    const index = tooltipItem?.index;
                    const price = data?.datasets[datasetIndex]?.data[index]?.y;
                    const date = data?.datasets[datasetIndex]?.data[index]?.x;
                    return `${filterCoin(price)} at ${date.toLocaleString()}`;
                  },
                },
              },
              pointBackgroundColor: "rgba(255, 255, 255, 0.4)",
            };

            // add data to secondary sales table
            this.items = scatterplotResult?.value?.secondaryActionsTable?.map(
              (x) => {
                return {
                  Time_of_sale: x.date.toLocaleString() || undefined,
                  Buyer: x.buyer || undefined,
                  Seller: x.seller || undefined,
                  Price_in_Tezos: x.price || undefined,
                  buyerPk: x.buyerPk || undefined,
                  sellerPk: x.sellerPk || undefined,
                  objktId: x.objktId || undefined,
                  objktName: x.objktName || undefined,
                  displayUri: x.displayUri || undefined,
                };
              }
            );
          }

          this.loading = !this.loading;
        });
    },  
    collectionRedirect(rowParam){
      const chain = this.$route.query.chain === 'ethereum' ? 'ethereum' : 'tezos';
      return { 
        name: 'collection', 
        query: { 
          id: rowParam.item.id,
          chain: chain,
        } 
      }
    },
    filterCoin(value, decimal) {
      const ethereum = this.$route.query.chain === "ethereum";
      return ethereum
        ? etherFilter(value, decimal)
        : tezosFilter(value, decimal);
    },
    doSelectMarket(marketValue) {
      const marketIndex = this.markets.findIndex((market) => market.value === marketValue);
      if (marketIndex !== -1) {
        const selectedMarketIndex = this.selectedMarkets.findIndex((selectedMarket) => selectedMarket.value === marketValue);
        if (selectedMarketIndex === -1) {         
          this.selectedMarkets.push(this.markets[marketIndex]);
          this.markets[marketIndex].class = 'selected-market'

        } else if(this.selectedMarkets.length  !== 1){        
          this.selectedMarkets.splice(selectedMarketIndex, 1);
          this.markets[marketIndex].class = 'disabled-market'
        }
      }
    },
    async doConnectWallet() {
      if (this.isWalletConnected) {
        await this.$store.dispatch("tezosWallet/disconnect");
        return;
      }
      await this.$store.dispatch("tezosWallet/connect");
    },
    isBookmarked() {
      const params = new URLSearchParams({
        login_wallet: this.tezosAddress,
        type: "artist",
        id: this.$route.params.artist,
      });
      fetch(`${SERVICE_API}/is/bookmarks?${params.toString()}`, {
        method: "GET",
      }).then((res) => {
        if (res.status === 200) {
          // if res promise result is true or false set bookmark to true or false
          res.json().then((res) => {
            this.bookmark = res;
          });
        }
      });
    },
    toggleBookmark() {
      if (this.bookmark) {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "DELETE",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            type: "artist",
            id: this.$route.params.artist,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.bookmark = !this.bookmark;
          }
        });
      } else {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "POST",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            chain: this.$route.query.chain,
            type: "artist",
            id: this.$route.params.artist,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.bookmark = !this.bookmark;
          }
        });
      }
    },
  },
};
</script>
<style>
.conceptInfo {
  opacity: 0.7;
}

#dropdown-1>.btn {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}

.dropdown-menu {
  border-radius: 0;
}

.selected-market{
  display: flex;
  align-items: center;
  gap:1rem;
  padding:0.15rem 0.85rem;
  background-color: #fff;
  color:#000000;
  font-size: small;
  font-weight: 600;
  cursor: pointer;
}

.disabled-market{
  display: flex;
  align-items: center;
  gap:1rem;
  padding:0.15rem 0.85rem;
  background-color: #929292;
  color:#fff;
  font-size: small;
  font-weight: 600;
 
  cursor: pointer;

}
</style>
