<template>
  <div>
    <b-row
      v-if="items?.length > 0 || items2?.length > 0 || collectors?.length > 0"
      style="margin-bottom: 1rem">
      <div style="display: flex; align-items: center; gap: 0.5rem">
        <b-dropdown
          id="dropdown-2-1-2"
          :text="frequencySelected">
          <b-dropdown-item
            v-for="times in frequencies"
            :key="times"
            @click="
              selectFrequency(times);
              getBookmarksArtistCollection();
            ">
            {{ times }}
          </b-dropdown-item>
        </b-dropdown>

        <h5 style="margin-bottom: 0">
          <b>Your bookmarks</b>
        </h5>
        <Icon
          width="26px"
          icon="material-symbols:bookmark"
          color="#ff184d" />
      </div>
    </b-row>
    <b-row v-if="items?.length > 0 || items2?.length > 0 || collectors?.length > 0">
      <b-col
        cols="12"
        lg="4">
        <p>Collections</p>
        <b-table
          class="general-table"
          striped
          no-sort-reset
          hover
          sticky-header="600px"
          :items="items"
          :busy="tableBusy"
          label-sort-asc=""
          label-sort-desc=""
          :fields="fields">
          <template #head(linkLogo)>
            <span />
          </template>
          <template #table-busy>
            <div class="text-center spinner-loading">
              <b-spinner />
            </div>
          </template>
          <template #cell(linkLogo)="row">
            <div style="width: 30px; height: 30px">
              <img
                width="100%"
                height="100%"
                style="border-radius: 100%"
                :src="row.item.linkLogo">
            </div>
          </template>
          <template #cell(name)="row">
            <router-link
              :to="{
                name: 'collection',
                query: { id: row.item.slug_id },
              }"
              :replace="false"
              style="text-decoration: none">
              <a
                :title="row.item.Collector"
                class="wallet-name"
                target="_blank">
                {{ row.item.name }}
              </a>
            </router-link>
          </template>
          <template #cell(floor)="row">
            <span>
              {{ row.item.floor | tezos }}
            </span>
          </template>
          <template #cell(incrementoAcum)="row">
            <span
              :class="toNumber(row.item.incrementoAcum) > 0 ? 'green' : 'red'">
              {{ row.item.incrementoAcum }}
            </span>
          </template>
          <template #head(bookmark)>
            <span />
          </template>
          <template #cell(bookmark)="row">
            <span
             
              style="cursor: pointer"
              @click="deleteRow('table1', row.index,row.item.slug_id)">
              <Icon
                width="24px"
                icon="material-symbols:bookmark-remove-outline" />
            </span>
          </template>
        </b-table>
      </b-col><b-col
        cols="12"
        lg="4">
        <p>Artists</p>
        <b-table
          class="general-table"
          striped
          no-sort-reset
          hover
          sticky-header="600px"
          :items="items2"
          :busy="tableBusy"
          label-sort-asc=""
          label-sort-desc=""
          :fields="fields2">
          <template #head(linkLogo)>
            <span />
          </template>
          <template #table-busy>
            <div class="text-center spinner-loading">
              <b-spinner />
            </div>
          </template>
          <template #cell(linkLogo)="row">
            <div style="width: 30px; height: 30px">
              <img
                width="100%"
                height="100%"
                style="border-radius: 100%"
                :src="row.item.linkLogo">
            </div>
          </template>
          <template #cell(name)="row">
            <router-link
              :to="{
                name: 'artist',
                params: { artist: row.item.pkauthor },
              }"
              :replace="false"
              style="text-decoration: none">
              <a
                :title="row.item.Collector"
                class="wallet-name"
                target="_blank">
                {{ row.item.name }}
              </a>
            </router-link>
          </template>
          <template #cell(floor)="row">
            <span>
              {{ row.item.floor | tezos }}
            </span>
          </template>
          <template #head(bookmark)>
            <span />
          </template>
          <template #cell(bookmark)="row">
            <span
             
              style="cursor: pointer"
              @click="deleteRow('table2', row.index,row.item.pkauthor)">
              <Icon
                width="24px"
                icon="material-symbols:bookmark-remove-outline" />
            </span>
          </template>
        </b-table>
      </b-col><b-col
        cols="12"
        lg="4">
        <p>Collectors</p>
        <!-- use vue for to show data -->
        <div
          v-for="(collector,index) in collectors"
          :key="index"
          class="collector-card">
          <div>
            <img
              :src="`https://services.tzkt.io/v1/avatars/${collector.id}`"
              width="80%">
          </div>
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; justify-content: space-between">
              <h5>
                <router-link
                  :to="{
                    name: 'collector',
                    params: { collector: collector.id },
                  }"
                  :replace="false"
                  style="text-decoration: none">
                  <a
                    :title="collector.name"
                    class="wallet-name"
                    target="_blank">
                    {{ collector.name }}
                  </a>
                </router-link>
              </h5>
              <span
                
                style="cursor: pointer"
                @click="deleteRow('table3', index,collector.id)">
                <Icon
                  width="24px"
                  icon="material-symbols:bookmark-remove-outline" />
              </span>
            </div>
            <p>Total collected volume : {{ collector.totalCollectedVolume | tezos }}</p>
            <p>Total collections: {{ collector.totalCollection }}</p>
            <p>Total pieces: {{ collector.totalPieces }}</p>
            <p>Collected artist: {{ collector.totalArtistsCollected }}</p>
            <hr style="margin: 0.5rem 0">
            <div style="display: flex; justify-content: space-between">
              <p>Floor: {{ collector.floor | tezos }}</p>
              <p>Median: {{ collector.median | tezos }}</p>
              <p>High: {{ collector.high | tezos }}</p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-else
      style="height: 70vh">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        ">
        <Icon
          width="82px"
          icon="material-symbols:bookmarks" />
        <div v-if="isLoaded || tableBusy">
          <!-- loading -->
          <b-spinner
            style="margin-bottom: 1rem"
            variant="primary" />
        </div>
        <h5 v-else-if="!isLoaded && collectors.length === 0 && items.length === 0 && items2.length === 0">
          <b>You don't have any bookmarks yet.</b>
        </h5>
        <h5 v-else-if="!reload">
          <b>Uploading the market stats</b>
          <p>
            Bookmark your favorite artists, collections or collectors to track them in the future
          </p>
        </h5>
      </div>
    </b-row>

    <PxAlerts ref="alerts" />
  </div>
</template>
<script>
import PxAlerts from "@/components/PxAlerts.vue";
import { SERVICE_API } from "@/constants";
import { mapGetters } from "vuex";
import {parseBookmarks, getCollectorStats} from "@/API/bookmarks"
import { Icon } from "@iconify/vue2";

export default {
  name: "ViewBookmarks",
  components: { PxAlerts, Icon },
  data() {
    return {
      tableBusy: true,
      reload: false,
      frequencies: undefined,
      frequencySelected: "Weekly",
      Days: 7,
      collectors: [],
      collectorsBookmarks: undefined,
      isLoaded: true,
      fields: [
        { key: "linkLogo", label: "pfp", sortable: false },
        { key: "name", label: "Collection", sortable: false },
        { key: "floor", label: "Floor", sortable: true },
        { key: "incrementoAcum", label: "Change_%", sortable: true },
        { key: "bookmark", label: "bookmark", sortable: false },
      ],
      bookmarks: [],
      items: [],

      fields2: [
        { key:"linkLogo",label: "pfp", sortable: false },
        { key:"name",label: "Artist", sortable: false },
        { key:"floor",label: "Floor", sortable: true },
        { key:"incrementoAcum",label: "Change_%", sortable: true },
        { key:"bookmark",label: "bookmark", sortable: false },
      ],
      items2: [],
    };
  },
  computed: {
    ...mapGetters("tezosWallet", [
      "tezosAddress",
      "isWalletConnected",
      "isAllowed",
    ]),
  },
  // mounted() {
  //   this.$store.dispatch("tezosWallet/autoLogin");
  // },
  created() {
    this.frequencies = ["Weekly", "Monthly", "Yearly", "All time"];
    if (this.isWalletConnected) {
      this.getBookmarks();
    } else {
      setTimeout(() => {
        this.getBookmarks();
      }, 3000);
    }
  },
  methods: {
    selectFrequency(selected) {
      this.frequencySelected = selected;
      const timesDays = {
        "Daily": 1,
        "Weekly": 7,
        "Monthly": 30,
        "Yearly": 365,
        "All time": -1,
      };
      this.Days = timesDays[selected];
      // this.isLoaded = true;
      this.items = [];
      this.items2 = [];
    },
    toNumber(number) {
      // remove % symbol
      const fixNumber = number.replace("%", "");
      return Number(fixNumber);
    },
    getBookmarksArtistCollection(){
      this.tableBusy = true;
      fetch(`${SERVICE_API}/bookmarks?login_wallet=${this.tezosAddress}&days=${this.Days}`).then((res) => {
        res.json().then( async (data) => {
          this.bookmarks = data;
          // split bookmarks array into two arrays by type
          const aux1 = await parseBookmarks(this.bookmarks.filter((bookmark) => bookmark.type === "collection"), "collection")
          const aux2 = await parseBookmarks(this.bookmarks.filter((bookmark) => bookmark.type === "artist"), "artist")
          this.items = aux1
          this.items2 = aux2
          // this.isLoaded = false;
          this.tableBusy = false;
        });
      }).catch((err) => {
        console.error(err);
      });
    },
    getBookmarks() {
      fetch(`${SERVICE_API}/bookmarksbyfilter?login_wallet=${this.tezosAddress}&type=collector`)
        .then((res) => {
          res.json().then( async (data) => {
            this.collectorsBookmarks = data;
            const aux3 = await getCollectorStats(this.collectorsBookmarks)
            this.collectors = aux3
            this.isLoaded = false;
            this.reload = true;
          });
        }).catch((err) => {
          console.error(err);
        });
      this.getBookmarksArtistCollection()
    },
    deleteRow(table, index,name) {
      if (table === "table1") {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "DELETE",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            type: "collection",
            id: name,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.items.splice(index, 1);
            this.$refs.alerts.showAlert();
          }
        });
      } else if (table === "table2") {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "DELETE",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            type: "artist",
            id: name,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.items2?.splice(index, 1);
            this.$refs.alerts.showAlert();
          }
        });
      } else if (table === "table3") {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "DELETE",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            type: "collector",
            id: name,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.collectors.splice(index, 1);
            this.$refs.alerts.showAlert();
          }
        });
      }
      
    },
    closeModal(index) {
      this.$bvModal.hide("modal-" + index);
    },
  },
};
</script>
<style>
.collector-card {
  display: flex;

  align-items: center;
  padding: 0.5rem;
  border: 1px solid rgb(70, 71, 90);
}

.collector-card p {
  margin: 0;
  font-size: small;
}
</style>
