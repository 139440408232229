<template>
  <div>
    <b-table
      v-if="offerData.offers != 0"
      class="general-table"
      striped
      hover
      responsive
      sticky-header
      :items="offerData.offers"
      :fields="offerData.fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="offerData.isBusy"
      sort-icon-left
      no-sort-reset
      label-sort-asc=""
      label-sort-desc="">
      <!-- Image -->
      <template #table-busy>
        <div class="text-center spinner-loading">
          <b-spinner />
        </div>
      </template>
      <template #head(cid)>
        <th>{{ "" }}</th>
      </template>
      <template #cell(cid)="row">
        <div style="width: 50px; height: 50px">
          <img
            :id="`popover-1-${row.item.cid}`"
            width="100%"
            height="100%"
            :src="row.item.cid"
            style="cursor: pointer">
        </div>
        <b-popover
          :target="`popover-1-${row.item.cid}`"
          triggers="hover"
          placement="righttop">
          <img :src="row.item.cid">
        </b-popover>
      </template>

      <!-- Collection -->
      <template
        slot="head(collection)"
        slot-scope="data">
        <th>{{ data.label }}</th>
      </template>
      <template
        slot="cell(collection)"
        slot-scope="data">
        <td>
          {{ data.item.collection }}
        </td>
      </template>

      <!-- Iteration -->
      <template
        slot="head(iteration)"
        slot-scope="data">
        <th>{{ data.label }}</th>
      </template>
      <template
        slot="cell(iteration)"
        slot-scope="data">
        <td>
          {{ "#" + data.item.iteration }}
        </td>
      </template>

      <!-- Price -->
      <template
        slot="head(price)"
        slot-scope="data">
        <th>{{ data.label }}</th>
      </template>
      <template
        slot="cell(price)"
        slot-scope="data">
        <td>
          {{ data.item.price | tezosExact }}
        </td>
      </template>

      <!-- Offer (button to buy) -->
      <template slot="head(marketplace)">
        <th>{{ "" }}</th>
      </template>
      <template
        slot="cell(marketplace)"
        slot-scope="data">
        <td>
          <button
            class="first-button"
            @click="onButtonClicked(data.item)">
            COLLECT
          </button>
        </td>
      </template>
    </b-table>
    <NoDataIndividual v-if="!loading && offerData.offers < 1" />
  </div>
</template>

<script>
import FXHASH from "@/services/marketplaces/fxhash";
import OBJKT from "@/services/marketplaces/objkt";
import { MARKETPLACES } from "@/constants";
import NoDataIndividual from "./NoDataIndividual.vue";
export default {
  name: "OffersTable",
  components: { NoDataIndividual },
  props: {
    offerData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      screenWidth: window.innerWidth,
      sortBy: "price",
      sortDesc: false,
    };
  },

  methods: {
    async onButtonClicked(item) {
      const fxhash = new FXHASH({});
      const objkt = new OBJKT({});
      switch (item.marketplace.address) {
        case MARKETPLACES.fxhash.marketplaceV2:
          try {
            const _confirmation = await fxhash.acceptListingV2(
              item.marketplace.id,
              item.marketplace.price
            );
          } catch (e) {
            console.error(e);
          }
          break;

        case MARKETPLACES.fxhash.marketplaceV3:
          try {
            const _confirmation = await fxhash.acceptListingV3(
              item.marketplace.id,
              item.marketplace.price
            );
          } catch (e) {
            console.error(e);
          }
          break;

        case MARKETPLACES.objkt.marketplaceV1:
          try {
            const _confirmation = await fxhash.fulfillAskV1(
              item.marketplace.id,
              item.marketplace.price
            );
          } catch (e) {
            console.error(e);
          }
          break;

        case MARKETPLACES.objkt.marketplaceV4:
          try {
            const _confirmation = await objkt.fulfillAsk(
              item.marketplace.id,
              item.marketplace.price
            );
          } catch (e) {
            console.error(e);
          }
      }
    },
  },
};
</script>
<style></style>
