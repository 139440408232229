var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.items?.length > 0 || _vm.items2?.length > 0 || _vm.collectors?.length > 0)?_c('b-row',{staticStyle:{"margin-bottom":"1rem"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"0.5rem"}},[_c('b-dropdown',{attrs:{"id":"dropdown-2-1-2","text":_vm.frequencySelected}},_vm._l((_vm.frequencies),function(times){return _c('b-dropdown-item',{key:times,on:{"click":function($event){_vm.selectFrequency(times);
            _vm.getBookmarksArtistCollection();}}},[_vm._v(" "+_vm._s(times)+" ")])}),1),_c('h5',{staticStyle:{"margin-bottom":"0"}},[_c('b',[_vm._v("Your bookmarks")])]),_c('Icon',{attrs:{"width":"26px","icon":"material-symbols:bookmark","color":"#ff184d"}})],1)]):_vm._e(),(_vm.items?.length > 0 || _vm.items2?.length > 0 || _vm.collectors?.length > 0)?_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Collections")]),_c('b-table',{staticClass:"general-table",attrs:{"striped":"","no-sort-reset":"","hover":"","sticky-header":"600px","items":_vm.items,"busy":_vm.tableBusy,"label-sort-asc":"","label-sort-desc":"","fields":_vm.fields},scopedSlots:_vm._u([{key:"head(linkLogo)",fn:function(){return [_c('span')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center spinner-loading"},[_c('b-spinner')],1)]},proxy:true},{key:"cell(linkLogo)",fn:function(row){return [_c('div',{staticStyle:{"width":"30px","height":"30px"}},[_c('img',{staticStyle:{"border-radius":"100%"},attrs:{"width":"100%","height":"100%","src":row.item.linkLogo}})])]}},{key:"cell(name)",fn:function(row){return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'collection',
              query: { id: row.item.slug_id },
            },"replace":false}},[_c('a',{staticClass:"wallet-name",attrs:{"title":row.item.Collector,"target":"_blank"}},[_vm._v(" "+_vm._s(row.item.name)+" ")])])]}},{key:"cell(floor)",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("tezos")(row.item.floor))+" ")])]}},{key:"cell(incrementoAcum)",fn:function(row){return [_c('span',{class:_vm.toNumber(row.item.incrementoAcum) > 0 ? 'green' : 'red'},[_vm._v(" "+_vm._s(row.item.incrementoAcum)+" ")])]}},{key:"head(bookmark)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(bookmark)",fn:function(row){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteRow('table1', row.index,row.item.slug_id)}}},[_c('Icon',{attrs:{"width":"24px","icon":"material-symbols:bookmark-remove-outline"}})],1)]}}],null,false,1427026387)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Artists")]),_c('b-table',{staticClass:"general-table",attrs:{"striped":"","no-sort-reset":"","hover":"","sticky-header":"600px","items":_vm.items2,"busy":_vm.tableBusy,"label-sort-asc":"","label-sort-desc":"","fields":_vm.fields2},scopedSlots:_vm._u([{key:"head(linkLogo)",fn:function(){return [_c('span')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center spinner-loading"},[_c('b-spinner')],1)]},proxy:true},{key:"cell(linkLogo)",fn:function(row){return [_c('div',{staticStyle:{"width":"30px","height":"30px"}},[_c('img',{staticStyle:{"border-radius":"100%"},attrs:{"width":"100%","height":"100%","src":row.item.linkLogo}})])]}},{key:"cell(name)",fn:function(row){return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'artist',
              params: { artist: row.item.pkauthor },
            },"replace":false}},[_c('a',{staticClass:"wallet-name",attrs:{"title":row.item.Collector,"target":"_blank"}},[_vm._v(" "+_vm._s(row.item.name)+" ")])])]}},{key:"cell(floor)",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("tezos")(row.item.floor))+" ")])]}},{key:"head(bookmark)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(bookmark)",fn:function(row){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteRow('table2', row.index,row.item.pkauthor)}}},[_c('Icon',{attrs:{"width":"24px","icon":"material-symbols:bookmark-remove-outline"}})],1)]}}],null,false,3918495982)})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Collectors")]),_vm._l((_vm.collectors),function(collector,index){return _c('div',{key:index,staticClass:"collector-card"},[_c('div',[_c('img',{attrs:{"src":`https://services.tzkt.io/v1/avatars/${collector.id}`,"width":"80%"}})]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","width":"100%"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('h5',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                  name: 'collector',
                  params: { collector: collector.id },
                },"replace":false}},[_c('a',{staticClass:"wallet-name",attrs:{"title":collector.name,"target":"_blank"}},[_vm._v(" "+_vm._s(collector.name)+" ")])])],1),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteRow('table3', index,collector.id)}}},[_c('Icon',{attrs:{"width":"24px","icon":"material-symbols:bookmark-remove-outline"}})],1)]),_c('p',[_vm._v("Total collected volume : "+_vm._s(_vm._f("tezos")(collector.totalCollectedVolume)))]),_c('p',[_vm._v("Total collections: "+_vm._s(collector.totalCollection))]),_c('p',[_vm._v("Total pieces: "+_vm._s(collector.totalPieces))]),_c('p',[_vm._v("Collected artist: "+_vm._s(collector.totalArtistsCollected))]),_c('hr',{staticStyle:{"margin":"0.5rem 0"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('p',[_vm._v("Floor: "+_vm._s(_vm._f("tezos")(collector.floor)))]),_c('p',[_vm._v("Median: "+_vm._s(_vm._f("tezos")(collector.median)))]),_c('p',[_vm._v("High: "+_vm._s(_vm._f("tezos")(collector.high)))])])])])})],2)],1):_c('b-row',{staticStyle:{"height":"70vh"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('Icon',{attrs:{"width":"82px","icon":"material-symbols:bookmarks"}}),(_vm.isLoaded || _vm.tableBusy)?_c('div',[_c('b-spinner',{staticStyle:{"margin-bottom":"1rem"},attrs:{"variant":"primary"}})],1):(!_vm.isLoaded && _vm.collectors.length === 0 && _vm.items.length === 0 && _vm.items2.length === 0)?_c('h5',[_c('b',[_vm._v("You don't have any bookmarks yet.")])]):(!_vm.reload)?_c('h5',[_c('b',[_vm._v("Uploading the market stats")]),_c('p',[_vm._v(" Bookmark your favorite artists, collections or collectors to track them in the future ")])]):_vm._e()],1)]),_c('PxAlerts',{ref:"alerts"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }