<template>
  <div>
    <ol>
      <li
        v-for="item in items"
        :key="item.collection">
        <a :href="item.collectionLink">{{ item.collection }} </a>
        <span>
          {{ `by ${item.artist}${item.price ? `, ${tezosWord(item.price)}` : ""}` }}
        </span>
      </li>
    </ol>
    <PxPreviewImage :data="preview" />
  </div>
</template>

<script>
import numeral from 'numeral'
import PxPreviewImage from './PxPreviewImage.vue';

import { FXHASH } from '@/API/fxhash.js'

export default {
  name: "TopSalesTable",
  components: { PxPreviewImage },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      preview: undefined
    };
  },
  watch: {
    items: function(_newVal, _oldVal) {
      this.getPreview()
    }
  },
  mounted() {
    this.getPreview()
  },
  methods: {
    tezosWord(value) {
      if (!value) return "";
      return `${numeral(value).format('0,0')} Tezos`
    },
    async getPreview() {
      const id = this.items[0].collectionLink.replace('https://www.fxhash.xyz/gentk/slug/', '')
      const res = await FXHASH.getObjktPreview(id)
      this.preview = {
        src: res.displayUri.replace('ipfs://', 'https://gateway.fxhash.xyz/ipfs/'),
        title: res.name,
        author: this.items[0].artist
      }
    }
  }
}

</script>
