<template>
  <div>
    <b-row>
      <div class="referral-main-container">
        <img
          width="125"
          src="https://pbs.twimg.com/profile_images/1562353277647339521/UAZlyXN2_400x400.jpg">
        <h3><b>Kaloh's Newsletter</b></h3>
        <h5 style="text-align: center">
          Share your unique referral link with friends <br>
          and unlock <span style="color: #ff184d">exclusive rewards.</span>
        </h5>
        <div>
          <p style="font-size: small; color: #ffffffb6; margin-top: 1rem">
            Share this link:
          </p>

          <div style="display: flex; gap: 1rem">
            <p
              id="texto-generado"
              style="
                padding: 0.5rem;
                border: 1px solid #fff;
                margin: 0;
                height: 100%;
              ">
              kaloh.space/referral-subscribe/{{ link }}
            </p>
            <button
              id="tooltip-button-show-event"
              style="padding: 0rem 0.5rem; height: auto"
              class="subscribe-btn"
              @click="copyText">
              <Icon
                width="24px"
                icon="material-symbols:content-copy" />
            </button>
            <b-tooltip
              ref="tooltip"
              target="tooltip-button-show-event"
              triggers="click">
              Copied to the clipboard
            </b-tooltip>
          </div>
        </div>

        <div>
          <p>Referred friends: {{ referrals }}</p>
        </div>
        <hr width="100%">
        <PxProgressBar :referrals="referrals" />
      </div>
    </b-row>
  </div>
</template>
<script>
import PxProgressBar from "@/components/PxProgressBar.vue";

import { Referral } from "@/API/referral.js"
import { Icon } from '@iconify/vue2'

export default {
  name: "ViewReferralLink",
  components: { PxProgressBar, Icon },

  data() {
    return {
      referrals: 0,
      link: this.$route.params.link
    };
  },

  created() {
    this.getReferralLink();
  },

  methods: {

    async getReferralLink() {
      const response = await Referral.getReferralCounter({ link: this.$route.params.link });
      this.referrals = response.counter;
    },

    copyText() {
      const generatedText =
        document.getElementById("texto-generado").textContent;
      const tempEl = document.createElement("textarea");
      tempEl.value = generatedText;
      document.body.appendChild(tempEl);
      tempEl.select();
      tempEl.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(tempEl);
      this.$refs.tooltip.$emit("open");
      setTimeout(() => {
        this.$refs.tooltip.$emit("close");
      }, 2000);
    },
    referredFriends() {
      this.referrals += 1;
      if (this.referrals === 1) {
        this.value += 5;
      } else if (this.referrals > 1 && this.referrals <= 5) {
        this.value += 6;
      } else if (this.referrals > 5 && this.referrals <= 10) {
        this.value += 4;
      } else if (this.referrals > 10 && this.referrals <= 25) {
        this.value += 1.4;
      } else if (this.referrals > 25 && this.referrals <= 49) {
        this.value += 0.9;
      } else {
        this.value += 9.4;
      }
    },
  },
};
</script>
<style></style>
