import Vue from 'vue'
import App from '@/App.vue'
import VueGtag from "vue-gtag"

import BootstrapVue, { IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from '@/router'
import store from '@/store/index.js'
import Trend from 'vuetrend';
import { tezosFilterWord, artContent, dollarFilter, tezosFilter, tezosFilterExact, addressFilter, quantityFilter,etherFilter } from '@/filters'

Vue.use(Trend);
Vue.use(IconsPlugin)

// Vue.config.productionTip = false;
Vue.config.productionTip = true

Vue.use(BootstrapVue)

Vue.filter('dollar', dollarFilter)
Vue.filter('tezos', tezosFilter)
Vue.filter('ethereum', etherFilter)
Vue.filter('tezosExact', tezosFilterExact)
Vue.filter('pkShort', addressFilter)
Vue.filter('quantity', quantityFilter)
Vue.filter('artContent', artContent)
Vue.filter('tezosWord', tezosFilterWord)

// Configuration VueGtag
Vue.use(VueGtag,{
  config: {
    id: 'G-YG806KNBTQ'
  }},router);

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
