import { Bar, mixins } from 'vue-chartjs'

Chart.defaults.borderColor = ['#fff'];


const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options'],

  mounted() {

    this.renderChart(this.chartData, this.options, {

      responsive: true, maintainAspectRatio: false
    })

  }
}
