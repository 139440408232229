<template>
  <div>
    <ol>
      <li
        v-for="item in items"
        :key="item.collection">
        <a :href="item.collectionLink">{{ item.collection }}</a>
        <span>
          {{ ` by ${item.artist}`.concat(item.sales ? `, ${item.sales}` : "").concat(item.TezosVolume ? `,
          ${tezosWord(item.TezosVolume)}` : "").concat(item.price ? `, ${tezosWord(item.price)}` : "").concat(item.floor ?
            `, ${tezosWord(item.floor)}` : "").concat(item.change ? `, (${item.change})` : "") }}
        </span>
      </li>
    </ol>
    <PxPreviewImage :data="preview" />
  </div>
</template>

<script>
import numeral from 'numeral'
import PxPreviewImage from './PxPreviewImage.vue';

import { FXHASH } from '@/API/fxhash.js'

export default {
  name: "TopSalesCollection",
  components: { PxPreviewImage },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      preview: undefined
    };
  },
  watch: {
    items: function(_newVal, _oldVal) {
      this.getPreview()
    }
  },
  mounted() {
    this.getPreview()
  },
  methods: {
    tezosWord(value) {
      if (!value) return "";
      return `${numeral(value).format('0,0')} Tezos`
    },
    async getPreview() {
      const id = this.items[0].collectionLink.replace('https://kaloh.space/collection?id=', '')

      const res = await FXHASH.getCollectionPreview(Number(id))
      this.preview = {
        src: res.displayUri.replace('ipfs://', 'https://gateway.fxhash.xyz/ipfs/'),
        title: res.name,
        author: res.author.name
      }
    }
  }
}

</script>
