<template>
  <b-row>
    <div class="no-data">
      <span
        class="material-symbols-sharp"
        style="font-size: 8rem">
        running_with_errors
      </span>
      <h1 style="text-align: center">
        <span style="font-weight: 800">There is no data to show...</span>
      </h1>
    </div>
  </b-row>
</template>
<script>
export default {
  name: "NoData",
};
</script>
<style>
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  height: 50vh;
}
</style>