<template>
  <main>
    <div
      v-if="!$route.path.includes('/referral')"
      style="position: relative">
      <div
        v-show="feedback === true"
        ref="feedback"
        v-b-modal.feedbackModal
        class="feedback-header">
        <div>
          <p style="margin: 0; font-size: small">
            Kaloh's Space is in Beta.
            <span style="font-weight: 600"> Tell me what you think.</span>
          </p>
        </div>
      </div>
      <div
        v-show="feedback === true"
        style="
          cursor: pointer;
          color: white;
          position: absolute;
          right: 0;
          top: 0;
          padding: 0.5rem;
        ">
        <b-icon
          icon="x-lg"
          @click="feedback = false" />
      </div>
    </div>
    <b-modal
      id="feedbackModal"
      centered
      hide-footer>
      <template #modal-header>
        <h5 style="margin: 0">
          Feedback
        </h5>
        <b-icon
          style="cursor: pointer; color: white"
          icon="x-lg"
          @click="$bvModal.hide('feedbackModal')" />
      </template>

      <b-form
        v-if="show"
        @submit="onSubmit"
        @reset="onReset">
        <b-form-group
          id="input-group-1"
          style="margin-bottom: 1rem"
          label="Email or Twitter"
          label-for="input-1"
          description="We'll never share your email with anyone else.">
          <b-form-input
            id="input-1"
            v-model="form.email"
            style="margin-top: 0.5rem"
            placeholder=""
            required />
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="What would you like see in the future?"
          label-for="input-2"
          style="margin-bottom: 1rem">
          <b-form-textarea
            id="input-2"
            v-model="form.message"
            style="margin-top: 0.5rem; height: 200px"
            placeholder=""
            required />
        </b-form-group>
        <div style="display: flex; justify-content: end">
          <button
            type="submit"
            class="first-button">
            SUBMIT
          </button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="tanksModal"
      centered
      hide-footer
      hide-header>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 2rem;
          margin: 3rem;
        ">
        <b-icon
          icon="check-circle-fill"
          scale="3" />
        <h5 style="font-weight: bold; margin: 0">
          Thanks for your feedback!
        </h5>
      </div>
      <div style="display: flex; justify-content: end" />
    </b-modal>
    <b-container
      :class="!$route.path.includes('/referral')?'main-container': ''"
      fluid>
      <header
        v-if="!$route.path.includes('/referral')"
        class="sticky-top">
        <div class="main-header">
          <div style="display: flex; align-items: center; gap: 1rem">
            <router-link
              to="/"
              style="text-decoration: none; color: inherit">
              <img
                width="150px"
                alt="logo"
                src="../public/assets/boardslogo.png">
            </router-link>
            <div
              class="d-flex align-items-center "
              style=" gap: 0.5rem; color: white">
              <a
                class="social-media"
                style="text-decoration: none; color: #fff"
                target="_blank"
                href="https://twitter.com/Kaloh_nft">
                <Icon
                  icon="formkit:twitter"
                  width="1.5rem" />
              </a>
              <a
                class="social-media"
                style="text-decoration: none; color: #fff"
                target="_blank"
                href="https://www.instagram.com/kaloh_nft/">
                <Icon
                  icon="entypo-social:instagram-with-circle"
                  width="1.6rem" />
              </a>
          
              <a
                class="social-media"
                href="https://www.kaloh.xyz/"
                target="_blank">
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="background-color: #fff; border-radius: 100px;  width:1.5rem; height:1.5rem; padding:0.41rem">
                  <Icon
                    icon="simple-icons:substack"
                    color="black"
                    width="1rem" />
                </div>
              </a>
            </div>
          </div>

          <!-- MENU ICON -->
          <span
            v-show="showMenu"
            
            class="menu-responsive"
            @click="openMenu">
            <Icon
              icon="material-symbols:drag-handle"
              color="white"
              width="32px" />
          </span>
          <!-- CLOSE MENU -->
          <span
            v-show="!showMenu"
            
            class="menu-responsive"
            @click="openMenu">
            <Icon
              icon="material-symbols:close"
              color="white"
              width="32px" />
          </span>

          <div
            ref="navegadorToggle"
            class="navegador">
            <a
              v-show="tezosAddress"
              :href="getCollectorURL()"
              style="margin: 0; text-decoration: none; color: #fff"
              @click="openMenu">
              Your collection
            </a>
            <router-link
              v-show="tezosAddress"
              style="margin: 0; text-decoration: none; color: #fff"
              to="/bookmarks"
              @click="openMenu">
              Bookmarks
            </router-link>
            <p
              v-if="tezosAddress"
              v-b-modal.manageWallets
              class="manage-wallets">
              Manage wallets
            </p>
            <!--WALLET ICON-->
            <span
              v-if="tezosAddress"
              v-b-modal.manageWallets
            
              class="wallet-icon">
              <Icon
                icon="material-symbols:account-balance-wallet"
                width="24px"
                color="white" />
            </span>

            <button
              v-else
              class="first-button"
              @click="doConnectWallet()">
              SYNC
            </button>

            <b-modal
              id="manageWallets"
              centered
              hide-footer>
              <template #modal-header>
                <h5 style="margin: 0">
                  Manage wallets
                </h5>
                <b-icon
                  style="cursor: pointer; color: white"
                  icon="x-lg"
                  @click="$bvModal.hide('manageWallets')" />
              </template>
              <div class="wallets-container">
                <b-form>
                  <label for="connected-wallet">Connected wallet:</label>
                  <b-input-group
                    size="sm"
                    class="mb-2 ">
                    <b-form-input
                      id="connected-wallet"
                      :placeholder="tezosAddress"
                      disabled
                      type="text"
                      readonly />
                    <b-input-group-prepend
                      is-text
                      @click="
                        doConnectWallet();
                        $bvModal.hide('manageWallets');   
                      ">
                      <Icon
                        icon="mdi:logout"
                        color="black"
                        width="22px" />
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form>

                <b-form>
                  <label
                    v-if="addedWallets !== undefined"
                    for="wallets-added">
                    {{ addedWallets.length > 0 ? 'Wallets added' :'Add a wallet' }}
                  </label> 
                  <b-input-group
                    v-for="(addedWallet, index) in addedWallets"
                    :key="index"
                    size="sm"
                    class="mb-2">
                    <b-form-input
                      id="wallets-added"
                      disabled
                      readonly
                      type="text"
                      :placeholder="addedWallet" />
                    <b-input-group-prepend
                      is-text
                      @click="deleteWallet(index)">
                      <Icon
                        icon="material-symbols:delete-outline"
                        color="black"
                        width="22px" />
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form>

                <!--ADD WALLET INPUT-->
                <b-form-group
                  v-if="addedWallets !== undefined"
                  :invalid-feedback="
                    extraWallets === tezosAddress ||
                      addedWallets.includes(extraWallets)
                      ? 'This wallet is already added'
                      : 'Invalid wallet'
                  "
                  :state="state">
                  <b-input-group
                    id="add-wallet"
                    size="sm"
                    class="mb-2">
                    <b-form-input
                      v-model="extraWallets"
                      type="text"
                      placeholder="Type or paste your wallet"
                      trim
                      :state="state" />
                    <b-input-group-prepend
                      v-show="walletSave === false"          
                      is-text
                      @click="saveWallet()">
                      <Icon
                        icon="material-symbols:save-outline"
                        color="black"
                        width="22px" />
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form-group>
                

                <p style="font-size: small">
                  When you add wallets, <b>you can consult the data</b> of your
                  connected wallet and your added wallets together.
                </p>
              </div>
            </b-modal>
          </div>
        </div>
      </header>
      <div
        v-if="!$route.path.includes('/referral')"
        class="slogan">
        <div style="width: 30%">
          <p style="font-weight: bold; font-size: 14px; margin: 0">
            The go to place for
            <span style="color: #ff184d">generative</span> art markets, content
            and events
          </p>
        </div>
        <div style="width: 70%; border-top: 1px solid #46475a" />
      </div>

      <RouterView :key="$route.path" />

      <footer
        v-if="!$route.path.includes('/referral')"
        style="padding: 1.5rem 0rem 1.5rem 0rem">
        <hr>
        <p style="font-size: small; margin: 0">
          Kaloh's Space © 2023
        </p>
        <div
          class="d-flex align-items-center"
          style=" gap: 0.5rem; color: white">
          <a
            style="text-decoration: none; color: #fff"
            target="_blank"
            href="https://twitter.com/Kaloh_nft">
            <Icon
              icon="formkit:twitter"
              width="1.25rem" />
          </a>
          <a
            style="text-decoration: none; color: #fff"
            target="_blank"
            href="https://www.instagram.com/kaloh_nft/">
            <Icon
              icon="entypo-social:instagram-with-circle"
              width="1.25rem" />
          </a>
          
          <a
            href="https://www.kaloh.xyz/"
            target="_blank">
            <div
              class="d-flex align-items-center justify-content-between"
              style="background-color: #fff; border-radius: 100px;  width:1.15rem; height:1.15rem; padding:0.25rem">
              <Icon
                icon="simple-icons:substack"
                color="black"
                width="12px" />
            </div>
          </a>
        </div>
      </footer>
    </b-container>
  </main>
</template>
<script>
import sendMessage from "@/services/discordWebhooks";
import { isValidAddress } from "@/services/tezos";
import { SERVICE_API } from "@/constants";
import { Icon } from '@iconify/vue2'
import { mapGetters } from "vuex";

export default {
  components:{
    Icon
  },
  data() {
    return {
      form: {
        email: "",
        message: "",
      },
      feedback: true,
      show: true,
      showMenu: true,
      isValidAddress: true,
      extraWallets: "",
      walletSave: false,
    };
  },

  computed: {
    ...mapGetters("tezosWallet", [
      "tezosAddress",
      "isWalletConnected",
      "isAllowed",
      "addedWallets"
    ]),
    state() {
      if (this.extraWallets.length === 0) return null;
      return this.validWallet();
    },
  },
  mounted() {
    document.title = "Kaloh's Space";
    this.$store.dispatch("tezosWallet/autoLogin");
  },
  created() {
    document.title = "Kaloh's Space";
    this.validWallet();
  },

  methods: {
    getCollectorURL() {
      const routeName = "collector";
      const params = {
        name: routeName,
        params: {
          collector: this.tezosAddress,
        },
        query: {
          otherWallets: this.addedWallets,
        },
      };

      return this.$router.resolve(params).href;
    },
    validWallet() {
      const re = /^(tz1|tz2)[a-zA-Z0-9]{33}$/;
      this.isValidAddress =
        re.test(this.extraWallets) &&
        !this.addedWallets.includes(this.extraWallets) &&
        this.extraWallets !== this.tezosAddress;
      if (!isValidAddress(this.extraWallets)) {
        this.isValidAddress = false;
        console.error("invalid address by tezos services");
      }
      return this.isValidAddress;
    },
    saveWallet() {
      if (this.state === true) {
        fetch(`${SERVICE_API}/wallet`, {
          method: "POST",
          body: new URLSearchParams({
            name: this.tezosAddress,
            login_wallet: this.tezosAddress,
            email: "",
            password: "",
            wallet: this.extraWallets,
            chain: "tezos",
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              this.addedWallets.push(this.extraWallets);
              this.extraWallets = "";
            }
          })
          .catch((err) => console.error(err));
      }
    },

    deleteWallet(index) {
      fetch(`${SERVICE_API}/wallets`, {
        method: "DELETE",
        body: new URLSearchParams({
          wallet: this.addedWallets[index],
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            this.addedWallets.splice(index, 1);
          }
        })
        .catch((err) => console.error(err));
    },

    openMenu() {
      this.$refs["navegadorToggle"].classList.toggle("navegador-toggle");
      if (this.showMenu) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
    onSubmit(event) {
      event.preventDefault();
      sendMessage(
        "We received feedback!",
        "Feedback",
        [
          { name: "Email", value: this.form.email },
          { name: "Message", value: this.form.message },
        ],
        "feedback"
      );
      this.$bvModal.hide("feedbackModal");
      this.$bvModal.show("tanksModal");
      setTimeout(() => {
        this.$bvModal.hide("tanksModal");
      }, 3000);
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.message = "";

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    async doConnectWallet() {
      if (this.isWalletConnected) {
        await this.$store.dispatch("tezosWallet/disconnect");
        return;
      }
      await this.$store.dispatch("tezosWallet/connect");
    },
  },
};
</script>
<style>
body {
  --bs-body-bg: #090b24;
  --bs-body-color: #fff;
  --bs-body-font-family: "Libre Franklin", sans-serif;
}

body.referral {
  --bs-body-bg: #fff; 
  --bs-body-color: #000000;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#feedbackModal,
#tanksModal {
  color: rgb(0, 0, 0);
}

.main-container {
  max-width: 1440px;
  margin: 0 auto;
}

.main-header {
  width: 100%;
  z-index: 100;
  background-color: #090b24;
  margin-bottom: 1rem;
  padding: 1rem 0rem 1rem 0rem;
}

.navegador {
  display: flex;
  gap: 1rem;
  color: white;
  align-items: center;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  max-height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #090b24;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(146, 146, 146);
}

.modal-header {
  --bs-modal-header-border-color: transparent;
  background-color: #ff184d;
  color: #fff;
  align-items: center;
}

.feedback-header {
  background-color: #ff184d;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pfp-logo {
  border-radius: 50%;
  border: 1px solid #fff;
  max-width: 40px;
}

.style-logo {
  font-family: MOMCAKE;
  font-weight: 700;
  margin: 0;
  line-height: 0.8;
}

.first-button {
  background-color: #090b24;
  color: #fff;
  font-weight: 600;
  border: 1px solid #fff;

  padding: 0.3rem 1.5rem;
}

.second-button {
  color: #10133e;
  font-weight: 600;
  background-color: transparent;
  border: 1px solid #d0d7de;
  padding: 0.3rem 1.5rem;
}

h1 {
  font-weight: 500;
  font-size: 2.6rem;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

.landing-page-btns {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 4rem;
}

.sync-wallet-landing {
  position: absolute;
  padding: 0.8rem;
  margin-bottom: 1.37rem;
  border-radius: 0.3rem;
  left: 20rem;
}

.unsync-btn {
  margin: 0;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  color: #646464;
}


.main-header {
  line-height: 1.5;
  max-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

.substack-style {
  left: -5.5rem;
  position: absolute;
}

.landing-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding: 10rem;
}

#boards-logo {
  width: 50%;
}

.stats-container {
  min-height: 434px;
}

.menu-responsive,
.manage-wallets {
  display: none;
}

.slogan {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.wallets-container {
  color: #646464;
  margin: 3rem 0rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.input-group-text{
  margin-left:-0.2rem;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .main-header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  .main-header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .landing-page {
    display: flex;
    justify-content: center;
    padding: 5rem;
    flex-direction: column;
    height: 100vh;
  }

  #boards-logo {
    width: 70%;
  }

  .slogan {
    display: none;
  }

  .navegador {
    position: absolute;
    width: 100%;
    padding: 20px 0 20px;
    background: #090b24;
    display: flex;

    flex-direction: column;
    justify-content: center;
    top: 60px;
    height: 15vh;
    left: -100%;
    top: 4.2rem;
    transition: all 0.4s;
  }

  .navegador-toggle {
    position: absolute;
    width: 100%;
    padding: 20px 0 20px;
    background: #090b24;

    text-align: left;

    left: 0;
    z-index: 2;
  }

  .menu-responsive,
  .manage-wallets {
    display: block;
  }

  .landing-page {
    display: flex;
    justify-content: center;
    padding: 1rem;
    flex-direction: column;
    height: 100vh;
  }

  .stats-container {
    min-height: inherit;
  }

  #boards-logo {
    width: 80%;
  }

  .sync-wallet-landing {
    position: absolute;
    padding: 0.8rem;
    margin-bottom: 1.37rem;
    border-radius: 0.3rem;
    left: 0rem;
    top: 2rem;
  }

  .social-media,
  .wallet-icon {
    display: none;
  }
}
@media (max-width: 425px) {
}
</style>
