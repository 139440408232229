import { request, gql } from "graphql-request";
import { FXHASH_API, STATS_API } from "@/constants.js";
import { percentFilter, ParseDataToView } from "@/API/Utils.js";

/**
 * @name getCollectionView
 * @description Get data to show in collection view
 * @example
 * getCollectionView();
 * @returns {Object} data with secondary sales, owners and actions. Sales and actions are promises
 */
async function getCollectionView(
  id,
  mkp,
  chain = "tezos",
  time_from = "2021-01-01T15:00:00.000Z"
) {
  if (chain === "tezos") {
    // set config
    let time_to = new Date();
    time_to = time_to.toISOString();
    const collectionId = parseInt(id);
    const parser = new ParseDataToView();
    /**
     * @name historyGetter
     * @description Get history data to show in collection view, used by bar plot
     * @example
     *  historyGetter(65,"2021-01-01T15:00:00.000Z","2023-01-01T15:00:00.000Z",new ParseDataToView());
     * @returns {list} list of {dateIndex: string;sales: number;}
     */
    async function historyGetter(collectionId, time_from, time_to, parser) {
      const body = `{"operationName":"GenerativeTokenMarketHistory","variables":{"id":${collectionId},"filters":{"from":"${time_from}","to":"${time_to}"}},"query":"query GenerativeTokenMarketHistory($id: Float, $filters: MarketStatsHistoryInput!) {\\n  generativeToken(id: $id) {\\n    id\\n    marketStatsHistory(filters: $filters) {\\n      floor\\n      median\\n      from\\n      to\\n      listed\\n      highestSold\\n      lowestSold\\n      primVolumeTz\\n      primVolumeNb\\n      secVolumeTz\\n      secVolumeNb\\n      __typename\\n    }\\n    __typename\\n  }\\n}\\n"}`;
      let historical = [];
      await fetch(FXHASH_API, {
        headers: {
          "accept": "*/*",
          "accept-language": "es-US,es-419;q=0.9,es;q=0.8",
          "content-type": "application/json",
          "sec-fetch-dest": "empty",
          "sec-fetch-mode": "cors",
        },
        body: body,
        method: "POST",
      })
        .then((response) => response.json())
        .then((response) => {
          historical = response.data.generativeToken.marketStatsHistory;
        })
        .catch((err) => console.error("error:" + err));

      // parse to bart plot

      const bartPlotData = parser.parseBartPlot(historical);
      return bartPlotData;
    }

    /**
     * @name collectionInfoData
     * @description Entire collection and stats
     * @example
     *  historyGetter(65,"2021-01-01T15:00:00.000Z","2023-01-01T15:00:00.000Z",new ParseDataToView());
     * @returns {list}
     */
    async function collectionInfoData(slug_id, parser) {
      async function generateQuery() {
        const TokenQuery = gql`
          query MarketStats($generativeTokenId: Float) {
            generativeToken(id: $generativeTokenId) {
              author {
                name
                avatarUri
                id
                collaborators {
                  name
                  avatarUri
                  id
                }
              }
              balance
              id
              name
              objktsCount
              originalSupply
              supply
              displayUri
              thumbnailUri
              entireCollection {
                id
                slug
                name
                owner {
                  name
                  id
                }
              }
              marketStats {
                listed
                floor
                secVolumeTz
                highestSold
              }
            }
          }
        `;

        const data = await request(FXHASH_API, TokenQuery, {
          generativeTokenId: parseInt(slug_id),
        });
        return data;
      }
      const collections = await generateQuery();
      collections.entireCollection =
        collections.generativeToken.entireCollection;
      return parser.ownerAndUniques(collections);
    }
    const owners = await collectionInfoData(collectionId, parser);
    // extract artist data
    const authors = owners.collections.generativeToken.author;
    const artist = parser.artistParser(authors);
    let collectionStats = owners.collections.generativeToken.marketStats;
    collectionStats = {
      ...collectionStats,
      floor: collectionStats.floor / 1000000,
      secVolumeTz: collectionStats.secVolumeTz / 1000000,
      highestSold: collectionStats.highestSold / 1000000,
    };
    /**
     * @name actionsGetter
     * @description Get actions data to show in collection view
     * @example
     *  actionsGetter(65,0,50,new ParseDataToView());
     * @returns {list}
     */
    async function actionsGetter(slug_id, skip = 0, take = 50, parser) {
      const body = gql`
        query GenTokActions($id: Float!, $skip: Int, $take: Int) {
          generativeToken(id: $id) {
            id
            actions(
              skip: $skip
              take: $take
              sort: { createdAt: "DESC" }
              filters: {
                type_in: [
                  LISTING_V1_ACCEPTED
                  LISTING_V2_ACCEPTED
                  LISTING_V3_ACCEPTED
                  OFFER_ACCEPTED
                  COLLECTION_OFFER_ACCEPTED
                ]
              }
            ) {
              id
              type
              opHash
              numericValue
              metadata
              createdAt
              issuer {
                id
                type
                name
                avatarUri
                avatarMedia {
                  cid
                  width
                  height
                  placeholder
                  mimeType
                  metadata
                  processed
                }
                flag
              }
              target {
                id
                type
                name
                avatarUri
                avatarMedia {
                  cid
                  width
                  height
                  placeholder
                  mimeType
                  metadata
                  processed
                }
                flag
              }
              objkt {
                id
                name
                displayUri
                iteration
              }
              token {
                id
                name
              }
            }
          }
        }
      `;

      const data = await request(FXHASH_API, body, {
        id: slug_id,
        skip: skip,
        take: take,
      });
      const actions = data.generativeToken.actions;
      const actionsTable = parser.actionsTableParser(actions);
      return actionsTable;
    }

    // parse to front
    return {
      // metadata
      collectionName: owners.collections.generativeToken.name,
      collectionPreview: String(
        owners.collections.generativeToken.thumbnailUri
      ).replace("ipfs://", "https://media.fxhash.xyz/w_512/"),
      artistInfo: artist,
      editions: owners.collections.generativeToken.originalSupply,
      minted: owners.collections.generativeToken.objktsCount,
      uniqueCollectors: {
        value: owners.collections.statsUnique.uniqueMinters,
        percent: percentFilter(
          (owners.collections.statsUnique.uniqueMinters /
            owners.collections.statsUnique.totalMints) *
            100
        ),
      },
      listForSale: {
        value: owners.collections.generativeToken.marketStats.listed,
        percent: percentFilter(
          (owners.collections.generativeToken.marketStats.listed /
            owners.collections.statsUnique.totalMints) *
            100
        ),
      },
      collectionStats: collectionStats,
      holders: owners.holders,
      // secondary sales
      secondarySales: async function () {
        return historyGetter(collectionId, time_from, time_to, parser).then(
          (bartPlotData) => {
            return {
              labels: bartPlotData.map(function (item) {
                return item.dateIndex;
              }),
              data: bartPlotData.map(function (item) {
                return item.sales;
              }),
            };
          }
        );
      },
      // actions table promise
      actions: async function () {
        return actionsGetter(collectionId, 0, 50, parser).then(
          (actionsTable) => {
            return {
              secondaryActions: actionsTable.map(function (item) {
                return { x: item.date, y: item.price };
              }),
              secondaryActionsTable: actionsTable,
            };
          }
        );
      },
      //before: before,
      mkp: mkp,
      linkCollection: `https://www.fxhash.xyz/marketplace/generative/${collectionId}`,
    };
  } else {
    // get data from STATS_API /ethereum/artist/stats with query wallet=artistId
    const options = {
      method: "GET",
    };
    try {
      const statsEther = await fetch(
        `${STATS_API}/ethereum/collection/stats?slug_id=${id}`,
        options
      );
      const statsEtherJson = await statsEther.json();
      return statsEtherJson;
    } catch (err) {
      console.error(err);
      return {};
    }
  }
}

export default getCollectionView;
