<template>
  <div>
    <ol>
      <li
        v-for="item in items"
        :key="item.collection">
        <a :href="item.artistLink">{{ item.artist }}</a>
        <span>
          {{ (item.sales ? ', ' + item.sales : '') + (item.TezosVolume ? ', ' + tezosWord(item.TezosVolume) : '') + (item.price ? ', ' + tezosWord(item.price) : '') + (item.floor ? ', ' + tezosWord(item.floor) : '') + (item.change ? ', (' + item.change + ')' : '') }}
        </span>
      </li>
    </ol>
  </div>
</template>

<script>
import numeral from 'numeral'

export default {
  name: "TopSalesArtist",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    tezosWord(value) {
      if (!value) return "";
      return `${numeral(value).format('0,0')} Tezos`
    }
  }
}

</script>
