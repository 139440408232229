<template>
  <div>
    <div class="filters-container">
      <div class="all-filters">
        <div class="filters-artist">
          <button
            :class="{
              'active-btn': activeButton === 'namecollection',
              'disabled-btn': activeButton !== 'namecollection',
            }"
            @click="
              filterSelected('namecollection');
              onEnter();
            ">
            Collection
          </button>
          <button
            :class="{
              'active-btn': activeButton === 'artist',
              'disabled-btn': activeButton !== 'artist',
            }"
            @click="
              filterSelected('artist');
              onEnter();
            ">
            Artist
          </button>
          <button
            :class="{
              'active-btn': activeButton === 'highestSales',
              'disabled-btn':
                activeButton === 'artist' ||
                activeButton === 'namecollection',
            }"
            @click="
              filterSelected('highestSales');
              getHighSales();
            ">
            Highest sales
          </button>
        </div>
        <div v-if="activeButton === 'highestSales'">
          <b-dropdown
            id="dropdown-2-1"
            :text="frequencySelected"
            class="m-md-2">
            <b-dropdown-item
              v-for="item2 in frequencies"
              :key="item2"
              @click="
                selectFrequency(item2);
                getHighSales();
              ">
              {{ item2 }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-else>
          <b-dropdown
            id="dropdown-2"
            :text="frequencySelected">
            <b-dropdown-item
              v-for="item2 in frequencies"
              :key="item2"
              style="margin: 0"
              @click="
                selectFrequency(item2);
                onEnter();
              ">
              {{ item2 }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div>
        <div class="substack">
          <p style="font-size: small; color: #ffffffb6; margin: 0">
            Subscribe to my newsletter to get insights about the gen art world
          </p>
          <PxSubstack style="margin-bottom: 1rem" />
        </div>
      </div>
    </div>



    <p style="font-weight: bold; margin-top: 1rem">
      <span v-if="activeButton !== 'highestSales'"> Hot</span><span v-else />
      {{ selectedText }} over
      <span v-show="selectedTimeText !== 'all the time'">the past</span>
      {{ selectedTimeText }} on:
    </p>

    <!--FILTERS TAGS-->
    <div
      class="d-flex flex-direction-row alig-items-center justify-content-between flex-wrap mb-4">
      <div
        class="d-flex"
        style="gap:1rem">
        <div
          v-for="market in markets"
          :key="market.value"
          :class="market.class"
          style="border-radius: 50px; gap:0.5rem"
          @click="doSelectMarket(market.value)">
          <b-avatar
            class="border"
            size="1rem"
            :src="market.logo" />

          <p class="m-0 small">
            {{ market.text }}
          </p>
        </div>
      </div>
   
      
      <div class="py-2 d-flex aling-items-center justify-content-between">
        <!-- <div> -->
        <p 
          v-if="activeButton !== 'artist'"
          class="m-0 small">
          Switch to dollars
        </p>
        <SwitchButton 
          v-if="activeButton !== 'artist'"
          :value="isUSD"
          @switch-usd="switchUSD" />
        <!-- add margin left -->
        <!-- <p 
          v-if="activeButton === 'artist'"
          class="mt-0 mb-0 small" 
          style="margin-left: 2em;">
          Merge artists
        </p>
        <SwitchButton
          v-if="activeButton === 'artist'"
          :value="mergedArtists"
          @switch-usd="switchMergeArtist" /> -->
      </div>
      <!-- </div> -->
    </div>
    
    <b-row v-if="activeButton === 'highestSales'">
      <PxHighestSales
        :highest-sales="highestSales"
        :chain-filter-coin="chainFilterCoin" 
        :is-u-s-d="isUSD" />
    </b-row>
    <b-row v-else>
      <b-col
        cols="12"
        lg="4"
        style="position: relative; margin-bottom: 1rem">
        <!--TABLE 1 -->
        <b-table
          class="general-table"
          striped
          hover
          responsive
          :sticky-header="screenWidth <= 768 ? '2500px' : '600px'"
          :items="items1"
          :fields="fields1"
          no-sort-reset
          :busy="isBusy"
          :sort-by="sortConfig1.by"
          :sort-desc="sortConfig1.order"
          label-sort-asc=""
          label-sort-desc="">
          <template #table-busy>
            <div class="text-center spinner-loading">
              <b-spinner />
            </div>
          </template>
          <template #head(pfp)>
            <span />
          </template>
          <template #head(Collection)>
            <span>
              {{ selectedText }}
            </span>
          </template>
          <template #cell(pfp)="row">
            <div
              v-b-tooltip.hover="
                row.item.collected ? tooltipOptionCollected : ''
              "
              style="width: 30px; height: 30px;position:relative">
              <b-avatar
                variant="light"
                size="1.75rem"
                :icon="activeButton === 'artist' ? 'person-fill':'image-fill'"
                :src="row.item.logo" />
              
              <div
                v-if="row.item.collected"
                class="collected-icon">
                <span
                  style="color: #fff; font-size: 10px"
                  class="material-symbols-sharp">
                  star_rate
                </span>
              </div>
              <div
                v-if="row.item.bookmarked"
                class="bookmarked-icon">
                <span
                  style="color: #fff; font-size: 10px"
                  class="material-symbols-sharp">
                  bookmark
                </span>
              </div>
            </div>
          </template>
          <template #cell(Collection)="row">
            <router-link
              v-if="selectedText === 'Artists'"
              v-b-tooltip.hover
              class="collection-name"
              :to="artistRedirect(row)">
              <a
                :title="row.item.Collection"
                class="collection-name">
                {{ row.item.Collection }}
              </a>
            </router-link>
            <router-link
              v-else
              v-b-tooltip.hover
              class="collection-name"
              :to="collectionRedirect(row)"
              :replace="false">
              <a
                :title="row.item.Collection"
                class="collection-name"
                target="_blank">
                {{ row.item.Collection }}
              </a>
            </router-link>
            <div
              v-if="row.item.chain ==='tezos' || row.item?.chainTxt?.length > 0"
              class="d-flex  align-items-center"
              style="color:#fff; border-radius: 50px; gap:0.25rem">
              <b-avatar
                src="https://pbs.twimg.com/profile_images/1468745106786242566/hgMjtzcV_400x400.png"
                size="0.8rem" />
              <p class="small m-0">
                fxhash
              </p>
            </div>

            <div
              v-if="row.item.chain === 'ether' || row.item?.chainTxt?.length > 0"
              class="d-flex  align-items-center"
              style="color:#fff; border-radius: 50px; gap:0.25rem">
              <b-avatar
                src="https://pbs.twimg.com/profile_images/1582886125336608768/ZeBztIcV_400x400.png"
                size="0.8rem" />
              <p class="small m-0">
                Art Blocks
              </p>
            </div>
          </template>
          <template #cell(Floor)="row">
            {{ chainFilterCoin(row.item.chain, row.item.Floor) }}
          </template>

          <template #cell(Change_%)="row">
            <div
              :class="{
                'floor-negative': parseFloat(row.item['Change_%']) < 0,
                'floor-positive': parseFloat(row.item['Change_%']) > 0,
              }">
              {{ row.item["Change_%"] }}
            </div>
          </template>

          <template #cell(trend)="row">
            <trend
              :data="row.item.trend"
              :gradient="
                row.item['Change_%'] === 'New!'
                  ? ['#ffffff']
                  : parseFloat(row.item['Change_%']) >= 0
                    ? ['#16C784']
                    : ['#ff184d']
              "
              :stroke-width="1.5"
              :height="35"
              :width="80"
              :padding="0"
              auto-draw
              :smooth="true" />
          </template>
          <template #head(trend)>
            <span />
          </template>
        </b-table>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        style="position: relative; margin-bottom: 1rem">
        <!--TABLE 2-->
        <b-table
          class="general-table"
          striped
          no-sort-reset
          hover
          :sticky-header="screenWidth <= 768 ? '2100px' : '600px'"
          :fields="fields2"
          :items="items2"
          :busy="isBusy"
          :sort-by="sortConfig2.by"
          :sort-desc="sortConfig2.order"
          label-sort-asc=""
          label-sort-desc="">
          <template #table-busy>
            <div class="text-center spinner-loading">
              <b-spinner />
            </div>
          </template>
          <template #head(pfp)>
            <span />
          </template>
          <template #head(Collection)>
            <span>
              {{ selectedText }}
            </span>
          </template>
          <template #cell(pfp)="row">
            <div
              v-b-tooltip.hover="
                row.item.collected ? tooltipOptionCollected : ''
              "
              style="width: 30px; height: 30px; position: relative">
              <b-avatar
                variant="light"
                size="1.75rem"
                :icon="activeButton === 'artist' ? 'person-fill':'image-fill'"
                :src="row.item.logo" />
              <div
                v-if="row.item.collected"
                class="collected-icon">
                <span
                  style="color: #fff; font-size: 10px"
                  class="material-symbols-sharp">
                  star_rate
                </span>
              </div>
              <div
                v-if="row.item.bookmarked"
                class="bookmarked-icon">
                <span
                  style="color: #fff; font-size: 10px"
                  class="material-symbols-sharp">
                  bookmark
                </span>
              </div>
            </div>
          </template>
          <template #cell(Collection)="row">
            <router-link
              v-if="selectedText === 'Artists'"
              v-b-tooltip.hover
              class="collection-name"
              :to="artistRedirect(row)">
              <a
                :title="row.item.Collection"
                class="collection-name">
                {{ row.item.Collection }}
              </a>
            </router-link>
            <router-link
              v-else
              v-b-tooltip.hover
              :to="collectionRedirect(row)"
              :replace="false"
              style="text-decoration: none">
              <a
                :title="row.item.Collection"
                class="collection-name"
                target="_blank">
                {{ row.item.Collection }}
              </a>
            </router-link>

            <div
              v-if="row.item.chain ==='tezos' || row.item?.chainTxt?.length > 0"
              class="d-flex  align-items-center"
              style="color:#fff; border-radius: 50px; gap:0.25rem">
              <b-avatar
                src="https://pbs.twimg.com/profile_images/1468745106786242566/hgMjtzcV_400x400.png"
                size="0.8rem" />
              <p class="small m-0">
                fxhash
              </p>
            </div>

            <div
              v-if="row.item.chain === 'ether' || row.item?.chainTxt?.length > 0"
              class="d-flex  align-items-center"
              style="color:#fff; border-radius: 50px; gap:0.25rem">
              <b-avatar
                src="https://pbs.twimg.com/profile_images/1582886125336608768/ZeBztIcV_400x400.png"
                size="0.8rem" />
              <p class="small m-0">
                Art Blocks
              </p>
            </div>
          </template>
          <template #cell(Secondary_Volume)="row">
            {{ chainFilterCoin(row.item.chain,parseFloat(row.item.Secondary_Volume)) }}
          </template>
        </b-table>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        style="position: relative; margin-bottom: 1rem">
        <!--TABLE 3 -->
        <b-table
          class="general-table thead-dark"
          striped
          hover
          no-sort-reset
          :sticky-header="screenWidth <= 768 ? '2100px' : '600px'"
          :fields="fields3"
          :items="items3"
          :busy="isBusy"
          :sort-by="sortConfig3.by"
          :sort-desc="sortConfig3.order"
          label-sort-asc=""
          label-sort-desc="">
          <template #table-busy>
            <div class="text-center spinner-loading">
              <b-spinner />
            </div>
          </template>
        
          <template #head(pfp)>
            <span />
          </template>
          <template #head(Collection)>
            <span>
              {{ selectedText }}
            </span>
          </template>
          <template #cell(pfp)="row">
            <div style="width: 30px; height: 30px;position: relative">
              <b-avatar
                variant="light"
                size="1.75rem"
                :icon="activeButton === 'artist' ? 'person-fill':'image-fill'"
                :src="row.item.logo" />
              <div
                v-if="row.item.collected"
                v-b-tooltip.hover="
                  row.item.collected ? tooltipOptionCollected : ''
                "
                class="collected-icon">
                <span
                  style="color: #fff; font-size: 10px"
                  class="material-symbols-sharp">
                  star_rate
                </span>
              </div>
              <div
                v-if="row.item.bookmarked"
                class="bookmarked-icon">
                <span
                  style="color: #fff; font-size: 10px"
                  class="material-symbols-sharp">
                  bookmark
                </span>
              </div>
            </div>
          </template>
          <template
            #cell(Collection)="row">
            <div>
              <router-link
                v-if="selectedText === 'Artists'"
                v-b-tooltip.hover
                class="collection-name"
                :to="artistRedirect(row)">
                <a
                  :title="row.item.Collection"
                  class="collection-name">
                  {{ row.item.Collection }}
                </a>
              </router-link>
              <router-link
                v-else
                v-b-tooltip.hover
                :to="collectionRedirect(row)"
                :replace="false"
                style="text-decoration: none">
                <a
                  :title="row.item.Collection"
                  class="collection-name"
                  target="_blank">
                  {{ row.item.Collection }}
                </a>
              </router-link>
              <div
                v-if="row.item.chain ==='tezos' || row.item?.chainTxt?.length > 0"
                class="d-flex  align-items-center"
                style="color:#fff; border-radius: 50px; gap:0.25rem">
                <b-avatar
                  src="https://pbs.twimg.com/profile_images/1468745106786242566/hgMjtzcV_400x400.png"
                  size="0.8rem" />
                <p class="small m-0">
                  fxhash
                </p>
              </div>

              <div
                v-if="row.item.chain === 'ether' || row.item?.chainTxt?.length > 0"
                class="d-flex  align-items-center"
                style="color:#fff; border-radius: 50px; gap:0.25rem">
                <b-avatar
                  src="https://pbs.twimg.com/profile_images/1582886125336608768/ZeBztIcV_400x400.png"
                  size="0.8rem" />
                <p class="small m-0">
                  Art Blocks
                </p>
              </div>
            </div>
          </template>
          <template #head(Secondary_pieces_sold)>
            <span>Secondary pcs sold</span>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row style="margin-top: 2rem">
      <b-col
        cols="12"
        lg="6">
        <div style="border: 1px solid #46475a; margin-bottom: 1rem">
          <header
            style="
              background-color: #fff;
              color: black;
              padding: 0.5rem 1rem 0.5rem 1rem;
            ">
            <div style="display: flex; gap: 0.5rem">
              <Icon
                icon="material-symbols:local-library"
                color="black"
                width="1.5rem" />
              <p style="margin: 0; font-weight: 600">
                Around the block
              </p>
            </div>
          </header>
          <p style="padding: 1rem 1rem 0rem 1rem">
            Interesting content around web
          </p>
          <hr>
          <div
            v-for="(item, index) in articles"
            :key="index"
            class="events-btn">
            <a
              :href="item.url"
              target="_blank"
              style="font-weight: 600; margin: 0">
              <div style="display: flex; align-items: center; gap: 1rem">
                <div>
                  <img
                    :src="articlesLogo[item.origin]"
                    height="70px">
                </div>
                <div>
                  <p
                    class="subtitle-class"
                    style="font-size: 11px; margin: 0">
                    Origin: {{ item.origin }}
                  </p>
                  <p class="itemTitle">{{ item.title }}</p>

                  <p class="subtitle-class">
                    {{ item.subtitle }}
                  </p>

                  <!-- <a
                    :href="item.origin"
                    target="_blank"
                    style="font-size: small; margin: 0"
                    >Origin: {{ item.origin }}</a
                  > -->
                </div>
              </div>
            </a>
          </div>
        </div>
      </b-col><b-col
        cols="12"
        lg="6">
        <div style="border: 1px solid #46475a; margin-bottom: 1rem">
          <header
            style="
              background-color: #fff;
              color: black;
              padding: 0.5rem 1rem 0.5rem 1rem;
            ">
            <div style="display: flex; gap: 0.5rem">
              <Icon
                icon="mdi:world"
                width="1.5rem" />

              <p style="margin: 0; font-weight: 600">
                Around the world
              </p>
            </div>
          </header>
          <p style="padding: 1rem 1rem 0rem 1rem">
            Interesting events, galleries and exhibitions
          </p>
          <hr>
          <div
            v-for="(item, index) in events"
            :key="index"
            class="events-btn">
            <a
              target="_blank"
              :href="item.link"
              style="font-weight: 600; margin: 0">
              <div style="display: flex; align-items: center; gap: 1rem">
                <div>
                  <img
                    :src="item.logo"
                    height="70px">
                </div>
                <div>
                  <p class="itemTitle">{{ item.name }}</p>
                  <p class="subtitle-class">
                    {{ item.description }}
                  </p>
                  <p style="font-size: small; margin: 0">
                    {{ item.date + "\t" + item.city + ", " + item.country }}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// modules
import aliasSuggest from "@/API/teztokSuggestions";
import {hotCollection,mergeArtist} from "@/API/kalohBoardAPI.js";
import getArticles from "@/API/articles.js";
import getEvents from "@/API/events.js";
import { ARTICLES_LOGO } from "@/constants";
import getHighestSales from "@/API/getHighestSales.js";
import getHoldingsByUser from "@/API/getHoldingsByUser.js";
import { SERVICE_API } from "@/constants";
import { mapGetters } from "vuex";
// import getCollectionView from "@/API/collectionView.js";
import PxSubstack from "@/components/PxSubstack.vue";
import SwitchButton from "@/components/SwitchButton.vue";
import PxHighestSales from "@/components/PxHighestSales.vue";
import numeral from 'numeral'
import { Icon } from '@iconify/vue2'


// components

export default {
  name: "ViewHome",
  components: {
    PxSubstack,
    SwitchButton,
    PxHighestSales,
    Icon
  },
  data() {
    return {
      isUSD: false,
      mergedArtists: true,
      selectedMarkets: [], //Here there are the selected markets
      //Here there are the current markets in our app
      markets: [
        { text: 'fxhash', value: 'fxhash', logo:'https://pbs.twimg.com/profile_images/1468745106786242566/hgMjtzcV_400x400.png', class:'selected-market'},
        { text: 'Art Blocks', value: 'artblocks', logo:'https://pbs.twimg.com/profile_images/1582886125336608768/ZeBztIcV_400x400.png', class:'selected-market'},
      ],
     
      tooltipOptionCollected: {
        title: "In your collection",
        placement: "topleft",
      },
      collectedByUser: [],
      checked: false,
      tempData2: undefined,
      articlesLogo: ARTICLES_LOGO,
      articles: [],
      events: [],
      // Table format
      fields1: [
        { key: "pfp", stickyColumn: true, sortable: false },
        // { key: "Updated", sortable: true },
        {
          key: "Collection",
          stickyColumn: true,

          sortable: false,
        },
        // { key: "link", sortable: true },
        // { key: "CollectionName", sortable: true },
        // { key: "Artist", sortable: true },
        // { key: "Account", sortable: true },
        { key: "Floor", sortable: true },
        { key: "Change_%", sortable: true },
        { key: "trend", sortable: false },
      ],
      fields2: [
        { key: "pfp", sortable: false, stickyColumn: true },
        { key: "Collection", sortable: false, stickyColumn: true },
        { key: "Secondary_Volume", sortable: true },
      ],
      fields3: [
        { key: "pfp", sortable: false, stickyColumn: true },
        { key: "Collection", sortable: false, stickyColumn: true },
        { key: "Secondary_pieces_sold", sortable: true },
      ],
      isBusy: false,
      items1: [],
      items2: [],
      items3: [],
      sortConfig1: { by: "floorUSD", order: true },
      sortConfig2: { by: "volumenAcumUSD", order: true },
      sortConfig3: { by: "Secondary_pieces_sold", order: true },
      // data fetching
      dataToBoard: undefined,
      dataToBoardPersistence: undefined,
      aliasDataSuggestion: undefined,
      chosen: "",
      // UI parameters
      Top_n: {
        "1": 15,
        "7": 15,
        "30": 18,
        "365": 20,
        "-1": 20,
      },
      Days: 7,
      filtered: "name",
      frequencies: undefined,
      frequencySelected: "Weekly",
      showDiv: false,
      tempData: undefined,

      activeButton: "namecollection",

      selectedText: "Collections",
      selectedTimeText: "day",

      screenWidth: 0,

      highestSales: undefined,
      highestSalesPersistence: undefined,
    };
  },
  computed: {
    ...mapGetters("tezosWallet", [
      "tezosAddress",
      "isWalletConnected",
      "isAllowed",
      "addedWallets",
    ]),
  },
  watch: {
    chosen: async function (val, _oldVal) {
      if (val.length > 3) {
        const response = await aliasSuggest(val);
        this.aliasDataSuggestion = response.tzprofiles.map(function (user) {
          return user.alias;
        });
      }
    },
    selectedMarkets(newSelectedMarkets){
      this.filterTableItems(newSelectedMarkets);
    },
  },
  created() {
    
    this.frequencies = ["Daily", "Weekly", "Monthly", "Yearly", "All time"];
    getArticles(10, 10).then((x) => {
      this.articles = x;
    });
    getEvents(10, 0).then((x) => {
      this.events = x;
    });
    window.addEventListener("resize", this.updateScreenWidth);
    this.updateScreenWidth();
  },
  mounted() {
    this.selectedMarkets = this.markets.map((market) => market);
    this.onEnter(); 
  },
  // in component
  methods: {
    switchUSD(value){
      this.isUSD = value
      this.filterTableItems(this.selectedMarkets)
    },
    switchMergeArtist(value){
      this.mergedArtists = value
      this.isUSD = true
      this.filterTableItems(this.selectedMarkets)
    },
    chainFilterCoin(chain,value){
      if (this.isUSD) return this.dollarFilter(value)
      return chain === 'tezos' ? this.tezosFilter(value) : this.etherFilter(value)
    },
    dollarFilter(value){
      if (!value) return '$ 0'
      return `$ ${numeral(value).format('0,000.00')}`
    },
    tezosFilter(value){
      if (!value) return 'ꜩ 0'
      return `ꜩ ${numeral(value).format('0.0')}`
    }, 
    etherFilter(value){
      if (!value) return 'Ξ 0'
      return `Ξ ${numeral(value).format('0,000.0000')}`
    },
    artistRedirect(rowParam) {
      return { 
        name: 'artist', 
        params: { 
          artist: rowParam.item.slugId
        },
        query: { 
          chain: rowParam.item.chain === 'ether' ? 'ethereum' : rowParam.item.chain
        }
      }
    },
    collectionRedirect(rowParam){
      return { 
        name: 'collection', 
        query: { 
          id: rowParam.item.slugId,
          chain: rowParam.item.chain === 'ether' ? 'ethereum' : rowParam.item.chain
        }
      }
    },
    //This function check or uncheck the selected markets
    doSelectMarket(marketValue) {
      const marketIndex = this.markets.findIndex((market) => market.value === marketValue);
      if (marketIndex !== -1) {
        const selectedMarketIndex = this.selectedMarkets.findIndex((selectedMarket) => selectedMarket.value === marketValue);
        if (selectedMarketIndex === -1) {         
          this.selectedMarkets.push(this.markets[marketIndex]);
          this.markets[marketIndex].class = 'selected-market'

        } else if(this.selectedMarkets.length  !== 1){        
          this.selectedMarkets.splice(selectedMarketIndex, 1);
          this.markets[marketIndex].class = 'disabled-market'
        }
      }
    },
    async filterTableItems(newSelectedMarkets) {
      if(this.activeButton === 'highestSales') {
        if(this.highestSales != undefined){
          this.isBusy = !this.isBusy;
          this.highestSales = this.highestSalesPersistence.filter((item) => {
            return newSelectedMarkets.some((selectedMarket) => {
              return selectedMarket.value === item.mkpId;
            });
          });
          this.isBusy = !this.isBusy;
        }
      } else {
        if(this.dataToBoardPersistence != undefined){
          this.isBusy = !this.isBusy;
          //update tables
          await this.parsingData(newSelectedMarkets);
          this.isBusy = !this.isBusy;
        }
      }
    },
    async getBookmarks(type = "collection") {
      const res = await fetch(
        `${SERVICE_API}/bookmarksbyfilter?login_wallet=${this.tezosAddress}&type=${type}`
      );
      const data = await res.json();
      return data ? data : [];
    },
    async getHighSales() {
      this.highestSales = [];
      this.highestSales = await getHighestSales(
        // this.Top_n[this.Days],
        30,
        this.Days,
        "price",
        0
      );
      this.highestSalesPersistence = this.highestSales;
      this.filterTableItems(this.selectedMarkets)
      if (this.frequencySelected === "All time")
        return (this.selectedTimeText = "all the time");
    },
    async getData() {
      this.dataToBoard = await hotCollection(
        this.Top_n[this.Days],
        this.Days,
        this.activeButton
      );
      this.dataToBoardPersistence = [...this.dataToBoard];
    },
    async parsingData(selectedMarkets) {
      this.dataToBoard = undefined
      // filter dataToBoardPersistence by selectedMarkets list and update dataToBoard
      const newData = this.dataToBoardPersistence.filter((item) => {
        return selectedMarkets.some((selectedMarket) => {
          return selectedMarket.value === item.mkpId;
        });
      });
      this.dataToBoard = [...newData];
      this.items1 = [];
      this.items3 = [];
      this.items2 = [];
      if (this.collectedByUser.length === 0) {
        this.collectedByUser = await getHoldingsByUser(
          this.tezosAddress,
          this.addedWallets
        );
      }
      let collectedData = [];
      if (this.activeButton === "artist") {
        collectedData = this.collectedByUser.byArtist.map((item) => {
          return item.ArtistId;
        });
        collectedData = collectedData ?? [];
        // merge artists with same name
        if (this.mergedArtists && this.isUSD) {
          this.dataToBoard = mergeArtist([...newData]);
        }
      } else {
        collectedData = this.collectedByUser.byCollection.map((item) => {
          return item.CollectionId;
        });
        collectedData = collectedData ?? [];
      }
      const bookmarkedCollections = await this.getBookmarks(
        this.activeButton === "artist" ? "artist" : "collection"
      );
      // to know if the collection is bookmarked
      this.dataToBoard.forEach((collection) => {
        const isBookmark = bookmarkedCollections.filter((item) => {
          return (
            String(item.id) === String(collection.slug_id) ||
            String(item.id) === String(collection.pkauthor)
          );
        });
        collection.bookmarked = isBookmark.length > 0 ? true : false;
        const isCollected = collectedData.filter((item) => {
          return (
            String(item) === String(collection.slug_id) ||
            String(item) === String(collection.pkauthor)
          );
        });
        collection.collected = isCollected.length > 0 ? true : false;
      });
      this.dataToBoard.forEach((collection) => {
        const item1 = {};
        const item2 = {};
        const item3 = {};
        const filterTable = collection[this.filtered];
        const link =
          this.activeButton === "artist" ? `${filterTable}` : collection.link;
        // set data
        // item1.Updated = collection.date_from;
        item1.slugId =
          this.activeButton === "artist"
            ? collection.pkauthor
            : String(collection.slug_id);
        item1.chain = collection.chain;
        item1.chainTxt = collection.chainTxt;
        item1.Collection = filterTable;
        item1.link = link;
        // item.CollectionName = collection;
        // item.Artist = collection;
        // item.Account = collection;
        item1.Floor = this.isUSD ? collection.floorUSD : collection.floor;
        item1["Change_%"] = collection.incrementoAcum;
        item1.logo = collection.linkLogo;
        item1.trend = collection.floorTrend;
        item1.collected = collection.collected;
        item1.bookmarked = collection.bookmarked;
        item1.volumenAcumUSD = collection.volumenAcumUSD;
        item1.floorUSD = collection.floorUSD;
        // item.Volume = collection.TotalSalesTz;
        item2.slugId =
          this.activeButton === "artist"
            ? collection.pkauthor
            : String(collection.slug_id);
        item2.chain = collection.chain;
        item2.chainTxt = collection.chainTxt;
        item2.Collection = filterTable;
        item2.Secondary_Volume = this.isUSD ? collection.volumenAcumUSD : collection.volumenAcumTz;
        // item2.Median = collection.median;
        item2.link = link;
        item2.logo = collection.linkLogo;
        item2.trend = collection.volumenAcumTzTrend;
        item2.collected = collection.collected;
        item2.bookmarked = collection.bookmarked;
        item2.volumenAcumUSD = collection.volumenAcumUSD;
        item2.floorUSD = collection.floorUSD;
        item3.slugId =
          this.activeButton === "artist"
            ? collection.pkauthor
            : String(collection.slug_id);
        item3.chain = collection.chain;
        item3.chainTxt = collection.chainTxt;
        item3.Collection = filterTable;
        item3.Secondary_pieces_sold = collection.volumenAcumNb;
        item3.link = link;
        item3.logo = collection.linkLogo;
        item3.trend = collection.volumenAcumNbTrend;
        item3.collected = collection.collected;
        item3.bookmarked = collection.bookmarked;
        item3.volumenAcumUSD = collection.volumenAcumUSD;
        item3.floorUSD = collection.floorUSD;
        this.items1.push(item1);
        this.items2.push(item2);
        this.items3.push(item3);
      });
      return null;
      // this.fields[this.fields.length - 1].key = "_1st_sales";
    },
    selectFrequency(selected) {
      this.frequencySelected = selected;
      switch (selected) {
        case "Daily":
          this.Days = 1;
          this.selectedTimeText = "day";
          break;
        case "Weekly":
          this.Days = 7;
          this.selectedTimeText = "week";
          break;
        case "Monthly":
          this.Days = 30;
          this.selectedTimeText = "month";
          break;
        case "Yearly":
          this.Days = 365;
          this.selectedTimeText = "year";
          break;

        default:
          this.Days = -1;

          break;
      }
    },
    filterSelected(how) {
      switch (how) {
        case "namecollection":
          this.filtered = "name";
          this.activeButton = "namecollection";
          this.selectedText = "Collections";
          this.mergedArtists = false;
          this.isUSD = false;

          break;
        case "artist":
          this.filtered = "author";
          this.activeButton = "artist";
          this.selectedText = "Artists";
          this.mergedArtists = true;
          this.isUSD = true;
          break;
        case "highestSales":
          this.activeButton = "highestSales";
          this.selectedText = "Highest Sales";
          break;
      }
    },
    async onEnter() {
      this.isBusy = !this.isBusy;
      await this.getData();
      await this.parsingData(this.selectedMarkets);
      this.isBusy = !this.isBusy;
      if (this.frequencySelected === "All time")
        return (this.selectedTimeText = "all the time");
    },
    hideButton() {
      this.showDiv = !this.showDiv;
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style>



pre {
  white-space: pre-wrap;
  overflow-x: auto;
  background: rgb(57, 26, 4);
  border: 1px solid #777;
  color: white;
  padding: 5px;
}

button {
  margin: 4px;
}

.material-symbols-sharp {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  line-height: inherit;
}

.disabled-btn {
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: transparent;
  border: none;
  color: #000000;
  padding: 0.3rem 1rem;
  font-size: small;
  transition: background-color 0.3s ease;
  border-radius: 0.3rem;
}

.active-btn {
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: #ff184d;
  border: none;
  color: #fff;
  padding: 0.3rem 1rem;
  font-size: small;
  transition: background-color 0.3s ease;
}

.input_class {
  padding: 5px;
  font-size: 13px;
}

.text_span {
  padding: 4px;
  font-size: 15px;
  margin-bottom: 15px;
}



.column {
  float: left;
  width: 33.33%;
}

.filters-artist {
  background-color: #fff;
}

.btn {
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 0;
  font-size: small;
  padding: 0.67rem 1rem;
}

.btn:hover {
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 0;
  font-size: small;
}

.general-table {
  font-size: small;
  background-color: #090b24;
  white-space: nowrap;
}

.collection-name {
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all ease 0.3s;
  max-width: 12ch;
  display: table-cell;
  text-decoration: none;
}

.collection-name:hover {
  color: #ff184d;
}

.floor-negative {
  color: #ff184d;
}

.floor-positive {
  color: #16c784;
}

tr {
  border: 1px solid #46475a;
}

td {
  vertical-align: middle;
}

.events-btn {
  padding: 1rem;
  cursor: pointer;
}

.itemTitle {
  margin: 0;
  font-size: 14px;
}

.events-btn:hover .itemTitle {
  color: #ff184d;
  transition: all ease 0.3s;
}

.events-btn a {
  text-decoration: none;
  color: #fff;
  font-size: small;
}

.all-filters {
  display: flex;
  align-items: center;
}

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.subtitle-class {
  font-size: 14px;
  margin: 0;
  opacity: 0.7;
  font-weight: 300;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 3.6rem;

  overflow: hidden;
}

.table.b-table > tbody > tr > .table-b-table-default {
  background-color: #090b24;
}

.switch-currency {
  border: 1px solid #46475a;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.substack {
  display: flex;

  gap: 1rem;
  flex-direction: column;
}

.collected-icon {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: red;
  top: -10%;
  right: -20%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.b-avatar{
  color:#fff
}

.bookmarked-icon {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: red;
  top: -10%;
  left: -20%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.selected-market{
  display: flex;
  align-items: center;
  gap:1rem;
  padding:0.15rem 0.85rem;
  height: 30px;
  background-color: #fff;
  color:#000000;
  font-size: small;
  font-weight: 600;
  cursor: pointer;
}

.disabled-market{
  display: flex;
  align-items: center;
  gap:1rem;
  padding:0.15rem 0.85rem;
  background-color: #929292;
  color:#fff;
  font-size: small;
  font-weight: 600;
  cursor: pointer;

}

@media (max-width: 768px) {
  .substack {
    display: none;
  }
}

@media (max-width: 425px) {
  #dropdown-2 button,
  #dropdown-2-1 button {
    margin-left: 0;
  }
  .all-filters {
    flex-direction: column;
    align-items: flex-start;
  }
  .filters-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: inherit;
  }
  .active-btn {
    padding: 0.3rem;
  }
  .disable-btn {
    padding: 0.3rem;
  }
}
</style>
