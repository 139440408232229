import { BLIND_GALLERY_API } from '@/constants'

export class Referral {

  static async claimPendingBadges(wallet, edition) {

    const url = `https://${BLIND_GALLERY_API}/v1/mint`;

    const options = {
      method: 'POST',
      headers: {
        "Accept": '*/*',
        'Content-Type': 'application/json'
      },
      body: `{"wallet":"${wallet}","edition_name":"${edition}"}`
    };

    const data = await fetch(url, options)
    const badges = await data.json()

    return badges
  }

  // static async registerUser(email)

  static async getReferralCounter({ email, link }) {

    let url = `https://${BLIND_GALLERY_API}/v1/referral/getCounter`;
    if (email) url += `?email=${email}`
    if (link) url += `?link=${link}`

    const options = {
      method: 'GET',
      headers: {
        "Accept": '*/*',
        'Content-Type': 'application/json'
      },
    };

    const data = await fetch(url, options)
    const counter = await data.json()

    return counter
  }

  static async getUserInfo(email) {

    const url = `https://${BLIND_GALLERY_API}/v1/referral/userInfo?email=${email}`;

    const options = {
      method: 'GET',
      headers: {
        "Accept": '*/*',
        'Content-Type': 'application/json'
      },
    };

    const data = await fetch(url, options)
    const userInfo = await data.json()

    return userInfo
  }

  static async createUser(email) {

    const url = `https://${BLIND_GALLERY_API}/v1/referral/createUser`;

    const options = {
      method: 'POST',
      headers: {
        "Accept": '*/*',
        'Content-Type': 'application/json'
      },
      body: `{"email":"${email}"}`
    };

    const data = await fetch(url, options)
    const userInfo = await data.json()

    return userInfo
  }

  static async addReferral({ email, link }) {

    const url = `https://${BLIND_GALLERY_API}/v1/referral/addReferral`;

    const options = {
      method: 'POST',
      headers: {
        "Accept": '*/*',
        'Content-Type': 'application/json'
      },
      body: `{"email":"${email}","link":"${link}"}`
    };

    const data = await fetch(url, options)
    const userInfo = await data.json()

    return userInfo
  }

  static async isReferred(email) {

    const url = `https://${BLIND_GALLERY_API}/v1/referral/isReferral?email=${email}`;

    const options = {
      method: 'GET',
      headers: {
        "Accept": '*/*',
        'Content-Type': 'application/json'
      },
    };

    const data = await fetch(url, options)
    const userInfo = await data.json()

    return userInfo
  }


}
