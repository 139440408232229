<template>
  <div>
    <b-row>
      <div class="referral-main-container">
        <img
          width="125"
          src="https://pbs.twimg.com/profile_images/1562353277647339521/UAZlyXN2_400x400.jpg">
        <h3><b>Kaloh's Newsletter</b></h3>
        <h5 style="text-align: center">
          Hi friend, get your unique referral link and start <br>
          referring your friends to
          <span style="color: #ff184d">earn benefits.</span>
        </h5>

        <div>
          <b-input-group>
            <b-form-input
              v-model="email"
              type="email"
              placeholder="Type your email"
              required
              :state="state"
              :invalid-feedback="'Please enter a valid email address'" />
            <b-input-group-append>
              <button
                class="subscribe-btn"
                @click="getReferralLink">
                GET LINK
              </button>
            </b-input-group-append>
          </b-input-group>
          <p style="font-size: small; color: #ffffffb6; margin-top: 1rem">
            Make sure to use the same email used in my newsletter
          </p>
        </div>
        <hr width="100%">
        <PxProgressBar :referrals="0" />
      </div>
    </b-row>
  </div>
</template>
<script>
import PxProgressBar from "@/components/PxProgressBar.vue";

import { Referral } from "@/API/referral.js"

export default {
  name: "ViewReferral",

  components: { PxProgressBar },

  data() {
    return {
      email: "",
      referralStep1: true,
    }
  },

  computed: {
    state() {
      if (this.email.length === 0) return null;

      return this.validEmail();
    },
  },

  methods: {
    validEmail() {
      const re = /\S+@\S+\.\S+/;
      return re.test(this.email);
    },

    async getReferralLink() {
      const response = await Referral.getUserInfo(this.email);

      if (!response.error) {
        this.referralStep1 = false;
        this.$router.push({name :'referral-link', params: { link: response.link }});
        return
      }

      const responseCreate = await Referral.createUser(this.email);

      if (!responseCreate.error) {
        this.referralStep1 = false;
        this.$router.push({name :'referral-link', params: { link: responseCreate.link }});
        return
      }


    },
  },
};
</script>
<style>
.referral-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  gap: 1rem;
}

#logo-kaloh {
  width: 400px;
}

@media (max-width: 768px) {
  .progress-width {
    position: relative;
    width: 100%;
  }
  .referral-main-container {
    margin: 4rem 0rem;
    height: inherit;
  }
}
@media (max-width: 425px) {
  #logo-kaloh {
    width: 250px;
  }
  .progress-container {
    top: -50%;
  }
  .progress-circle {
    width: 40px;
    height: 40px;
    background-color: #ffff;
    border-radius: 50%;

    color: #090b24;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #ff184d;
  }
}
</style>
