var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.items != 0)?_c('b-table',{staticClass:"general-table",attrs:{"sticky-header":"","busy":_vm.busy,"striped":"","responsive":"","hover":"","sort-by":"Currently_holding","sort-desc":"true","label-sort-asc":"","label-sort-desc":"","no-sort-reset":"","items":_vm.items,"fields":[
      {
        key: 'Collector',
        label: 'Collector',
        class: 'hidden-overflow',
        sortable: true,
      },
      { key: 'Currently_holding', sortable: true },
    ]},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center spinner-loading"},[_c('b-spinner')],1)]},proxy:true},{key:"cell(Collector)",fn:function(row){return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'collector',
          params: { collector: row.item.collectorPk },
        },"replace":false}},[_c('a',{staticClass:"wallet-name",attrs:{"title":row.item.Collector,"target":"_blank"}},[_vm._v(" "+_vm._s(row.item.Collector)+" ")])])]}}],null,false,4113293184)}):_vm._e(),(!_vm.loading && _vm.items < 1)?_c('NoDataIndividual'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }