import hookcord from 'hookcord'

import { DISCORD_WEBHOOK } from '@/constants'
const Hook = new hookcord.Hook()

/**
 * @name sendMessage
 * @description Send message to a discord channel
 *
 * @param {String} content
 * @param {String} title
 * @param {Object} fields
 * @param {String} key in DISCORD_WEBHOOK constant
 * @example
 * sendMessage('content', 'title', [{ name: 'field1', value: 'value1' }], 'type')
 */
const sendMessage = (content, title, fields, type) => {
  const discordInfo = DISCORD_WEBHOOK[type].url.split('/')
  Hook.login(discordInfo[5], discordInfo[6])
  Hook.setPayload({
    content,
    embeds: [{
      title,
      color: DISCORD_WEBHOOK[type].color,
      fields
    }]
  })

  Hook.fire()
    .then(_response_object => {})
    .catch(error => {
      throw error
    })
}

export default sendMessage
