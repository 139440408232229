<template>
  <b-row>
    <b-col>
      <div class="no-data-individual">
        <span
          class="material-symbols-sharp"
          style="font-size: 4rem">
          running_with_errors
        </span>
        <h6 style="text-align: center">
          <span>There is no data to show...</span>
        </h6>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "NoDataIndividual",
};
</script>
<style>
.no-data-individual {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  min-height: 400px;
  border: 1px solid #46475a;
}
</style>
