import { request, gql } from 'graphql-request'
import { FXHASH_API, STATS_API } from '@/constants.js'
import { ParseDataToView, groupBy } from '@/API/Utils.js'

import { getAccountInfo } from '@/API/teztokProfiles';

/**
 * This async function retrieves data of an artist's generative token actions from a GraphQL API
 * and parses the data using a given parser's actionsTableParser method.
 *
 * @function actionsGetter
 * @async
 * @param {string} artistId - The ID of the artist whose generative token data is to be retrieved.
 * @param {number} [skip=0] - The number of items to skip at the beginning of the list of sales.
 * @param {number} [take=50] - The maximum number of items to retrieve.
 * @param {Object} parser - An object containing an actionsTableParser method to parse the data retrieved.
 * @returns {Promise} Returns a Promise that resolves to the parsed data containing the actions of the artist's generative tokens
 * in a table format.
 */
async function actionsGetter(artistId, parser) {
  let allData = [];
  for (let skip = 0; skip < 1000; skip += 50) {
    const tokenQuery = gql`
        query artistData {
          user(id: "${artistId}") {
            sales (skip: ${skip}, take: 50) {
              createdAt
              numericValue
              issuer {
                id
                name
              }
              target {
                id
                name
              }
              objkt {
                id
                name
                displayUri
              }
              type
            }
          }
        }
      `;
    const sales = await request(FXHASH_API, tokenQuery);
    const actions = sales ? sales.user ? sales.user.sales: []: [];

    if (actions.length === 0) {
      break;
    }
    allData = allData.concat(actions);
  }
  const actionsTable = parser.actionsTableParser(allData);
  // filter when seller is equal to artistId
  const filteredActions = actionsTable.filter(action => action.sellerPk == artistId);
  return filteredActions;
}

/** This async function retrieves and aggregates data of an artist's generative token actions and sales from an Ecosystem API. It then parses the data using a given parser's methods and formats it for display in a view.
  @function getCollectorView
  @async
  @param {string} artist - The wallet address of the artist whose data is to be retrieved and aggregated.
  @param {string} marketplace - The marketplace to which the generative tokens of the artist belong.
  @param {string} [timeFrom= "2021-01-01T15:00:00.000Z"] - The start time to aggregate data from.
  @returns {Object} Returns an object containing aggregated and formatted data of an artist's generative tokens and sales.
 */
export async function getCollectorView(artist, chain='tezos') {
  if(chain === 'ethereum') return [];
  const artistInfo = await getAccountInfo(artist);
  const publicKey = artistInfo.account;
  // set config
  const artistId = String(publicKey);
  const parser = new ParseDataToView();

  return actionsGetter(artistId, parser)
}


// code to get ether stats from api

export async function getCollectorEtherStats(wallets = '',otherWallets = []) {
  // get data fron api
  const res = await fetch(`${STATS_API}/ethereum/collector/stats?wallet=${wallets}`);
  const data = await res.json();
  // get data from other wallets and merge
  const otherWalletsData = await Promise.all(otherWallets.map(async wallet => {
    const res = await fetch(`${STATS_API}/ethereum/collector/stats?wallet=${wallet}`);
    const data = await res.json();
    return data;
  }));
  // merge data
  const mergedData = otherWalletsData.reduce((acc, data) => {
    acc.totalEther += data.totalEther;
    acc.totalEtherSpent += data.totalEtherSpent;
    acc.totalEtherReceived += data.totalEtherReceived;
    return acc;
  }, data);
  // get stats for artist
  const artistStats = groupBy(mergedData.sales,"artistAddress")
  // reduce each artist stats
  const artistStatsReduced = [];
  for (const [key, value] of Object.entries(artistStats)) {
    const artistStatsAux = value.reduce((acc, data) => {
      acc.amount_usd += data.amount_usd;
      acc.totalPieces += 1;
      return acc;
    }, {amount_usd:0,totalPieces:0, artistAddress:key, artistName: value[0].artistName});
    artistStatsReduced.push(artistStatsAux);
  }
  // get stats for collection
  const collectionStats = groupBy(mergedData.sales,"collectionName")
  // reduce each collection stats
  const collectionStatsReduced = [];
  for (const [key, value] of Object.entries(collectionStats)) {
    const collectionStatsAux = value.reduce((acc, data) => {
      acc.amount_usd += data.amount_usd;
      acc.totalPieces += 1;
      return acc;
    }, {amount_usd:0,totalPieces:0, collectionName:key, collectionSlug: value[0].collectionSlug});
    collectionStatsReduced.push(collectionStatsAux);
  }
  // stats for all
  const allStats = groupBy(mergedData.statsCollection,"slug_id");
  const allStatsReduced = [];
  for (const [key, value] of Object.entries(allStats)) {
    const allStatsAux = value.reduce((acc, data) => {
      // sum secVolumeUSD to get total amount
      acc.totalVolume += data.secVolumeUSD;
      // get the highest floor
      if(acc.highestFloor < data.floorUSD) {
        acc.highestFloor = data.floor;
      }
      return acc;
    }, {totalVolume:0, highestFloor:0, slug_id:key, name: value[0].name});
    // get the median floor
    const sorted = value.sort((a,b) => a.floor - b.floor);
    const middle = Math.floor((sorted.length - 1) / 2);
    if(sorted.length % 2) {
      allStatsAux.medianFloor = sorted[middle].floor;
    } else {
      allStatsAux.medianFloor = (sorted[middle].floor + sorted[middle + 1].floor) / 2.0;
    }
    // get floor from the more recent sale using date_from as date
    const sortedByDate = value.sort((a,b) => new Date(b.date_from) - new Date(a.date_from));
    allStatsAux.floor = sortedByDate[0].floorUSD;
    allStatsReduced.push(allStatsAux);
  }
  // sum all stats
  const totalStats = allStatsReduced.reduce((acc, data) => {
    acc.totalVolume += data.totalVolume;
    acc.high += data.highestFloor;
    acc.median += data.medianFloor;
    acc.floor += data.floor;
    return acc;
  }, {totalVolume:0, high:0, median:0, floor:0});

  return {...mergedData, 
    artistStats: artistStatsReduced, 
    collectionStats: collectionStatsReduced,
    allStats: allStatsReduced,
    ...totalStats
  };
};
