import { OpKind } from '@taquito/taquito';

import { Tezos, getContract } from '@/services/tezos'
import { MARKETPLACES, DEFAULT_CONFIRMATION_BLOCKS } from '@/constants'

class OBJKT {
  constructor({ }) {
    this.contractAddress = MARKETPLACES.objkt.address
    this.marketplaceV4 = MARKETPLACES.objkt.marketplaceV4
  }

  async mint(_referrer, _reserve_input, issuer_id, c = DEFAULT_CONFIRMATION_BLOCKS) {
    const contract = await getContract(this.contractAddress)
    const batch_operation = await Tezos.wallet
      .batch([
        {
          kind: OpKind.TRANSACTION,
          ...contract.methods
            .mint(issuer_id)
            .toTransferParams(),
        }
      ])
      .send()

    const confirmation = await batch_operation.confirmation(c);
    return confirmation

  }


  async fulfillAskV1(askId, amount, c = DEFAULT_CONFIRMATION_BLOCKS) {
    const contract = await getContract(this.marketplaceV1)
    const batch_operation = await Tezos.wallet
      .batch([
        {
          kind: OpKind.TRANSACTION,
          ...contract.methods
            .fulfill_ask(askId)
            .toTransferParams(),
          amount,
        }
      ])
      .send()

    return batch_operation.confirmation(c);
  }

  async fulfillAsk(askId, amount, _proxy = "None", c = DEFAULT_CONFIRMATION_BLOCKS) {
    const contract = await getContract(this.marketplaceV4)
    const batch_operation = await Tezos.wallet
      .batch([
        {
          kind: OpKind.TRANSACTION,
          ...contract.methods
            .fulfill_ask(askId)
            .toTransferParams(),
          amount,
        }
      ])
      .send()

    return batch_operation.confirmation(c);
  }
}

export default OBJKT
