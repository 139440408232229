import { gql, request } from 'graphql-request';
import { OBJKT_API, MARKETPLACES } from '@/constants';

import { getAccountInfo } from '@/API/teztokProfiles';

/**
  Queries for active listings of created tokens of an artist
  @async
  @function objktListings
  @param {string} artist - address of the artist
  @returns {Array.} - array of objects representing active listings
  @throws {Error} - throws an error if the request to OBJKT_API fails
*/
const objktListings = async (artist) => {
  const objktQuery = gql`
    query Query_root($address: String!, $where: token_creator_bool_exp, $limit: Int) {
      holder_by_pk(address: $address) {
        address
        alias
        created_tokens(where: $where, limit: $limit) {
          token {
            listings_active {
              amount_left
              price
              price_xtz
              bigmap_key
              marketplace {
                contract
                name
              }
            }
            name
            thumbnail_uri
          }
        }
      }
    }
  `;

  const response = await request(OBJKT_API, objktQuery, {
    "address": artist,
    "where": {
      "token": {
        "listings_active": {
          "fa_contract": {
            "_eq": MARKETPLACES.fxhash.gentkV2
          }
        }
      }
    },
    "limit": 10000,
  });

  const listings = response.holder_by_pk.created_tokens
    .flatMap((token) =>
      token.token.listings_active.map((listing) => ({ // map the listings to the format we need
        cid: `https://gateway.fxhash.xyz/ipfs/${token.token.thumbnail_uri}`.replace(`ipfs://`, ''),
        collection: token.token.name.split(` #${token.token.name.split('#')[1]}`)[0],
        iteration: token.token.name.match(/#(\d+)/) ? token.token.name.match(/#(\d+)/)[1] : 1,
        price: listing.price / 1000000,
        marketplace: {
          address: listing.marketplace.contract,
          name: listing.marketplace.name.includes('fxhash') ? MARKETPLACES.fxhash.name : MARKETPLACES.objkt.name,
          id: listing.bigmap_key,
          price: listing.price / 1000000,
        },
      }))
    );
  return listings;
}

/**
  Async function that retrieves objkt listings for a specific artist
  @async
  @function
  @param {string} artist - The artist name to retrieve objkt listings for
  @returns {Promise<Object[]>} - Promise object represents an array of objkt listings sorted by price in ascending order
*/
const getOffersByArtist = async (artist,chain='tezos') => {

  if(String(chain).includes('ethereum')){
    return [];
  } else {
    const accountInfo = await getAccountInfo(artist);
    const objktListingsData = await objktListings(accountInfo.account);
    const listings = objktListingsData.sort((a, b) => a.price - b.price);
    return listings;
  }
};

export default getOffersByArtist;
