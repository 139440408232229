<template>
  <div class="switch">
    <input
      :id="switchId"
      v-model="switchValue"
      type="checkbox"
      @change="handleChange">
    <label :for="switchId" />
  </div>
</template>

<script>
export default {
  name: "SwitchButton",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "switch",
    },
  },
  data() {
    return {
      switchValue: this.value,
    };
  },
  computed: {
    switchId() {
      return `${this.id}-${Math.floor(Math.random() * 1000000)}`;
    },
  },
  watch: {
    value(newValue) {
      this.switchValue = newValue;
    },
  },
  methods: {
    handleChange(event) {
      this.$emit("switch-usd", event.target.checked);
    },
  },
  emits: ["switch-usd"],
};
</script>

<style scoped>
.switch {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 20px;
  margin-left: 0.3rem;

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #929292;
  border-radius: 20px;
  transition: background-color 0.3s;
}

.switch label::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  transition: transform 0.3s;
  font-size: 10px;
}



.switch input:checked + label {
  background-color: #ff184d;
  
}

.switch input:checked + label::before {
  transform: translateX(25px);
  background-color: #ffffff;
 
}
</style>
