<template>
  <div>
    <b-row>
      <div class="collection-info">
        <div class="profile-img-container">
          <b-avatar
            target="_blank"
            size="10rem"
            square
            :src="`https://services.tzkt.io/v1/avatars/${collectorData.account}`" />
          <!-- <img
            :src="collectorData.logo"> -->
          <div class="profile-label">
            <p>COLLECTOR PROFILE</p>
          </div>
        </div>
        <div>
          <div
            class="d-flex"
            style="gap: 0.7rem">
            <h5 style="font-weight: bold">
              {{ collectorData.alias }}
            </h5>
          

            <span
              v-if="!$route.path.includes(tezosAddress)"
              style="cursor: pointer;"
              @click="
                isWalletConnected === false
                  ? doConnectWallet()
                  : toggleBookmark()
              ">
              <Icon
                width="26px"
                :color="bookmark === true ? 'red' : ''"
                :icon="bookmark === false ? 'material-symbols:bookmark-add-outline' : 'material-symbols:bookmark'" />
            </span>
          </div>
          <!--DROPDOWN-->
          <UserLinks :collector-data="collectorData" />
          <!--MESSAGE MULTIPLE WALLETS-->
          <div
            v-if="$route.query.otherWallets"
            v-b-tooltip.hover
            title="This view shows data from more than one wallet"
            style="
              display: flex;
              align-items: center;
              margin-top: 1rem;
              gap: 0.5rem;
            ">
            <p
              style="
                margin: 0;
                font-size: small;
                color: #fed402;
                font-weight: bold;
              ">
              Multiple wallets connected
            </p>
           

            <Icon
              width="18px"
              color="#fed402"
              icon="material-symbols:error-outline-rounded" />
          </div>
        </div>

        <div class="vertical-line" />
        <!--STATS-->
        <div
          class="info-right"
          style="align-items: start">
          <div>
            <div style="margin-bottom: 1rem">
              <h5 style="font-weight: bold; margin: 0">
                {{ collectorMetadata.totalVolume | tezos }}
              </h5>
              <p class="conceptInfo">
                Total collected volume
              </p>
            </div>
            <div>
              <h5 style="font-weight: bold; margin: 0">
                {{ collectorMetadata.totalArtist }}
              </h5>
              <p class="conceptInfo">
                Collected artists
              </p>
            </div>
          </div>
          <div>
            <div style="margin-bottom: 1rem">
              <h5 style="font-weight: bold; margin: 0">
                {{ collectorMetadata.totalSold | tezos }}
              </h5>
              <p class="conceptInfo">
                Total sold volume
              </p>
            </div>

            <div>
              <h5 style="font-weight: bold; margin: 0">
                {{ collectorMetadata.totalCollection }}
              </h5>
              <p class="conceptInfo">
                Collected collections
              </p>
            </div>
          </div>
          <div>
            <div style="margin-bottom: 1rem">
              <h5 style="font-weight: bold; margin: 0">
                <span>{{ collectorMetadata.floor | tezos }}</span>
                <span> | </span>
                <span>{{ collectorMetadata.median | tezos }}</span>
                <span> | </span>
                <span>{{ collectorMetadata.high | tezos }}</span>
              </h5>
              <p class="conceptInfo">
                Collection value (floor | median | high)
              </p>
            </div>
            <div>
              <h5 style="font-weight: bold; margin: 0">
                {{ collectorMetadata.totalPieces }}
              </h5>
              <p class="conceptInfo">
                Collected pieces
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <NoData
      v-if="
        collectorMetadata.totalArtist == 0 &&
          collectorMetadata.totalCollection == 0 &&
          collectorMetadata.totalSold == 0 &&
          loading === false
      " />
    <b-row
      v-if="
        collectorMetadata.totalArtist != 0 ||
          collectorMetadata.totalCollection != 0 ||
          collectorMetadata.totalSold != 0
      ">
      <b-col
        cols="12"
        lg="6">
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            By artist
          </h5>
          <NoDataIndividual
            v-if="!loading && holdingInfo.byArtist.length < 1" />
          <div
            v-if="!loading && holdingInfo.byArtist.length != 0"
            class="dona-container">
            <DoughnutChart
              height="203"
              :chart-data="artistChartData"
              :options="artistChartOptions" />
          </div>
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            By collection
          </h5>
          <NoDataIndividual
            v-if="!loading && holdingInfo.byCollection.length < 1" />
          <div
            v-if="!loading && holdingInfo.byCollection.length != 0"
            class="dona-container">
            <DoughnutChart
              height="203"
              :chart-data="collectionsChartData"
              :options="collectionsChartOptions" />
          </div>
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Holdings by artist
          </h5>
          <NoDataIndividual
            v-if="!loading && holdingInfo.byArtist.length < 1" />
          <b-table
            v-if="!loading && holdingInfo.byArtist.length != 0"
            class="general-table"
            sticky-header
            sort-by="Currently_holding"
            sort-desc="true"
            label-sort-asc=""
            label-sort-desc=""
            no-sort-reset
            striped
            hover
            :busy="loading"
            :fields="[
              { key: 'Artist', sortable: false },
              { key: 'Currently_holding', sortable: true },
            ]"
            :items="holdingInfo.byArtist">
            <template #table-busy>
              <div class="text-center spinner-loading">
                <b-spinner />
              </div>
            </template>
            <template #cell(Artist)="row">
              <div v-if="row.item.ArtistId.includes(',')">
                <span
                  v-for="(artist, index) in row.item.ArtistId.split(', ')"
                  :key="index">
                  <router-link
                    :to="{ name: 'artist', params: { artist: artist } }"
                    :replace="false"
                    style="text-decoration: none">
                    <a
                      :title="artist"
                      class="collection-name"
                      target="_blank">
                      {{ artist }}
                    </a>
                  </router-link>
                  <span v-if="index < row.item.ArtistId.split(', ').length - 1">
                    ,
                  </span>
                </span>
              </div>
              <div v-else>
                <router-link
                  :to="{
                    name: 'artist',
                    params: { artist: row.item.ArtistId },
                  }"
                  :replace="false"
                  style="text-decoration: none">
                  <a
                    :title="row.item.Artist"
                    class="collection-name"
                    target="_blank">
                    {{ row.item.Artist }}
                  </a>
                </router-link>
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="6">
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Available on the market
          </h5>
          <div>
            <OffersTable :offer-data="offerData" />
          </div>
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Latest transactions
          </h5>
          <TransactionsTable
            :transactions="collectorTransactions"
            :loading="loading" />
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Holdings by collection
          </h5>
          <NoDataIndividual
            v-if="!loading && holdingInfo.byCollection.length < 1" />
          <b-table
            v-if="!loading && holdingInfo.byCollection.length != 0"
            class="general-table"
            sticky-header
            sort-by="Currently_holding"
            sort-desc="true"
            label-sort-asc=""
            label-sort-desc=""
            no-sort-reset
            striped
            hover
            :busy="loading"
            :fields="[
              { key: 'Collection', sortable: false },
              { key: 'Currently_holding', sortable: true },
            ]"
            :items="holdingInfo.byCollection">
            <template #table-busy>
              <div class="text-center spinner-loading">
                <b-spinner />
              </div>
            </template>
            <template #cell(Collection)="row">
              <router-link
                :to="{
                  name: 'collection',
                  query: { id: row.item.CollectionId },
                }"
                :replace="false"
                style="text-decoration: none">
                <a
                  :title="row.item.Collection"
                  class="collection-name"
                  target="_blank">
                  {{ row.item.Collection }}
                </a>
              </router-link>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DoughnutChart from "@/components/Chars/DoughnutChart";
import OffersTable from "@/components/OffersTable.vue";

import { getAccountInfo } from "@/API/teztokProfiles";
import getHoldingsByUser from "@/API/getHoldingsByUser";
import transactionsByUser from "@/API/transactionsByUser";
import getOffersByCollector from "@/API/getOffersByCollector";
import UserLinks from "@/components/Atomics/UserLinks.vue";
import {getCollectorView,getCollectorEtherStats} from "@/API/collectorView";
import NoData from "@/components/NoData.vue";
import NoDataIndividual from "@/components/NoDataIndividual.vue";
import TransactionsTable from "@/components/TransactionsTable.vue";
import { SERVICE_API } from "@/constants";
import { mapGetters } from "vuex";

import { Icon } from '@iconify/vue2'

export default {
  name: "ViewCollector",
  components: {
    DoughnutChart,
    NoDataIndividual,
    OffersTable,
    UserLinks,
    NoData,
    TransactionsTable,
    Icon
  },

  data() {
    return {
      bookmark: false,
      screenWidth: window.innerWidth,
      loading: true,
      offerData: {
        offers: [],
        fields: [],
        isBusy: true,
      },
      collectorData: {
        account: "",
        alias: "",
        contract: "",
        description: "",
        discord: "",
        domain_name: "",
        ethereum: "",
        github: "",
        logo: "",
        twitter: "",
        website: "",
      },

      collectorMetadata: {
        totalArtist: 0,
        totalCollection: 0,
        totalVolume: 0,
        totalSold: 0,
        totalPieces: 0,
        floor: 0,
        median: 0,
        high: 0,
      },

      artistChartData: undefined,
      artistChartOptions: undefined,
      collectionsChartData: undefined,
      collectionsChartOptions: undefined,

      holdingInfo: {
        byCollection: [],
        byArtist: [],
      },
      collectorTransactions: [],
      actions: undefined,
    };
  },
  computed: {
    ...mapGetters("tezosWallet", [
      "tezosAddress",
      "isWalletConnected",
      "isAllowed",
    ]),
  },
  created() {
    if (this.isWalletConnected) {
      this.isBookmarked();
    } else {
      this.$store.watch(
        (state) => state.tezosWallet.isWalletConnected,
        (value) => {
          if (value) {
            this.isBookmarked();
          }
        }
      );
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.dataForCollerctor();

  },

  methods: {
    dataForCollerctor(){
      this.dataFromEther().then(() => {
        this.dataFromTezos();
      });
    },
    dataFromEther(){
      return new Promise((resolve, reject) => {
        getCollectorEtherStats(this.$route.params.collector)
          .then((res) => {
            this.collectorTransactions = res.sales.map(x =>{
              return {
                "Type": "Sold",
                "Collection": x.collection,
                "Iteration": x.tx_hash,
                "Price": x.amount_usd,
                "DisplayUri": x.imgURL,
                "CollectionId": x.slug_id,
                "createdAt": x.block_time
              }
            });
            if(this.$route.chain == "ethereum"){
              this.collectorData.ethereum.push(this.$route.params.collector);
            }
            this.actions = res.sales.map(x =>{
              return {
                "date": x.block_time,
                "buyer": x.buyer,
                "seller": x.seller,
                "price": x.amount_usd,
                "buyerPk": x.buyer,
                "sellerPk": x.seller,
                "objktId": x.slug_id,
                "objktName": x.collection,
                "displayUri": x.imgURL
              }
            });
            // parse to add in holding data
            // const dataParsed = 
            this.holdingInfo = {
              accountInfo: this.collectorData,
              byArtist: res.artistStats.map(x => {
                return {
                  "Artist": x.artistName,
                  "ArtistId": x.artistAddress,
                  "Currently_holding": x.totalPieces
                }
              }),
              byCollection: res.collectionStats.map(x => {
                return {
                  "Collection": x.collectionName,
                  "CollectionId": x.collectionSlug,
                  "Currently_holding": x.totalPieces
                }
              }),
              statsCollector: {
                floor: res.floor,
                median: res.median,
                high: res.high
              },
              totalVolume: res.totalVolume
            }
            resolve(true);
          }).catch((err) => {
            console.error("err",err);
            reject(false);
          });
      });
    },
    dataFromTezos() {
      transactionsByUser(this.$route.params.collector,this.$route.query.chain).then((res) => {
        // this.collectorTransactions = res;
        this.collectorTransactions = [...this.collectorTransactions, ...res];
        // internal function to get values
        const getOtherTransactions = (wallets) => {
          wallets.forEach((wallet) => {
            transactionsByUser(wallet).then((res) => {
              this.collectorTransactions = this.collectorTransactions.concat(res);
            });
          });
        };
        this.getOtherWallets(
          this.$route.query.otherWallets,
          getOtherTransactions
        );
        //sort this.collectionTransactions by created_at
        this.collectorTransactions.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
      });
      // checked
      getAccountInfo(this.$route.params.collector,this.$route.query.chain).then((res) => {
        this.collectorData = {
          ...this.collectorData,
          ...res,
          ...this.collectorData.ethereum
        };
      });
      // checked
      getOffersByCollector(this.$route.params.collector,this.$route.query.chain).then((res) => {
        this.offerData.fields = [
          { key: "cid", sortable: false },
          { key: "collection", sortable: true },
          { key: "iteration", sortable: true },
          { key: "price", sortable: true },
          { key: "marketplace", sortable: false },
        ];
        this.offerData.offers = res;
        // internal function to get values
        const getOtherOffers = (wallets) => {
          wallets.forEach((wallet) => {
            getOffersByCollector(wallet).then((res) => {
              this.offerData.offers = this.offerData.offers.concat(res);
            });
          });
        };
        this.getOtherWallets(this.$route.query.otherWallets, getOtherOffers);
        this.offerData.isBusy = false;
      });
      // checked
      getCollectorView(this.$route.params.collector,this.$route.query.chain).then((resTemp) => {
        this.actions = [...this.actions, ...resTemp];
        const res = this.actions;
        // sum all prices
        this.collectorMetadata.totalSold = res.reduce(
          (acc, item) => acc + item.price,
          0
        );
        // internal function to get values
        const getOtherSales = (wallets) => {
          wallets.forEach((wallet) => {
            getCollectorView(wallet).then((res) => {
              this.actions = this.actions.concat(res);
              // sum all prices
              this.collectorMetadata.totalSold = this.actions.reduce(
                (acc, item) => acc + item.price,
                this.collectorMetadata.totalSold
              );
            });
          });
        };
        this.getOtherWallets(this.$route.query.otherWallets, getOtherSales);
      });
      getHoldingsByUser(
        this.$route.params.collector,
        this.parseOtherWallets(this.$route.query.otherWallets),
        this.$route.query.chain
      ).then((res) => {
        this.holdingInfo = res;
        this.loading = false;
        const limit = 10;

        // Metadata del collector
        // total artist collected
        this.collectorMetadata.totalArtist = res.byArtist.length;
        this.collectorMetadata.totalCollection = res.byCollection.length;
        this.collectorMetadata.totalPieces = res.byCollection.reduce(
          (acc, item) => acc + item.Currently_holding,
          0
        );
        // total minted
        this.collectorMetadata.totalVolume = res.totalVolume;
        // stats
        this.collectorMetadata.floor = res.statsCollector.floor;
        this.collectorMetadata.median = res.statsCollector.median;
        this.collectorMetadata.high = res.statsCollector.high;
        const artistData = [];
        let otherData = 0;

        res.byArtist.sort((a, b) => b.Currently_holding - a.Currently_holding);

        res.byArtist.slice(0, limit).forEach((item) => {
          artistData.push(item.Currently_holding);
        });

        if (res.byArtist.length > limit) {
          res.byArtist.splice(limit).forEach((item) => {
            otherData += item.Currently_holding;
          });
          artistData.push(otherData);
        }

        const getColorsDonutChart = (dataToPlotLength, labelsToPlot) => {
          // select colors, if data length is more than 10, add other color
          const colors = [
            "rgb(255, 24, 67)",
            "rgb(255, 200,19)",
            "rgb(24, 28, 94)",
            "rgb(55, 67, 222)",
            "rgb(0, 128, 0)",
            "rgb(255, 165, 0)",
            "rgb(255, 0, 0)",
            "rgb(128, 0, 128)",
            "rgb(0, 0, 255)",
            "rgb(255, 192, 203)",
          ];
          const backgroundColor =
            dataToPlotLength > limit
              ? [...colors, "rgb(255, 215, 0)"]
              : colors.slice(0, dataToPlotLength);
          const labels =
            dataToPlotLength > limit
              ? [...labelsToPlot.slice(0, limit), "Others"]
              : labelsToPlot;
          return [backgroundColor, labels];
        };
        const [backgroundColorArtist, labelsArtist] = getColorsDonutChart(
          artistData.length,
          res.byArtist.slice(0, artistData.length).map((item) => item.Artist)
        );

        this.artistChartData = {
          labels: labelsArtist,
          datasets: [
            {
              label: "Holdings by Collection Dataset",
              data: artistData,
              backgroundColor: backgroundColorArtist,
              hoverOffset: 4,
            },
          ],
        };

        this.artistChartOptions = {
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const value =
                  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                const total = data.datasets[tooltipItem.datasetIndex].data.reduce(
                  (a, b) => a + b,
                  0
                );
                const percentage = Math.round((value / total) * 100);
                return `${value} (${percentage}%), ${
                  data.labels[tooltipItem.index]
                }`;
              },
            },
          },
          legend: {
            position: "right",
            display: this.screenWidth > 600 ? true : false,
            labels: {
              fontColor: "#fff",
            },
          },
        };

        const collectionData = [];
        otherData = 0;

        res.byCollection.sort(
          (a, b) => b.Currently_holding - a.Currently_holding
        );

        res.byCollection.slice(0, limit).forEach((item) => {
          collectionData.push(item.Currently_holding);
        });

        if (res.byCollection.length > limit) {
          res.byCollection.slice(limit).forEach((item) => {
            otherData += item.Currently_holding;
          });

          collectionData.push(otherData);
        }

        this.collectionsChartOptions = {
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const value =
                  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                const total = data.datasets[tooltipItem.datasetIndex].data.reduce(
                  (a, b) => a + b,
                  0
                );
                const percentage = Math.round((value / total) * 100);
                return `${value} (${percentage}%), ${
                  data.labels[tooltipItem.index]
                }`;
              },
            },
          },
          legend: {
            display: this.screenWidth > 600 ? true : false,
            position: "right",
            labels: {
              fontColor: "#fff",
            },
          },
        };

        const [backgroundColor, labels] = getColorsDonutChart(
          collectionData.length,
          res.byCollection
            .slice(0, collectionData.length)
            .map((item) => item.Collection)
        );

        this.collectionsChartData = {
          labels: labels,
          datasets: [
            {
              label: "Holdings by Collection Dataset",
              data: collectionData,
              backgroundColor: backgroundColor,
              hoverOffset: 4,
            },
          ],
        };
      });
    },
    async doConnectWallet() {
      if (this.isWalletConnected) {
        await this.$store.dispatch("tezosWallet/disconnect");
        return;
      }
      await this.$store.dispatch("tezosWallet/connect");
    },
    isBookmarked() {
      const params = new URLSearchParams({
        login_wallet: this.tezosAddress,
        type: "collector",
        id: this.$route.params.collector,
      });
      fetch(`${SERVICE_API}/is/bookmarks?${params.toString()}`, {
        method: "GET",
      }).then((res) => {
        if (res.status === 200) {
          // if res promise result is true or false set bookmark to true or false
          res.json().then((res) => {
            this.bookmark = res;
          });
        }
      });
    },
    toggleBookmark() {
      if (this.bookmark) {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "DELETE",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            type: "collector",
            id: this.$route.params.collector,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.bookmark = !this.bookmark;
          }
        });
      } else {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "POST",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            chain: "tezos",
            type: "collector",
            id: this.$route.params.collector,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.bookmark = !this.bookmark;
          }
        });
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    parseOtherWallets(otherWallets) {
      let wallets = otherWallets ?? [];
      if (typeof otherWallets === "string") {
        wallets = [otherWallets];
      } else if (typeof otherWallets === "object") {
        wallets = otherWallets;
      }
      return wallets;
    },
    getOtherWallets(otherWallets, getOtherData) {
      // check if otherWallets is a list, if not, create list
      const addedWallets = this.parseOtherWallets(otherWallets);
      getOtherData(addedWallets);
    },
  },

  onMounted() {},
};
</script>
<style>
.conceptInfo {
  opacity: 0.7;
}

#dropdown-1 > .btn {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}

.dropdown-menu {
  border-radius: 0;
}

.dona-container {
  border: 1px solid #46475a;
  padding: 3rem;
}

@media (max-width: 425px) {
  .dona-container {
    padding: 1rem;
  }
}
</style>
