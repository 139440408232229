<template>
  <div>
    <b-form-group
      id="fieldset-1"
      invalid-feedback="Please enter a valid email address"
      :state="state">
      <div style="display: flex">
        <b-form-input
          v-model="email"
          :class="inputClass"
          type="email"
          placeholder="Type your email"
          required
          :state="state"
          trim />

        <button
          v-if="statusIcon === '' || statusIcon === 'error'"
          :class="btnClass"
          @click="subscribeOnClickHandler">
          Subscribe
        </button>
      </div>
    </b-form-group>
    <div
      v-if="$route.path.includes('/referral')"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      ">
      <p
        :style="statusIcon === 'error' ? 'color:#ff184d' : 'color:#46c563'"
        style="font-size: 14px; margin-top: 1rem">
        {{ statusMessage }}
      </p>
      <span
        :style="statusIcon === 'error' ? 'color:#ff184d' : 'color:#46c563'"
        style="font-size: 18px"
        class="material-symbols-sharp">
        {{ statusIcon }}
      </span>
    </div>
    <b-alert
      :variant="state ? 'success' : 'danger'"
      fade
      :show="dismissCountDown"
      class="alert-position"
      @dismiss-count-down="countDownChanged">
      {{
        state
          ? "You will receive a welcome email shortly"
          : "Please enter a valid email"
      }}
    </b-alert>
  </div>
</template>
<script>
import { AD_MESSAGES } from "@/constants";
// import { subscribe } from '@/API/substack'
import { Referral } from "@/API/referral";
import sendMessage from "@/services/discordWebhooks";
export default {
  name: "PxSubstack",

  props: {
    referralLink: {
      type: String,
      default: "",
    },
    social: {
      type: String,
      default: "",
    },
    btnClass:{
      type: String,
      default: "subscribe-btn"
    },
    inputClass:{
      type:String,
      default: "subscribe-input"
    }
  },
  data() {
    return {
      email: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      statusMessage: "",
      statusIcon: "",
    };
  },

  computed: {
    state() {
      if (this.email.length === 0 || this.statusIcon === "error") return null;

      return this.validEmail();
    },
  },

  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    validEmail() {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(this.email);
    },
    async subscribeOnClickHandler() {
      if (!this.referralLink) {
        this.doSubscribe();
      }

      if (this.referralLink) {
        if (this.state === false) {
          return;
        }


        const alreadyReferred = await Referral.isReferred(this.email);
        if (alreadyReferred.ref) {
          this.statusMessage = "This email is already referred";
          this.statusIcon = "error";
          return;
        }

        const newReferral = await Referral.addReferral({
          email: this.email,
          link: this.referralLink,
        });


        if (newReferral.passed) {
          const counter = await Referral.getReferralCounter({
            link: this.referralLink,
          });
          this.statusMessage =
            "Thanks for subscribing! You will receive an email shortly.";
          this.statusIcon = "check_circle";

          if (AD_MESSAGES[this.referralLink]) {
            const referralLink = this.social ? `${this.referralLink} - ${this.social}` : this.referralLink;
            sendMessage(
              `New Subscriber - Landing ${AD_MESSAGES[this.referralLink].number}`,
              "Ad",
              [
                { name: "Email", value: this.email },
                { name: "Referral Link", value: referralLink },
                { name: "Referred by", value: `Ad ${AD_MESSAGES[this.referralLink].number}` },
              ],
              "referralAd"
            );
            setTimeout(() => {
              window.open('https://www.kaloh.xyz/subscribe?coupon=f4a0ce2d')
            }, 2000);
           
            return
          }

          sendMessage(
            "New Subscriber - Referral",
            "Referral",
            [
              { name: "Email", value: this.email },
              { name: "Referral Link", value: this.referralLink },
              { name: "Referred by", value: counter.email },
            ],
            "referralSubscribed"
          );
          this.getBenefits(counter);
        }else{
          this.statusMessage = "This email is already subscribed";
          this.statusIcon = "error";
          return;
        }
      }
    },
    getBenefits({ link, email, counter }) {
      switch (counter) {
        case 1:
          sendMessage(
            "New Subscriber - Benefit",
            "Referral",
            [
              { name: "Email", value: email },
              { name: "Referral Link", value: link },
              { name: "Benefit", value: "Monthly artist to watch issue" },
            ],
            "referralBenefit"
          );
          break;
        case 5:
          sendMessage(
            "New Subscriber - Benefit",
            "Referral",
            [
              { name: "Email", value: email },
              { name: "Referral Link", value: link },
              { name: "Benefit", value: "Discord Access" },
            ],
            "referralBenefit"
          );
          break;

        case 10:
          sendMessage(
            "New Subscriber - Benefit",
            "Referral",
            [
              { name: "Email", value: email },
              { name: "Referral Link", value: link },
              {
                name: "Benefit",
                value: "50% Discount (monthly /yearly subscriptions)",
              },
            ],
            "referralBenefit"
          );
          break;

        case 25:
          sendMessage(
            "New Subscriber - Benefit",
            "Referral",
            [
              { name: "Email", value: email },
              { name: "Referral Link", value: link },
              { name: "Benefit", value: "Kaloh's Mug" },
            ],
            "referralBenefit"
          );
          break;

        case 50:
          sendMessage(
            "New Subscriber - Benefit",
            "Referral",
            [
              { name: "Email", value: email },
              { name: "Referral Link", value: link },
              { name: "Benefit", value: "Lifetime Premium Subscription" },
            ],
            "referralBenefit"
          );
          break;

        default:
          break;
      }
    },
    doSubscribe() {
      if (this.state === true) {
        sendMessage(
          "New Subscriber",
          "Subscription",
          [{ name: "Email", value: this.email }],
          "subscription"
        );
      }
      // subscribe(this.email)
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>
<style>
.subscribe-btn {
  background-color: #ff184d;
  color: #fff;
  font-size: small;
  border: none;
  height: 100%;
  margin: 0;
  padding: 0.5rem ;
}

.subscribe-btn-md{
  background-color: #ff184d;
  color: #fff;
  border-radius: 0px 5px 5px 0px;
  border: none;
  height: 100%;
  margin: 0;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

.subscribe-input{
  padding: 0rem 0.5rem;
  border-radius: 0px;
}

.subscribe-input-md{
  border-radius: 5px 0px 0px 5px;
}

.alert-position {
  position: absolute;
  margin: 1rem;
  bottom: 0;
  right: 0;
  border-radius: 0;
  z-index: 1000;
  position: fixed;
}
</style>
