import getURL from "@/API/getUrl.js";
import { groupBy, percentFilter, tezosFilter } from "./Utils";
import getHoldingsByUser from "@/API/getHoldingsByUser.js";

async function parseBookmarks(data, type = "collection") {
  const agrupador = type === "artist" ? "name" : "slug_id";
  const statsByCollection = groupBy(data, agrupador);
  let urls = [];
  if (type === "artist") {
    const pkArtist = [];
    for (const slugId in statsByCollection) {
      pkArtist.push(statsByCollection[slugId][0].pkauthor);
    }
    urls = await getURL(pkArtist, type);
    urls = groupBy(urls, "name");
  } else {
    function toNumber(value) {
      return Number(value);
    }
    urls = await getURL(
      Object.getOwnPropertyNames(statsByCollection).map(toNumber),
      type
    );
    urls = groupBy(urls, "id");
  }
  for (const slugId in statsByCollection) {
    // order by date
    statsByCollection[slugId].sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
    // el vector esta ordenado, el primero no tiene incremento
    statsByCollection[slugId][0].incrementoAcum = 0;
    for (let index = 1; index < statsByCollection[slugId].length; index++) {
      if (index === statsByCollection[slugId].length - 1) {
        // floor incremental take initial and last
        const aux1 = statsByCollection[slugId][index].floor || 0;
        const aux2 = statsByCollection[slugId][0].floor || 0;
        statsByCollection[slugId][index].incremento = (aux1 / aux2 - 1) * 100;
        // acumulado incremental
        statsByCollection[slugId][index].incrementoAcum =
          (statsByCollection[slugId][0].incrementoAcum || 0) +
          (statsByCollection[slugId][index].incremento || 0);
      }
      // add link to collection
      statsByCollection[slugId][index].linkLogo = urls[slugId]
        ? urls[slugId][0].displayUri
        : "";
    }
  }

  const filteredStats = [];
  for (const slugId in statsByCollection) {
    // agregadores para trend
    const floorTrend = [];
    // el vector esta ordenado, el primero no tiene incremento
    for (let index = 0; index < statsByCollection[slugId].length; index++) {
      // add trend data
      floorTrend.push(statsByCollection[slugId][index].floor);
      if (index === statsByCollection[slugId].length - 1) {
        // stats incremental
        statsByCollection[slugId][index].incrementoAcum = percentFilter(
          statsByCollection[slugId][index].incrementoAcum
        );
        statsByCollection[slugId][index].floor = tezosFilter(
          statsByCollection[slugId][index].floor
        );
        //trend data
        statsByCollection[slugId][index].floorTrend = floorTrend;
        statsByCollection[slugId][index].bookmark='bookmark';
        // save data
        filteredStats.push(statsByCollection[slugId][index]);
      }
    }
  }

  return filteredStats;
}

async function getCollectorStats(data){
  // make a request for each id in data
  const promises = data.map((item) => {
    return getHoldingsByUser(item.id)
  });
  // wait for all the requests to finish
  const results = await Promise.all(promises);
  // console.log("results",results);
  // for each result, get the stats
  const stats = results.map((result) => {
    return {
      name: result.accountInfo.alias ?? result.accountInfo.twitter,
      id: result.accountInfo.account,
      totalArtistsCollected: result.byArtist.length,
      totalCollection: result.byCollection.length,
    totalPieces: result.byCollection.reduce(
      (acc, item) => acc + item.Currently_holding,
      0
    ),
    // total minted
    totalCollectedVolume: result.totalVolume,
    // stats
    floor: result.statsCollector.floor,
    median: result.statsCollector.median,
    high: result.statsCollector.high,
    }
  });  
  // console.log("stats",stats);
  return stats;
}

export { parseBookmarks, getCollectorStats };
