<template>
  <div>
    <figure>
      <img
        height="645"
        width="645"
        :src="data.src"
        :alt="data.title">
      <figcaption>
        <a :href="data.url"> {{ data.title }}</a>
        <span> {{ `by ${data.author}` }}</span>
      </figcaption>
    </figure>
  </div>
</template>
<script>
export default {
  name: "PxPreviewImage",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
}
</script>
