var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.items != 0)?_c('b-table',{staticClass:"general-table",attrs:{"sticky-header":"","busy":_vm.busy,"responsive":"","striped":"","hover":"","label-sort-asc":"","label-sort-desc":"","no-sort-reset":"","items":_vm.items,"fields":[
      { key: 'Time_of_sale', label: 'Time of Sale', sortable: false },
      {
        key: 'Buyer',
        label: 'Buyer',
        sortable: false,
        class: 'hidden-overflow',
      },
      {
        key: 'Seller',
        label: 'Seller',
        sortable: false,
        class: 'hidden-overflow',
      },
      { key: 'Price_in_Tezos', label: `Price in ${_vm.coinLabel}`, sortable: true },
      { key: 'objktName', label: 'Item', sortable: true },
    ]},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center spinner-loading"},[_c('b-spinner')],1)]},proxy:true},{key:"cell(Price_in_Tezos)",fn:function(row){return [_c('td',[_vm._v(" "+_vm._s(_vm.filterCoin(row.item.Price_in_Tezos,'0,000.00'))+" ")])]}},{key:"cell(Buyer)",fn:function(row){return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'collector', params: { collector: row.item.buyerPk } },"replace":false}},[_c('a',{staticClass:"collection-name",attrs:{"title":row.item.Buyer,"target":"_blank"}},[_vm._v(" "+_vm._s(row.item.Buyer)+" ")])])]}},{key:"cell(Seller)",fn:function(row){return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'collector', params: { collector: row.item.sellerPk } },"replace":false}},[_c('a',{staticClass:"collection-name",attrs:{"title":row.item.Seller,"target":"_blank"}},[_vm._v(" "+_vm._s(row.item.Seller)+" ")])])]}},{key:"cell(objktName)",fn:function(row){return [_c('div',{staticStyle:{"cursor":"pointer"},attrs:{"id":`popover-1-${row.item.displayUri}`,"title":row.item.objktName}},[_c('div',[_vm._v(" "+_vm._s(row.item.objktName)+" ")])]),_c('b-popover',{staticClass:"popover",attrs:{"target":`popover-1-${row.item.displayUri}`,"triggers":"hover","placement":"left"}},[_c('img',{attrs:{"src":row.item.displayUri}})])]}}],null,false,2892910563)}):_vm._e(),(!_vm.busy && _vm.items < 1)?_c('NoDataIndividual'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }