import getHighestSales from "@/API/getHighestSales.js";
import {hotCollection} from "@/API/kalohBoardAPI.js";
import { FXHASH } from "@/API/fxhash.js";
// import getArticles from "@/API/articles.js";
// import getEvents from "@/API/events.js";

export class Reports {
  constructor() {
    this.weeklyReport = {
      topSales: [],
      artists: [],
      collections: [],
    }
    this.monthlyReport = {
      topSales: [],
      artists: [],
      collections: [],
    }
  }

  /**
  * Asynchronously retrieves the top sales based on specified criteria.
  *
  * @param {Object} options - The options for retrieving top sales.
  * @param {number} options.amount - The number of top sales to retrieve.
  * @param {number} options.days - The number of days to consider for the top
  * sales.
  * @param {string} [options.sort='price'] - The sorting criteria for the top
  * sales (optional, default: 'price').
  * @param {number} [options.filter=0] - The filter value for the top sales
  * (optional, default: 0).
  * @returns {Promise<Array<Object>>} - A promise that resolves to an array of
  * top sales objects.
  */
  async getTopSales({ amount, days, sort = 'price', filter = 0 }) {
    const topSales = [];
    const sales = await getHighestSales(amount, days, sort, filter);
    sales.forEach(async (sale) => {
      topSales.push(
        {
          artist: sale.artistName ? sale.artistName : sale.artistPk,
          collection: sale.title,
          collector: sale.buyer,
          price: sale.price,
          collectionLink: sale.url
        })
    })

    return topSales;
  }

  /**
   * Asynchronously retrieves the top collections based on specified criteria.
   *
   * @param {Object} options - The options for retrieving top collections.
   * @param {number} options.amount - The number of top collections to retrieve.
   * @param {number} options.days - The number of days to consider for the top
   * collections.
   * @returns {Promise<Array<Object>>} - A promise that resolves to an array of
   * top collections objects.
   */
  async getTopCollections({ amount, days }) {
    const collections = await hotCollection(
      amount,
      days,
      "namecollection"
    )

    return collections;
  }

  /**
   * Asynchronously retrieves the top artists based on specified criteria.
   *
   * @param {Object} options - The options for retrieving top artists.
   * @param {number} options.amount - The number of top artists to retrieve.
   * @param {number} options.days - The number of days to consider for the top
   * artists.
   * @returns {Promise<Array<Object>>} - A promise that resolves to an array of
   * top artists objects.
   */
  async getTopArtists({ amount, days }) {
    const artists = await hotCollection(
      amount,
      days,
      "artist"
    )

    return artists;
  }

  /**
   * Asynchronously retrieves information about a collection based on the
   * specified slug ID.
   *
   * @param {Object} options - The options for retrieving collection information.
   * @param {string} options.slugId - The slug ID of the collection.
   * @returns {Promise<Object>} - A promise that resolves to an object
   * containing collection information.
   */
  async getCollectionInfo(slugId) {
    const res = await FXHASH.getCollectionInfo(slugId)
    return {
      artist: res.author.name,
      collection: item.name,
      TezosVolume: item.volumenAcumTz,
      collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
    }
  }

  /**
   * Asynchronously generates a collection report based on the specified
   * interval.
   *
   * @param {Object} options - The options for generating the collection report.
   * @param {string} options.interval - The interval for the collection report
   * ('weekly' or 'monthly').
   * @returns {void}
   */
  async getCollectionReport({ interval }) {

    const intervals = {
      weekly: {
        days: 7,
        storage: this.weeklyReport
      },
      monthly: {
        days: 30,
        storage: this.monthlyReport
      }
    }

    const days = intervals[interval].days

    const collections = await this.getTopCollections({ amount: 50, days })

    const collectionsByAbsoluteSales = [...collections].slice(0, 5)
    intervals[interval].storage.collections.push(
      {
        label: 'Top 5 Collections Sales',
        data: collectionsByAbsoluteSales
      }
    )

    const collectionsByVolumeSorted = [...collections].sort((a, b) => b.volumenAcumTz - a.volumenAcumTz)
    const collectionsByVolumeSliced = collectionsByVolumeSorted.slice(0, 5)
    const collectionsByVolume = []
    collectionsByVolumeSliced.forEach(async (item) => {
      const res = await this.getCollectionInfo(item.slug_id)
      collectionsByVolume.push({
        artist: res.author.name,
        collection: item.name,
        TezosVolume: item.volumenAcumTz,
        collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
      })
    })

    intervals[interval].storage.collections.push(
      {
        label: 'Top 5 Collections by Volume',
        data: collectionsByVolume
      }
    )

    return 1

    const collectionsByFloorChangeFiltered = [...collections].filter((element) => element.incremento !== Infinity)
    const collectionsByFloorSorted = collectionsByFloorChangeFiltered.sort((a, b) => {
      if (a.incremento == Infinity) {
        return 1;
      }
      return b.incremento - a.incremento;
    })
    const collectionsByFloorSliced = collectionsByFloorSorted.slice(0, 5)
    const collectionsByFloorChange = []
    collectionsByFloorSliced.forEach(async (item) => {
      const res = await this.getCollectionInfo(item.slug_id)
      collectionsByFloorChange.push({
        artist: res.author.name,
        collection: item.name,
        floor: item.floor,
        change: item.incrementoAcum,
        collectionLink: `https://kaloh.space/collection?id=${item.slug_id}`
      })
    })

    intervals[interval].storage.collections.push(
      {
        label: 'Top Movers by Floor',
        data: collectionsByFloorChange
      }
    )
  }

  /**
   * Asynchronously generates an artist report based on the specified interval.
   *
   * @param {Object} options - The options for generating the artist report.
   * @param {string} options.interval - The interval for the artist report
   * ('weekly' or 'monthly').
   * @returns {void}
   */
  async getArtistReport({ interval }) {
    const intervals = {
      weekly: {
        days: 7,
        storage: this.weeklyReport
      },
      monthly: {
        days: 30,
        storage: this.monthlyReport
      }
    }

    const days = intervals[interval].days

    const artists = await this.getTopArtists({ amount: 50, days })

    const artistsByVolumeSliced = [...artists.slice(0, 5)]
    const artistsByVolume = []
    artistsByVolumeSliced.forEach((item) => {
      artistsByVolume.push({
        artist: item.author,
        tezosVolume: item.volumenAcumTz,
        artistLink: `https://kaloh.space/artist/${item.pkauthor}`
      })
    })

    intervals[interval].storage.artists.push(
      {
        label: 'Top 5 Artists by Volume',
        data: artistsByVolume
      }
    )

  }

  /**
   * Asynchronously generates the weekly report.
   *
   * @returns {void}
   */
  async getWeeklyReport() {
    const sales = await this.getTopSales({ amount: 5, days: 7 })
    this.weeklyReport.topSales = sales

    await this.getCollectionReport({ interval: 'weekly' })
    await this.getArtistReport({ interval: 'weekly' })

  }

  /**
   * Asynchronously generates the monthly report.
   *
   * @returns {void}
   */
  async getMonthlyReport() {
    const sales = await this.getTopSales({ amount: 10, days: 30 })
    this.monthlyReport.topSales = sales

    await this.getCollectionReport({ interval: 'monthly' })
    await this.getArtistReport({ interval: 'monthly' })
  }

  /**
   * Asynchronously generates the weekly and monthly reports.
   * @returns {void}
   */
  async generateReports() {
    const _ = await this.getWeeklyReport()
    const __ = await this.getMonthlyReport()
  }
}
