import { request, gql } from 'graphql-request'
import { FXHASH_API } from '@/constants'

function getURLByCollection () {
  const queryMaker = `
    query($slug_id: [Int!])  {
      generativeTokens(filters: {id_in: $slug_id}, take: 50) {
        name
        id
        displayUri
      }
    }
  `
  const TokenQuery = gql`
    ${queryMaker}
  `
  return TokenQuery
}

function getURLbyArtist () {
  const queryMaker = `
    query($pk_id: [String!])  {
      users(filters: {id_in: $pk_id}, take: 50) {
        name
        id
        avatarUri
      }
    }
  `
  const TokenQuery = gql`
    ${queryMaker}
  `
  return TokenQuery
}

async function getURL (collections, type) {
  if (type === 'artist') {
    const data = await request(FXHASH_API, getURLbyArtist(), {
      pk_id: collections
    })
    const dataAux = data.users
    for (let index = 0; index < dataAux.length; index++) {
      try {
        dataAux[index].displayUri = dataAux[index].avatarUri.replace(
          'ipfs://',
          'https://media.fxhash.xyz/w_256/'
        )
      }
      catch (error) {
        dataAux[index].displayUri = ""
      }
    }
    return dataAux
  } else {
    let data = []
    for (let index = 0; index < collections.length; index += 50) {
      const data_temp = await request(FXHASH_API, getURLByCollection(), {
        slug_id: collections.slice(index, index + 50)
      })
      data = [...data, ...data_temp.generativeTokens]
    }

    const dataAux = data;
    for (let index = 0; index < dataAux.length; index++) {
      try {
        dataAux[index].displayUri = dataAux[index].displayUri.replace(
          'ipfs://',
          'https://media.fxhash.xyz/w_512/'
        )  
      }
      catch (error) {
        dataAux[index].displayUri = ""
      }
      
    }
    return dataAux
  }
}
export default getURL
