<template>
  <div>
    <b-row>
      <div class="collection-info">
        <div class="profile-img-container">
          <b-avatar
            :href="metadataStats?.mkpLink"
            target="_blank"
            size="10rem"
            square
            :src="collectionData?.collectionPreview" />

          <div class="profile-label">
            <p>COLLECTION PROFILE</p>
          </div>
        </div>
        <div
          class="info-left h-25"
          style="min-width: 270px">
          <div>
            <b-avatar-group>
              <div
                v-for="authorInfo in collectionData?.artistInfo"
                :key="authorInfo.artistName"
                class="avatar-stack">
                <b-avatar
                  v-b-popover.hover.top="authorInfo.artistName"
                  :to="{
                    name: 'artist',
                    params: {
                      artist: authorInfo.pkh,
                    },
                  }"
                  size="2rem"
                  :src="authorInfo.artistPreview"
                  style="border: 2px solid #fff" />

                <b-link
                  v-show="collectionData?.artistInfo.length === 1"
                  class="collection-name"
                  :to="{
                    name: 'artist',
                    params: {
                      artist: authorInfo.pkh,
                    },
                  }">
                  {{ authorInfo.artistName }}
                </b-link>
              </div>
            </b-avatar-group>
            <div
              class="d-flex"
              style="gap: 0.7rem">
              <h5 style="font-weight: bold">
                {{ metadataStats.collectionName }}
              </h5>
              <span
                style="cursor: pointer"
                @click="
                  isWalletConnected === false
                    ? doConnectWallet()
                    : toggleBookmark()
                ">
                <Icon
                  width="26px"
                  :color="bookmark === true ? 'red' : ''"
                  :icon="
                    bookmark === false
                      ? 'material-symbols:bookmark-add-outline'
                      : 'material-symbols:bookmark'
                  " />
              </span>
            </div>
            <p>
              {{ metadataStats.editions }}
              Editions
            </p>
          </div>
        </div>

        <div class="vertical-line" />

        <div
          class="info-right"
          style="align-items: start">
          <div>
            <div style="margin-bottom: 1rem">
              <h5 style="font-weight: bold">
                {{ metadataStats.uniqueCollectors.value }} |
                {{ metadataStats.uniqueCollectors.percent }}
              </h5>
              <p class="conceptInfo">
                Unique collectors
              </p>
            </div>
            <div>
              <h5 style="font-weight: bold">
                {{ metadataStats.listForSale.value }} |
                {{ metadataStats.listForSale.percent }}
              </h5>
              <p class="conceptInfo">
                Listed for sale
              </p>
            </div>
          </div>
          <div>
            <div style="margin-bottom: 1rem">
              <h5 style="font-weight: bold">
                {{ metadataStats.floor }}
              </h5>
              <p class="conceptInfo">
                Floor Price
              </p>
            </div>
            <div>
              <h5 style="font-weight: bold">
                {{ metadataStats.highestSold }}
              </h5>
              <p class="conceptInfo">
                Highest Sale
              </p>
            </div>
          </div>
          <div>
            <div>
              <h5 style="font-weight: bold">
                {{ metadataStats.secVolumeTz }}
              </h5>
              <p class="conceptInfo">
                Secondary Sales
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <NoData
      v-if="
        metadataStats.uniqueCollectors.value == 0 &&
          metadataStats.listForSale.value == 0 &&
          loading === false
      " />
    <b-row
      v-if="
        metadataStats.uniqueCollectors.value != 0 ||
          metadataStats.listForSale.value != 0
      "
      style="margin-top: 2rem">
      <b-col
        cols="12"
        lg="6">
        <div
          class="stats-container"
          style="margin-top: 2rem">
          <h5 style="font-weight: bold">
            Secondary volume
          </h5>
          <!-- <template> -->
          <div v-if="loading">
            <div
              class="text-center spinner-loading"
              style="opacity: 0.5">
              <b-spinner />
            </div>
          </div>
          <NoDataIndividual v-if="!loading && items.length < 1" />
          <div v-if="!loading && items.length != 0">
            <BarChart
              :height="233"
              :options="optionsBarPlot"
              :chart-data="dataBartPlot"
              :plugins="plugins" />
          </div>
          <div class="stats-container">
            <h5 style="font-weight: bold; margin-top: 2rem">
              Secondary Sales
            </h5>
            <div v-if="loading">
              <div
                class="text-center spinner-loading"
                style="opacity: 0.5">
                <b-spinner />
              </div>
            </div>
            <NoDataIndividual v-if="!loading && items.length < 1" />
            <div v-if="!loading && items != 0">
              <ScatterChart
                :height="233"
                :options="optionsScaterPlot"
                :chart-data="dataScatterPlot" />
            </div>
          </div>
          <div class="stats-container">
            <h5 style="font-weight: bold; margin-top: 2rem">
              All secondary sales
            </h5>

            <SalesTable
              :items="items"
              :busy="loading"
              :filter-coin="filterCoin" />
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="6">
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Available on the market
          </h5>

          <div>
            <OffersTable
              :offer-data="offerData"
              :busy="loading" />
          </div>
        </div>
        <div class="stats-container">
          <h5 style="font-weight: bold; margin-top: 2rem">
            Collector holdings
          </h5>
          <CollectorTable :items="itemsTres" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BarChart from "@/components/Chars/BarChart";
import ScatterChart from "@/components/Chars/ScatterChart";
import OffersTable from "@/components/OffersTable.vue";
import CollectorTable from "@/components/CollectorTable.vue";
import SalesTable from "@/components/SalesTable.vue";
import NoData from "@/components/NoData.vue";
import NoDataIndividual from "@/components/NoDataIndividual.vue";
import { SERVICE_API } from "@/constants";
import { mapGetters } from "vuex";

import { artBlocksURL } from "@/API/artBlocksServices.js";
import getCollectionView from "@/API/collectionView.js";
import getOffersByCollection from "@/API/getOffersByCollection.js";
import { tezosFilter,etherFilter } from "@/filters.js";
import numeral from "numeral";
import { Icon } from "@iconify/vue2";

export default {
  name: "ViewCollection",
  components: {
    BarChart,
    ScatterChart,
    NoData,
    NoDataIndividual,
    OffersTable,
    CollectorTable,
    SalesTable,
    Icon,
  },
  data() {
    return {
      bookmark: false,
      loading: true,
      offerData: {
        offers: [],
        fields: [],
        isBusy: true,
      },
      queryData: {
        id: undefined,
        mkp: undefined,
      },
      metadataStats:  {
        editions:'-',
        secVolumeTz: '-',
        floor: '-',
        highestSold: '-',
        secVolumeTzFilter: '-',
        originalSupply: '-',
        listForSale: {
          value: '0',
          percent:'0.00 %'
        },
        uniqueCollectors: {
          value: '0',
          percent: '0.00 %'
        },
      },
      collectionData: undefined,
      items: undefined,
      itemsDos: undefined,
      itemsTres: undefined,
      plugins: {
        loading: {
          display: true,
          color: "#fff",
          font: {
            size: 16,
          },
          messages: {
            loading: "Fetching data to graph...",
            noData: "Something went wrong, please try again later",
          },
        },
      },
      dataBartPlot: undefined,
      optionsBarPlot: undefined,
      dataScatterPlot: undefined,
      optionsScaterPlot: undefined,
    };
  },
  computed: {
    ...mapGetters("tezosWallet", [
      "tezosAddress",
      "isWalletConnected",
      "isAllowed",
    ]),
  },
  created() {
    if (this.isWalletConnected) {
      this.isBookmarked();
    } else {
      this.$store.watch(
        (state) => state.tezosWallet.isWalletConnected,
        (value) => {
          if (value) {
            this.isBookmarked();
          }
        }
      );
    }
    getOffersByCollection(this.$route.query.id, this.$route.query.chain).then(
      (res) => {
        this.offerData.fields = [
          { key: "cid", sortable: false },
          { key: "iteration", sortable: true },
          { key: "price", sortable: true },
          { key: "marketplace", sortable: false },
        ];
        this.offerData.offers = res;
        this.offerData.isBusy = false;
      }
    );
    // },
    // mounted() {
    const id = this.$route.query.id;
    const mkp = this.$route.query.mkp;
    const chain = this.$route.query.chain;
    this.queryData.id = id;
    this.queryData.mkp = mkp;

    // get collection data
    // let barplot , scatterplot;
    getCollectionView(id, mkp, chain)
      .then(async (x) => {
        this.collectionData = x;
        // all the metadata stats
        const ethereum = this.$route.query.chain === "ethereum";
        const metadata = ethereum ? x.stats[0] : x;
        this.metadataStats = {
          collectionName: metadata.collectionName,
          editions: ethereum ? metadata.totalSupply : `${metadata.minted}/${metadata.editions}`,
          secVolumeTz: ethereum 
            ? etherFilter(metadata.totalVolume) 
            : tezosFilter(metadata.collectionStats.secVolumeTz),
          floor: ethereum 
            ? etherFilter(metadata.floor, '0.00000') 
            : tezosFilter(metadata.collectionStats.floor),
          highestSold: ethereum
            ? etherFilter(metadata.highestSale)
            : tezosFilter(metadata.collectionStats.highestSold),
          secVolumeTzFilter: ethereum
            ? etherFilter(metadata.totalVolume)
            : tezosFilter(metadata.secVolumeTz),
          originalSupply: ethereum
            ? metadata.totalSupply
            : metadata?.editions,
          listForSale: {
            value: ethereum ? metadata.listedCount : x.listForSale.value,
            percent: ethereum
              ? `${numeral(metadata.listedCount / metadata.totalSupply).format(
                "0.00"
              )} %`
              : x.listForSale.percent,
          },
          uniqueCollectors: {
            value: ethereum ? metadata.totalOwners : x.uniqueCollectors.value,
            percent: ethereum
              ? `${numeral(metadata.totalOwners / metadata.totalSupply).format(
                "0.00"
              )} %`
              : x.uniqueCollectors.percent,
          },
          mkpLink: ethereum ? await artBlocksURL(this.$route.query.id) : `https://www.fxhash.xyz/generative/${this.$route.query.id}`,
        };
        
        let barplot = () => Promise.resolve();
        let scatterplot = () => Promise.resolve();
        if (this.$route.query.chain === "ethereum") {
          this.collectionData.collectionPreview = `https://res.cloudinary.com/art-blocks/image/fetch/f_auto,c_limit,w_320,q_auto/${x?.sales[0]?.imgURL}`;
          this.itemsTres = x.sales.map((sale) => {
            return {
              Collector: sale.buyer,
              Currently_holding: sale.number_of_items,
              collectorPk: sale.buyer,
            };
          });
          barplot = async function () {
            // aggregate sales by labels
            const sales = x.sales.sort((a, b) => {
              const aDate = new Date(a.block_time);
              const bDate = new Date(b.block_time);
              return aDate - bDate;
            });
            const aggregated = sales.reduce((acc, sale) => {
              const date = new Date(sale.block_time);
              const month = date.toLocaleString("default", { month: "short" });
              const year = date.getFullYear();
              const label = `${month} ${year}`;
              if (!acc[label]) {
                acc[label] = 0;
              }
              acc[label] += sale.price;
              return acc;
            }, {});
            const labels = Object.keys(aggregated);
            const data = Object.values(aggregated);
            return ({
              labels: labels,
              data: data
            })
          };
          scatterplot = async function () {
            return ({
              secondaryActions: x.sales.map((sale) => {
                return {
                  // x: new Date(sale.block_time),
                  x: sale.block_time,
                  y: sale.price,
                };
              }),
              secondaryActionsTable: x.sales.map((sale) => {
                return {
                  date:  new Date(sale.block_time),
                  buyer: sale.buyer,
                  seller: sale.seller,
                  price: 0.01,//sale.price,
                  buyerPk: sale.buyer,
                  sellerPk: sale.seller,
                  objktId: sale.slug_id,
                  objktName: sale.collectionName,
                  displayUri: `https://res.cloudinary.com/art-blocks/image/fetch/f_auto,c_limit,w_320,q_auto/${sale.imgURL}`,
                };
              }),
            })
          };
        } else {
          // add data to holders table
          this.itemsTres = x.holders.map((x) => {
            return {
              Collector: x.name,
              Currently_holding: x.value,
              collectorPk: x.pk,
            };
          });

          // get promises
          barplot = x.secondarySales;
          scatterplot = x.actions;
        }
        // Return a new promise that resolves when both barplot and scatterplot are done
        return Promise.all([barplot(), scatterplot()]);
      })
      .then(([barplotResult, scatterplotResult]) => {
        const coin = this.$route.query.chain === "ethereum" ? " Ξ " : " ꜩ ";
        const coinLabel = this.$route.query.chain === "ethereum" ? "ETH" : "Tezos";
        const filterCoin = this.$route.query.chain === "ethereum" ? etherFilter : tezosFilter;
        // secondary sales
        this.dataBartPlot = {
          labels: barplotResult.labels,
          datasets: [
            {
              data: barplotResult.data,
              label: "Volume",
              backgroundColor: "#16C784",
              fill: false,
            },
          ],
        };

        this.optionsBarPlot = {
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "#46475a",
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: "#46475a",
                  drawOnChartArea: false,
                },
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    // Formatear el valor con el sufijo "Tz"
                    return value + coin;
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: `${coinLabel} Volume`,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "";
                const value = tooltipItem.yLabel;
                return `${datasetLabel}: ${filterCoin(value)}`;
              },
            },
          },
        };

        // actions
        // add data to scatter plot
        this.dataScatterPlot = {
          datasets: [
            {
              data: scatterplotResult.secondaryActions,
              label: "Volume",
              backgroundColor: "#16C784",
              fill: false,
            },
          ],
        };
        this.optionsScaterPlot = {
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "month",
                  displayFormats: {
                    hour: "YY-MM",
                  },
                  tooltipFormat: "DD-MM-YYYY",
                },
                gridLines: {
                  color: "#46475a",
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                type: "linear",
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    return value + coin;
                  },
                },
                gridLines: {
                  color: "#46475a",
                  drawOnChartArea: false,
                },
                scaleLabel: {
                  display: true,
                  labelString: `${coinLabel} Price`,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetIndex = tooltipItem.datasetIndex;
                const index = tooltipItem.index;
                const price = data.datasets[datasetIndex].data[index].y;
                const date = data.datasets[datasetIndex].data[index].x;
                return `${filterCoin(price)} at ${date.toLocaleString()}`;
              },
            },
          },
          pointBackgroundColor: "rgba(255, 255, 255, 0.4)",
        };

        // add data to secondary sales table
        this.items = scatterplotResult.secondaryActionsTable.map((x) => {
          return {
            Time_of_sale: x.date.toLocaleString(),
            Buyer: x.buyer,
            Seller: x.seller,
            Price_in_Tezos: x.price,
            buyerPk: x.buyerPk,
            sellerPk: x.sellerPk,
            objktId: x.objktId,
            objktName: x.objktName,
            displayUri: x.displayUri,
          };
        });
        this.loading = !this.loading;
      });
  },
  methods: {
    filterCoin(value, decimal) {
      const ethereum = this.$route.query.chain === "ethereum";
      return ethereum
        ? etherFilter(value, decimal)
        : tezosFilter(value, decimal);
    },
    async doConnectWallet() {
      if (this.isWalletConnected) {
        await this.$store.dispatch("tezosWallet/disconnect");
        return;
      }
      await this.$store.dispatch("tezosWallet/connect");
    },
    showLoader() {
      this.loading = !this.loading;
    },
    isBookmarked() {
      const params = new URLSearchParams({
        login_wallet: this.tezosAddress,
        type: "collection",
        id: this.$route.query.id,
      });
      fetch(`${SERVICE_API}/is/bookmarks?${params.toString()}`, {
        method: "GET",
      }).then((res) => {
        if (res.status === 200) {
          // if res promise result is true or false set bookmark to true or false
          res.json().then((res) => {
            this.bookmark = res;
          });
        }
      });
    },
    toggleBookmark() {
      if (this.bookmark) {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "DELETE",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            type: "collection",
            id: this.$route.query.id,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.bookmark = !this.bookmark;
          }
        });
      } else {
        fetch(`${SERVICE_API}/bookmarks`, {
          method: "POST",
          body: new URLSearchParams({
            login_wallet: this.tezosAddress,
            chain: this.$route.query.chain,
            type: "collection",
            id: this.$route.query.id,
          }),
        }).then((res) => {
          if (res.status === 200) {
            this.bookmark = !this.bookmark;
          }
        });
      }
    },
  },
};
</script>
<style>
.collection-info,
.info-left,
.info-right {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.container-flex {
  display: flex;
}
.main-title {
  border: 3px solid #fff;
}

.collection-info p {
  margin: 0.1rem;
}

.vertical-line {
  border-left: 1px solid #46475a;
  height: 176px;
}

.subtitle-concept {
  color: #ffffffb6;
}

.hidden-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 15ch;
}

.profile-img-container {
  display: flex;
  flex-direction: column;

  border: 2px solid #ffff;
}

.img-pfp {
  width: 130px;
  height: 130px;
}

.ratio img {
  width: 100%;
  object-fit: cover;
  max-height: 100%;
}

.profile-label {
  background-color: #090b24;
  color: #fff;
  padding: 0.2rem;
  font-weight: bold;
  width: 100%;
  font-size: 10px;
  margin: 0;
  text-align: center;
  border-top: 1px solid #46475a;
  order: 2;
}

.b-avatar-group .b-avatar-group-inner {
  align-items: center;
  gap: 0.5rem;
}

.avatar-stack {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  top: 0;
  left: 0;
  margin-right: -1.5rem;
  z-index: 1;
  margin-bottom: 1rem;
}

.popover-body {
  border: 1px solid #46475a;
  color: #fff;

  background-color: #090b24;
}

@media (max-width: 768px) {
  .info-left,
  .collection-info {
    flex-direction: column;
    align-items: self-start;
  }

  .info-left {
    order: 1;
  }

  .profile-img-container,
  .img-pfp {
    order: 2;

    height: 100%;
  }

  .info-right {
    display: flex;
    max-width: 425px;
    order: 3;
    flex-wrap: wrap;
  }

  .vertical-line {
    border-top: 1px solid #ffffff81;
    height: 0;
    width: 100%;
    display: none;
  }
}
</style>
