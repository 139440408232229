import { OpKind } from '@taquito/taquito';

import { Tezos, getContract } from '@/services/tezos'
import { MARKETPLACES, DEFAULT_CONFIRMATION_BLOCKS } from '@/constants'

class FXHASH {
  constructor({ }) {
    this.marketplaceV1 = MARKETPLACES.fxhash.marketplaceV1
    this.marketplaceV2 = MARKETPLACES.fxhash.marketplaceV2
    this.marketplaceV3 = MARKETPLACES.fxhash.marketplaceV3
    this.articles = MARKETPLACES.fxhash.articles
  }


  async mint(_referrer, _reserve_input, issuer_id, c = DEFAULT_CONFIRMATION_BLOCKS) {
    const contract = await getContract(this.marketplaceV1)
    const batch_operation = await Tezos.wallet
      .batch([
        {
          kind: OpKind.TRANSACTION,
          ...contract.methods
            .mint(issuer_id)
            .toTransferParams(),
        }
      ])
      .send()

    return batch_operation.confirmation(c);
  }

  async acceptListingV2(listingId, amount, c = DEFAULT_CONFIRMATION_BLOCKS) {
    const contract = await getContract(this.marketplaceV2)
    const batch_operation = await Tezos.wallet
      .batch([
        {
          kind: OpKind.TRANSACTION,
          ...contract.methods
            .listing_accept(listingId)
            .toTransferParams(),
          amount,
        }
      ])
      .send()

    return batch_operation.confirmation(c);
  }

  async acceptListingV3(listingId, referer, amount, c = DEFAULT_CONFIRMATION_BLOCKS) {
    const contract = await getContract(this.marketplaceV3)
    const batch_operation = await Tezos.wallet
      .batch([
        {
          kind: OpKind.TRANSACTION,
          ...contract.methods
            .listing_accept(listingId)
            .toTransferParams(),
          amount,
        }
      ])
      .send()

    return batch_operation.confirmation(c);
  }


}

export default FXHASH
