import { STATS_API } from '@/constants'

async function getEvents (top, days) {
  const options = {
    method: 'GET'
  }
  let events = []
  await fetch(
    `${STATS_API}/get/events?days=${days}&top_n=${top}`,
    options
  )
    .then((response) => response.json())
    .then((response) => (events = response))
    .catch((err) => console.error(err))
  return events
}

export default getEvents
