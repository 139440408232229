import numeral from 'numeral'
import { isValidAddress } from '@/services/tezos'

const dollarFilter = (value) => {
  if (!value) return '$ 0.00'
  return `$ ${numeral(value).format('0,0.00')}`
}

const tezosFilter = (value,decimals = '0,0') => {
  if (!value) return 'ꜩ 0'
  return `ꜩ ${numeral(value).format(decimals)}`
}

const etherFilter = (value,decimals='0,0000') => {
  if (!value) return 'Ξ 0'
  return `Ξ ${numeral(value).format(decimals)}`
}

const tezosFilterWord = (value) => {
  if (!value) return '0 Tezos'
  return `${numeral(value).format('0,0')} Tezos`
}

const tezosFilterExact = (value) => {
  if (!value) return 'ꜩ 0'
  return `ꜩ ${numeral(value).format('0,0.00')}`
}

const addressFilter = (pkh) => {
  const isValid = isValidAddress(pkh)
  if (isValid) {
    return pkh.slice(0, 5) + '...' + pkh.slice(pkh.length - 4, pkh.length)
  }
  return pkh
}

const quantityFilter = (value) => {
  if (!isFinite(value)) return 'New!'
  if (!value) return 0
  return numeral(value).format('0,0')
}

const artContent = (value) => {
  if (value == 'https://www.kaloh.xyz/') {
    return "Kaloh's newsletter"
  }
  if (value == 'http://www.rightclicksave.com/') {
    return 'Right Click Save'
  }
  if (value == 'https://podtail.com/podcast/waiting-to-be-signed/') {
    return 'Waiting to be signed'
  }
  if (value == 'https://www.fxhash.xyz/') {
    return 'fx(text)'
  }

  return value
}

export {
  tezosFilterWord,
  artContent,
  dollarFilter,
  tezosFilter,
  tezosFilterExact,
  addressFilter,
  quantityFilter,
  etherFilter
}
